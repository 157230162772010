import { useCallback } from 'react';
import { EditSurveyModal } from '../../../components/EditSurveyModal/EditSurveyModal';
import { EditSurveyArgs, Survey } from '../../../types';
import { SURVEYS, useUpdateSurvey } from '../../../queries/useSurveys';
import { useQueryClient } from 'react-query';

interface Props {
  selectedSurvey: Survey | null;
  isEditModalOpen: boolean;
  handleCloseEditModal: () => void;
}

export const SurveyEdit = ({ selectedSurvey, isEditModalOpen, handleCloseEditModal }: Props) => {
  const updateSurvey = useUpdateSurvey();
  const queryClient = useQueryClient();

  const handleConfirmEditModal = useCallback(
    async ({
      newName,
      description,
      includeEvaluation,
      includeWeight,
      includeQuestionNumbers,
      evaluationRules,
      evaluationScale,
    }: EditSurveyArgs) => {
      handleCloseEditModal();
      if (!selectedSurvey?.id) return;

      await updateSurvey.mutateAsync({
        id: selectedSurvey.id,
        name: newName,
        description,
        evaluationRules,
        evaluationScale,
        includeEvaluation,
        includeWeight,
        includeQuestionNumbers,
      });
      queryClient.refetchQueries(SURVEYS);
    },
    [handleCloseEditModal, queryClient, selectedSurvey?.id, updateSurvey]
  );

  if (!isEditModalOpen) return null;

  return (
    <EditSurveyModal
      onClose={handleCloseEditModal}
      isOpen={isEditModalOpen}
      title={'Edit Survey Settings'}
      surveyName={selectedSurvey?.name || ''}
      onConfirm={handleConfirmEditModal}
      confirmText={'Save Changes'}
      activeSurvey={selectedSurvey}
    />
  );
};
