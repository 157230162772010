import { CHECKLIST_ITEM_FREQUENCY, Company } from '../../../types';

export const getChecklistAnswer = ({
  company,
  questionId,
}: {
  company?: Company;
  questionId: string | number;
}) => {
  const companyAnnuallyChecklist =
    company?.checklistData?.[CHECKLIST_ITEM_FREQUENCY.ANNUALLY] || [];
  const companyQuarterlyChecklist =
    company?.checklistData?.[CHECKLIST_ITEM_FREQUENCY.QUARTERLY] || [];
  const allCompanyChecklistData = [...companyAnnuallyChecklist, ...companyQuarterlyChecklist];
  return (
    allCompanyChecklistData?.find((item) => item?.id === questionId)?.checklistOption?.name || null
  );
};
