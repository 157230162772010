import { ROUTES } from '../../../../../../../../../constants/routes';
import { useReportById } from '../../../../../../../../../queries/useReports';
import { CompanyActivity, REPORT_STATUS } from '../../../../../../../../../types';
import { LinkContent } from './components/LinkContent';

interface Props {
  activity: CompanyActivity;
}

export const ResponseSubmittedContent = ({ activity }: Props) => {
  const { data: report } = useReportById(activity?.value?.report?.id || 0);
  const link =
    report?.status === REPORT_STATUS.IN_REVIEW
      ? `/${ROUTES.REPORTS}/${activity?.value?.report?.id}`
      : `/${ROUTES.ASSESSMENTS}/${activity?.value?.report?.id}`;

  return <LinkContent link={link} text={activity?.value?.report?.survey?.name || ''} />;
};
