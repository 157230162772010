import { Button, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-blue.svg';
import { COMPANY_EXCEL_HEADERS } from '../../../constants/defaultValues';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { useCallback } from 'react';
import { useAtomValue } from 'jotai';
import { userState } from '../../../state/UIState';
import { User } from '../../../types';

export const DownloadExcelButton = () => {
  const { colors } = useTheme();
  const user = useAtomValue(userState) as User;

  const onExportTemplate = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = user?.fullName || '';
    workbook.created = new Date();
    workbook.properties.date1904 = true;

    const worksheet = workbook.addWorksheet('Companies');

    worksheet.columns = COMPANY_EXCEL_HEADERS;

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), `Companies.xlsx`);
  }, [user?.fullName]);

  return (
    <Button
      onClick={onExportTemplate}
      variant='text'
      startIcon={<DownloadIcon />}
      style={{ minWidth: 'auto' }}
    >
      <Typography variant='subtitle2' color={colors.accent[50]}>
        Download Company Template
      </Typography>
    </Button>
  );
};
