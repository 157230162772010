import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../../../../../components/Typography/Typography';
import { useLayoutEffect, useRef, useState } from 'react';

interface Props {
  text?: string;
}

const Wrapper = styled('div')``;

const TextWrapper = styled('div')<{ isTruncated: boolean }>`
  width: 100%;
  height: ${({ isTruncated }) => (isTruncated ? '60px' : 'auto')};
  & > p {
    white-space: ${({ isTruncated }) => (isTruncated ? 'normal' : 'wrap')};
    overflow: ${({ isTruncated }) => (isTruncated ? 'hidden' : 'visible')};
    text-overflow: ${({ isTruncated }) => (isTruncated ? 'ellipsis' : 'unset')};
    display: ${({ isTruncated }) => (isTruncated ? '-webkit-box' : 'inline')};
    -webkit-line-clamp: ${({ isTruncated }) => (isTruncated ? 3 : 'none')};
    -webkit-box-orient: ${({ isTruncated }) => (isTruncated ? 'vertical' : 'unset')};
    line-height: 20px;
    width: 100%;
  }
`;

export const TextContent = ({ text }: Props) => {
  const { colors } = useTheme();
  const ref = useRef<HTMLDivElement>(null);

  const [isTruncated, setIsTruncated] = useState(false);
  const [hasTruncatedText, setHasTruncatedText] = useState(false);

  useLayoutEffect(() => {
    const { offsetHeight, scrollHeight } = ref.current || {};

    if (offsetHeight && scrollHeight && scrollHeight > 60) {
      setIsTruncated(true);
      setHasTruncatedText(true);
    } else {
      setIsTruncated(false);
      setHasTruncatedText(false);
    }
  }, [ref, text]);

  if (!text) return null;

  return (
    <Wrapper>
      <TextWrapper ref={ref} isTruncated={isTruncated}>
        <Typography variant='body' color={colors.primary[90]}>
          {text}
        </Typography>
      </TextWrapper>
      {hasTruncatedText && (
        <Typography
          variant='body'
          color={colors.accent[50]}
          style={{ marginTop: '8px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            setIsTruncated((prev) => !prev);
          }}
        >
          {isTruncated ? 'Show More' : 'Show Less'}
        </Typography>
      )}
    </Wrapper>
  );
};
