import { Dispatch, SetStateAction, useMemo } from 'react';
import { SidebarMenu } from '../../../components/SidebarMenu/SidebarMenu';
import { useAtomValue } from 'jotai';
import { activeRoleState, userState } from '../../../state/UIState';
import { User, USER_ROLE } from '../../../types';
import { useMenuItems } from './useMenuItems';
import { useAnalystMenuItems } from './useAnalystMenuItems';
import { useManagerComplianceMenuItems } from './useManagerComplianceMenuItems';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  isExpanded: boolean;
  onPinToggle: () => void;
  isPinned: boolean;
}

export const EsgCompassSidebarMenu = ({
  setIsExpanded,
  isExpanded,
  onPinToggle,
  isPinned,
}: Props) => {
  const activeRole = useAtomValue(activeRoleState);

  const analystMenuItems = useAnalystMenuItems();
  const managerComplianceMenuItems = useManagerComplianceMenuItems();
  const commonMenuItems = useMenuItems();

  const menuItems = useMemo(() => {
    if (activeRole === USER_ROLE.ANALYST) {
      return analystMenuItems;
    }

    if (activeRole === USER_ROLE.MANAGER || activeRole === USER_ROLE.COMPLIANCE) {
      return managerComplianceMenuItems;
    }

    return commonMenuItems;
  }, [activeRole, analystMenuItems, commonMenuItems, managerComplianceMenuItems]);

  const user = useAtomValue(userState) as User;

  const { logout, isAuthenticated } = useAuth0();

  const handleLogout = () => {
    if (!isAuthenticated) return;
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <SidebarMenu
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      onPinToggle={onPinToggle}
      isPinned={isPinned}
      menuItems={menuItems}
      username={user?.fullName || ''}
      activeRole={activeRole}
      onUserAvatarClick={handleLogout}
    />
  );
};
