import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../../../../../components/Typography/Typography';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

interface Props {
  title: string;
  icon: JSX.Element;
  subtext: string;
}

export const ComposedTitle = ({ title, icon, subtext }: Props) => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      <Typography
        variant='overline'
        color={colors.primary[70]}
        style={{ textTransform: 'uppercase' }}
      >
        {title}
      </Typography>
      {icon}
      <Typography
        variant='overline'
        color={colors.primary[70]}
        style={{ textTransform: 'uppercase' }}
      >
        {subtext}
      </Typography>
    </Wrapper>
  );
};
