import React, { useEffect } from 'react';
import { Unauthorized } from '../../pages/Unauthorized/Unauthorized';
import { useAtomValue } from 'jotai';
import { activeRoleState, userState } from '../../state/UIState';
import { ComponentWithChildren, LOCAL_USER_STATE, USER_ROLE } from '../../types';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../constants/routes';

interface RolesGuardProps extends ComponentWithChildren {
  allowedRoles: string[];
}

const RolesGuard: React.FC<RolesGuardProps> = ({ allowedRoles, children }) => {
  const activeRole = useAtomValue(activeRoleState);
  const user = useAtomValue(userState);
  const navigate = useNavigate();

  const firstSegment = window.location.pathname.split('/')[1];
  useEffect(() => {
    if (firstSegment === ROUTES.PORTFOLIO) {
      if (activeRole === USER_ROLE.ANALYST) {
        navigate(`/${ROUTES.ISSUERS}`);
      }
    }
  }, [activeRole, firstSegment, navigate]);

  if (allowedRoles.includes(activeRole as USER_ROLE)) {
    return <>{children}</>;
  } else if (user === LOCAL_USER_STATE.LOADING) {
    return null;
  } else {
    return <Unauthorized showHeader={false} />;
  }
};

export default RolesGuard;
