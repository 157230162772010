import { useCallback, useMemo, useState } from 'react';
import { Modal } from '../../../../../../../components/Modal/Modal';
import { useBreakdownData } from '../../../../../hooks/useBreakdownData';
import {
  QuestionOption,
  REPORT_COMPANY_DETAILS,
  REPORT_DATA_SOURCE,
  SelectItem,
  TWidgetDataType,
  WIDGET_TYPE,
} from '../../../../../../../types';
import { SingleSelect } from '../../../../../../../components/SingleSelect/SingleSelect';
import { FormField } from '../../../../../../../components/FormField/FormField';
import { styled } from '@mui/material';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../../../state/UIState';
import { WidgetModalContent } from './WidgetModalContent';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const widgetTypeOptions: SelectItem[] = [
  {
    id: WIDGET_TYPE.BAR_CHART,
    value: 'Bar Chart',
  },
  {
    id: WIDGET_TYPE.PIE_CHART,
    value: 'Pie Chart',
  },
  {
    id: WIDGET_TYPE.COMPANY_BREAKDOWN,
    value: 'Company Breakdown',
  },
];

interface Props {
  isOpen: boolean;
  title: string;
  confirmBtnText: string;
  onClose: () => void;
  handleSubmit: ({
    questionId,
    widgetType,
    questionAnswer,
    widgetDataType,
    startYear,
    endYear,
  }: {
    questionId: string;
    widgetType: WIDGET_TYPE;
    questionAnswer?: QuestionOption;
    widgetDataType: TWidgetDataType;
    startYear?: number;
    endYear?: number;
  }) => void;
  widgetQuestionId?: string;
  widgetType?: WIDGET_TYPE;
  widgetAnswer?: QuestionOption;
  widgetDataType?: TWidgetDataType;
  widgetStartYear?: number;
  widgetEndYear?: number;
}
export const WidgetModal = ({
  isOpen,
  title,
  confirmBtnText,
  onClose,
  handleSubmit,
  widgetQuestionId,
  widgetType,
  widgetAnswer,
  widgetDataType,
  widgetStartYear,
  widgetEndYear,
}: Props) => {
  const { selectedColumns } = useBreakdownData();
  const [aggregatedReport] = useAtom(aggregatedReportState);

  const initialSelectedQuestion = useMemo(() => {
    const init = selectedColumns?.find((q) => q?.id === widgetQuestionId);

    if (!init) return null;

    return { id: init?.id, value: init?.name };
  }, [selectedColumns, widgetQuestionId]);

  const initialWidgetType = useMemo(() => {
    return widgetTypeOptions?.find((option) => option.id === widgetType);
  }, [widgetType]);

  const initialWidgetAnswer = useMemo(() => {
    if (!widgetAnswer) return null;

    return {
      id: widgetAnswer?.id,
      value: widgetAnswer?.name,
    };
  }, [widgetAnswer]);

  const dataTypeOptions: SelectItem[] = useMemo(() => {
    const uniqueTypes = [
      ...new Map(
        selectedColumns?.map((q) => {
          if (q.sourceType === REPORT_DATA_SOURCE.COMPANY_DETAILS) return [q.id, q];
          return [q.sourceType, q];
        })
      ).values(),
    ];

    return uniqueTypes
      ?.map((type) => {
        if (type?.sourceType === REPORT_DATA_SOURCE.COMPANY_DETAILS)
          return {
            id: type?.id,
            value: type?.name,
          };

        if (type.sourceType === REPORT_DATA_SOURCE.ASSESSMENT) {
          return {
            id: type?.sourceType,
            value: 'Assessment',
          };
        }
        const source = aggregatedReport?.sources?.find((s) => s?.id === type?.sourceType);

        if (!source) return null;

        return {
          id: source?.id,
          value: source?.name,
        };
      })
      ?.filter(Boolean) as SelectItem[];
  }, [aggregatedReport?.sources, selectedColumns]);

  const initialWidgetDataType = useMemo(() => {
    if (!widgetDataType) return null;

    return dataTypeOptions?.find((option) => option.id === widgetDataType);
  }, [dataTypeOptions, widgetDataType]);

  const [selectedDataType, setSelectedDataType] = useState<SelectItem | null>(
    initialWidgetDataType || null
  );

  const [selectedQuestion, setSelectedQuestion] = useState<SelectItem | null>(
    initialSelectedQuestion
  );
  const [selectedType, setSelectedType] = useState<SelectItem | null>(
    initialWidgetType || widgetTypeOptions[0]
  );

  const [selectedAnswer, setSelectedAnswer] = useState<SelectItem | null>(
    initialWidgetAnswer || null
  );

  const [startYear, setStartYear] = useState<string>(
    widgetStartYear ? String(widgetStartYear) : ''
  );
  const [endYear, setEndYear] = useState<string>(widgetEndYear ? String(widgetEndYear) : '');

  const onCloseModal = useCallback(() => {
    setSelectedQuestion(null);
    setSelectedAnswer(null);
    setSelectedType(null);
    onClose();
  }, [onClose]);

  const isConfirmDisabled = useMemo(() => {
    if (
      selectedDataType?.id === REPORT_COMPANY_DETAILS.ESG_OPPORTUNITIES ||
      selectedDataType?.id === REPORT_COMPANY_DETAILS.ESG_RISK_LEVEL ||
      selectedDataType?.id === REPORT_COMPANY_DETAILS.GOVERNANCE_SCORE
    ) {
      return !selectedType;
    }

    if (selectedDataType?.id === REPORT_DATA_SOURCE.PERFORMANCE) {
      return !selectedQuestion;
    }

    return !selectedQuestion || !selectedType;
  }, [selectedDataType?.id, selectedQuestion, selectedType]);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      confirmBtnText={confirmBtnText}
      onClose={onCloseModal}
      onConfirm={() => {
        const questionAnswer = selectedAnswer?.id
          ? {
              id: selectedAnswer?.id as string,
              name: selectedAnswer?.value || '',
            }
          : undefined;

        handleSubmit({
          questionId: selectedQuestion?.id as string,
          widgetType: selectedType?.id as WIDGET_TYPE,
          questionAnswer,
          widgetDataType: selectedDataType?.id as TWidgetDataType,
          startYear: startYear ? Number(startYear) : undefined,
          endYear: endYear ? Number(endYear) : undefined,
        });
        onCloseModal();
      }}
      isConfirmDisabled={isConfirmDisabled}
    >
      <Container>
        <FormField label={'Data Type'}>
          <SingleSelect
            style={{ width: '100%' }}
            options={dataTypeOptions}
            value={selectedDataType}
            onChange={(_, dataType) => {
              setSelectedDataType(dataType);
              setSelectedQuestion(null);
              setSelectedAnswer(null);
            }}
            disablePortal
            fieldPlaceholder={'Select Data Type'}
          />
        </FormField>
        <WidgetModalContent
          dataType={selectedDataType?.id as TWidgetDataType}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          widgetTypeOptions={widgetTypeOptions}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          startYear={startYear}
          setStartYear={setStartYear}
          endYear={endYear}
          setEndYear={setEndYear}
        />
      </Container>
    </Modal>
  );
};
