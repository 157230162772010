import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { createReportCompletedProgressState, userState } from '../../../state/UIState';
import { useAtomValue } from 'jotai';
import { User } from '../../../types';
import { useCallback, useMemo } from 'react';
import {
  AGGREGATED_REPORT_PARAMS,
  AggregatedReportColumn,
  AggregatedReportSource,
  REPORT_COMPANY_DETAILS,
  REPORT_DATA_SOURCE,
  SURVEY_STAGE,
} from '../../../types';
import { ROUTES } from '../../../constants/routes';
import {
  AggregatedReportPayload,
  useCreateAggregatedReport,
} from '../../../queries/useAggregatedReports';
import { useSurveys } from '../../../queries/useSurveys';
import { getQuestionsMap } from '../../../utils/getAggregatedReportSurveysMap';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { INVESTOR_EXCLUSIONS_SURVEY_ID } from '../../../constants/constants';
import { HEADER_COLUMN_ID, KPI_FORMAT_COLUMN_ID } from '../../../constants/defaultValues';

export const ControlButtons = () => {
  const navigate = useNavigate();
  const { mutate: createAggregatedReport } = useCreateAggregatedReport();
  const { data: surveys } = useSurveys();
  const user = useAtomValue(userState) as User;
  const progress = useAtomValue(createReportCompletedProgressState);

  const params = useParams();

  const { navigateAndPreserveParams, getParam } = useQueryParams();
  const activeStep = Number(params.activeStep);

  const onCancel = useCallback(() => {
    navigate(`/${ROUTES.AGGREGATED_REPORTS}`);
  }, [navigate]);

  const exclusionSurvey = useMemo(() => {
    return surveys?.find((survey) => survey.id === INVESTOR_EXCLUSIONS_SURVEY_ID);
  }, [surveys]);

  const exclusionsSurveyQuestions = useMemo(() => {
    return (
      exclusionSurvey?.sections?.flatMap((section) =>
        section?.questions?.flatMap((question) =>
          question.followUpQuestions?.map((question) => {
            return {
              id: question?.id,
              name: question?.name,
              sourceType: REPORT_DATA_SOURCE.EXCLUSIONS,
              category: {
                id: section.id,
                name: section.name,
              },
            } as AggregatedReportColumn;
          })
        )
      ) || []
    );
  }, [exclusionSurvey?.sections]);

  const checklistsQuarterlySurvey = useMemo(() => {
    return surveys?.find((survey) => survey.stage === SURVEY_STAGE.CHECKLIST_QUARTERLY);
  }, [surveys]);

  const checklistAnnuallySurvey = useMemo(() => {
    return surveys?.find((survey) => survey.stage === SURVEY_STAGE.CHECKLIST_ANNUALLY);
  }, [surveys]);

  const checklistsQuestions = useMemo(() => {
    return [checklistAnnuallySurvey, checklistsQuarterlySurvey].flatMap((survey) =>
      survey?.sections?.flatMap((section) =>
        section?.questions?.map((question) => ({
          id: question?.id,
          name: question?.name,
          sourceType: REPORT_DATA_SOURCE.CHECKLIST,
          category: {
            id: section.id,
            name: section.name,
          },
        }))
      )
    );
  }, [checklistAnnuallySurvey, checklistsQuarterlySurvey]);

  const kpisSurvey = useMemo(() => {
    return surveys?.find((survey) => survey.stage === SURVEY_STAGE.CORE_KPI);
  }, [surveys]);

  const kpisQuestions = useMemo(() => {
    return kpisSurvey?.sections?.flatMap((section) =>
      section?.questions?.[0]?.tableData?.rows?.slice(1)?.map((row) => {
        return {
          id: row.id,
          name: row[HEADER_COLUMN_ID],
          sourceType: REPORT_DATA_SOURCE.PERFORMANCE,
          format: row[KPI_FORMAT_COLUMN_ID],
          category: {
            id: section.id,
            name: section.name,
          },
        };
      })
    );
  }, [kpisSurvey?.sections]);

  const onCreateReport = useCallback(() => {
    const companyIds = getParam(AGGREGATED_REPORT_PARAMS.COMPANY_IDS);
    const selectedCompanyDetails = getParam(AGGREGATED_REPORT_PARAMS.COMPANY_DETAILS) || [];
    const selectedAssessmentIds = getParam(AGGREGATED_REPORT_PARAMS.ASSESSMENT_IDS);

    const companyDataCollectionKeys = [
      REPORT_DATA_SOURCE.CHECKLIST,
      REPORT_DATA_SOURCE.EXCLUSIONS,
      REPORT_DATA_SOURCE.PERFORMANCE,
    ];

    const selectedCompanyDataCollection = (selectedCompanyDetails as string[])?.filter(
      (companyDetail) => companyDataCollectionKeys?.includes(companyDetail as REPORT_DATA_SOURCE)
    );

    const companyDataCollectionSources =
      ((selectedCompanyDataCollection as REPORT_DATA_SOURCE[])?.map((companyDetails) => {
        if (companyDetails === REPORT_DATA_SOURCE.EXCLUSIONS) {
          return {
            id: REPORT_DATA_SOURCE.EXCLUSIONS,
            name: 'Exclusions',
            columns: (exclusionsSurveyQuestions as AggregatedReportColumn[]) || [],
          };
        }

        if (companyDetails === REPORT_DATA_SOURCE.CHECKLIST) {
          return {
            id: REPORT_DATA_SOURCE.CHECKLIST,
            name: 'Checklists',
            columns: (checklistsQuestions as AggregatedReportColumn[]) || [],
          };
        }

        if (companyDetails === REPORT_DATA_SOURCE.PERFORMANCE) {
          return {
            id: REPORT_DATA_SOURCE.PERFORMANCE,
            name: 'Performance',
            columns: (kpisQuestions as AggregatedReportColumn[]) || [],
          };
        }
      }) as AggregatedReportSource[]) || [];

    const companyDetailsKeys = [
      REPORT_COMPANY_DETAILS.ESG_OPPORTUNITIES,
      REPORT_COMPANY_DETAILS.ESG_RISK_LEVEL,
      REPORT_COMPANY_DETAILS.GOVERNANCE_SCORE,
    ];

    const selectedReportCompanyDetails = (selectedCompanyDetails as string[])?.filter(
      (companyDetails) => companyDetailsKeys.includes(companyDetails as REPORT_COMPANY_DETAILS)
    );

    const reportCompanyDetailsSource = {
      id: REPORT_DATA_SOURCE.COMPANY_DETAILS,
      type: REPORT_DATA_SOURCE.COMPANY_DETAILS,
      name: 'Company Details',
      columns: selectedReportCompanyDetails?.map((companyDetail) => {
        if (companyDetail === REPORT_COMPANY_DETAILS.ESG_OPPORTUNITIES) {
          return {
            id: companyDetail,
            name: 'ESG Opportunities',
            sourceType: REPORT_DATA_SOURCE.COMPANY_DETAILS,
          };
        }

        if (companyDetail === REPORT_COMPANY_DETAILS.ESG_RISK_LEVEL) {
          return {
            id: companyDetail,
            name: 'ESG Risk Level',
            sourceType: REPORT_DATA_SOURCE.COMPANY_DETAILS,
          };
        }

        if (companyDetail === REPORT_COMPANY_DETAILS.GOVERNANCE_SCORE) {
          return {
            id: companyDetail,
            name: 'Governance Score',
            sourceType: REPORT_DATA_SOURCE.COMPANY_DETAILS,
          };
        }
      }),
    } as AggregatedReportSource;

    const selectedAssessmentSurveys = surveys?.filter((survey) =>
      (selectedAssessmentIds as string[])?.includes(String(survey.id))
    );

    const assessmentsSources =
      (selectedAssessmentSurveys?.map((survey) => {
        const questionsMap = getQuestionsMap(survey);
        const questions = questionsMap ? Object.values(questionsMap) : [];
        return {
          id: survey.id,
          name: survey.name,
          type: REPORT_DATA_SOURCE.ASSESSMENT,
          columns: questions?.map((q) => ({
            id: q.id,
            name: q.name,
            assessmentQuestion: q,
            sourceType: REPORT_DATA_SOURCE.ASSESSMENT,
          })),
        };
      }) as AggregatedReportSource[]) || [];

    const reportSources = [];

    if (companyDataCollectionSources?.length) {
      reportSources.push(...companyDataCollectionSources);
    }

    if (reportCompanyDetailsSource) {
      reportSources.push(reportCompanyDetailsSource);
    }

    if (assessmentsSources?.length) {
      reportSources.push(...assessmentsSources);
    }

    const cIds = Array.isArray(companyIds) ? companyIds : Object.values(companyIds || {});

    const report: AggregatedReportPayload = {
      name: 'New Report',
      description: '',
      sources: reportSources,
      selectedColumns: [],
      companies: (cIds as string[])?.map((id) => Number(id)) || [],
      issuerFilters: null,
      createdBy: user?.id,
    };
    createAggregatedReport(report, {
      onSuccess: (data) => {
        navigate(`/${ROUTES.SINGLE_AGGREGATED_REPORT}/${data.id}`);
      },
      onError: (e) => {
        console.error(e);
      },
    });
  }, [
    checklistsQuestions,
    createAggregatedReport,
    exclusionsSurveyQuestions,
    getParam,
    kpisQuestions,
    navigate,
    surveys,
    user?.id,
  ]);

  const onNext = useCallback(() => {
    if (activeStep == 1) {
      navigateAndPreserveParams(`/${ROUTES.CREATE_AGGREGATED_REPORT}/${activeStep + 1}`);
      return;
    }

    if (activeStep == 2) {
      onCreateReport();
    }
  }, [activeStep, navigateAndPreserveParams, onCreateReport]);

  const nextBtnText = useMemo(() => {
    return activeStep === 1 ? 'Next' : 'Create Report';
  }, [activeStep]);

  const isNextDisabled = useMemo(() => {
    if (activeStep === 1) {
      return progress < 50;
    }
    if (activeStep === 2) {
      return progress < 100;
    }
  }, [activeStep, progress]);

  return (
    <>
      <Button variant='outlined' style={{ width: '166px' }} onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant='contained'
        style={{ width: '166px' }}
        disabled={isNextDisabled}
        onClick={onNext}
      >
        {nextBtnText}
      </Button>
    </>
  );
};
