import { styled } from '@mui/material';
import { CompanyActivity, GroupedActivity } from '../../../../../../../../../types';
import { ActivityIcon } from './ActivityIcon';
import { ActivityTitle } from './ActivityTitle/ActivityTitle';

const Header = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

interface Props {
  activity: CompanyActivity | GroupedActivity;
  isInGroupActivity?: boolean;
}

export const ActivityHeader = ({ activity, isInGroupActivity }: Props) => {
  return (
    <Header>
      {!isInGroupActivity && <ActivityIcon activityType={activity.type} />}
      <ActivityTitle activity={activity} />
    </Header>
  );
};
