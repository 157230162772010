import { useMemo } from 'react';
import { useBreakdownData } from '../../../../hooks/useBreakdownData';
import { AggregatedReportWidget } from '../../../../../../types';
import { isCompanyDetailsWidget } from '../utils/isCompanyDetailsWidget';

export const useWidgetQuestion = (widget: AggregatedReportWidget) => {
  const { selectedColumns } = useBreakdownData();

  return useMemo(() => {
    if (isCompanyDetailsWidget(widget)) {
      return selectedColumns?.find((q) => q?.id === widget?.widgetDataType);
    }
    return selectedColumns?.find((q) => q?.id === widget?.questionId);
  }, [selectedColumns, widget]);
};
