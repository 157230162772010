import { styled, useTheme } from '@mui/material';
import { REPORT_COMPANY_DETAILS, RISK_LEVEL } from '../../../../types';
import { EsgOpportunitiesIcon } from '../../../../components/EsgOpportunitiesIcon';
import { EsgRiskIcon } from '../../../../components/EsgRiskIcon';
import { Typography } from '../../../../components/Typography/Typography';
import {
  getGovernanceScoreIcon,
  getGovernanceScoreLabel,
} from '../../../../utils/governanceScoreUtils';
import { useMemo } from 'react';

interface Props {
  questionId?: string;
  answer: string;
}

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const getIcon = (type: REPORT_COMPANY_DETAILS, value: RISK_LEVEL) => {
  if (type === REPORT_COMPANY_DETAILS.ESG_OPPORTUNITIES) {
    return <EsgOpportunitiesIcon esgOpportunitiesLevel={value} />;
  }

  if (type === REPORT_COMPANY_DETAILS.ESG_RISK_LEVEL) {
    return <EsgRiskIcon esgRiskLevel={value} />;
  }

  return null;
};

export const CompanyDetailsCell = ({ questionId, answer }: Props) => {
  const { colors } = useTheme();

  const isGovernanceScore =
    questionId === REPORT_COMPANY_DETAILS.GOVERNANCE_SCORE && !isNaN(Number(answer));

  const icon = useMemo(() => {
    if (isGovernanceScore) {
      const Icon = getGovernanceScoreIcon(Number(answer));
      return <Icon />;
    }

    return getIcon(questionId as REPORT_COMPANY_DETAILS, answer as RISK_LEVEL);
  }, [answer, isGovernanceScore, questionId]);

  const label = useMemo(() => {
    if (isGovernanceScore) {
      return getGovernanceScoreLabel(Number(answer));
    }

    return answer;
  }, [answer, isGovernanceScore]);

  return (
    <Container>
      {icon}
      <Typography variant='body' color={colors.primary[90]}>
        {label}
      </Typography>
    </Container>
  );
};
