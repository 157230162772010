import { Button, Chip, styled, useTheme } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { ReactComponent as LocationIcon } from '../assets/icons/location.svg';
import { Typography } from './Typography/Typography';
import { ROUTES } from '../constants/routes';
import { Company, COMPANY_STAGE, COMPANY_TYPE } from '../types';
import { CompanyStageCell } from './Table/CellRenderers/CompanyStageCell';
import { ReactComponent as Edit } from '../assets/icons/edit.svg';
import { EditCompanyModal } from './EditCompanyModal/EditCompanyModal';
import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import { useCompaniesPageRouteName } from '../hooks/useCompaniesPageRouteName';
import { ExportSingleCompany } from './ExportSingleCompany';

interface Props {
  company: Company;
  isInScreeningFlow?: boolean;
  isActivityDrawerOpen?: boolean;
  onToggleActivityDrawer?: () => void;
}

const HeaderWrapper = styled('div')<{ hasBottomBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 20px 40px 20px 40px;
  gap: 20px;
  border-bottom: ${({ theme, hasBottomBorder }) =>
    hasBottomBorder ? `1px solid ${theme.colors.primary[30]}` : 'none'};
`;

const MainDetailsWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 12px;
`;

const Img = styled('img')`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
`;

const CompanyDetailsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 32px;
  height: 46px;
  border-radius: 4px;
  padding-right: 12px;
`;

const CompanyDetailsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const EditIcon = styled(Edit)`
  path {
    fill: ${({ theme }) => theme.colors.accent[50]};
  }
`;

export const SingleCompanyHeader: FC<Props> = ({
  company,
  isInScreeningFlow,
  isActivityDrawerOpen,
  onToggleActivityDrawer,
}) => {
  const { colors } = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const companyPageRouteName = useCompaniesPageRouteName();

  const { state } = useLocation();

  const prevRoute = useMemo(() => {
    if (!state?.prevRoute) return `/${ROUTES.COMPANIES}`;

    return state?.prevRoute;
  }, [state?.prevRoute]);

  const prevRouteName = useMemo(() => {
    if (!state?.prevRouteName) return companyPageRouteName;

    return state?.prevRouteName;
  }, [companyPageRouteName, state?.prevRouteName]);

  const showBottomBorder = useMemo(() => {
    return (
      company?.stage === COMPANY_STAGE.SCREENING || company?.type === COMPANY_TYPE.NOT_CONTROLLED
    );
  }, [company?.stage, company?.type]);

  return (
    <HeaderWrapper hasBottomBorder={showBottomBorder}>
      <Breadcrumbs prevRoutes={[{ prevRoute, prevRouteName }]} currentRouteName={company?.name} />
      <MainDetailsWrapper>
        <TitleWrapper>
          {company.logoUrl && <Img src={company.logoUrl} />}
          <Typography variant='h1' color={colors.primary[90]}>
            {company.name}
          </Typography>
        </TitleWrapper>
        <CompanyDetailsContainer>
          <CompanyDetailsWrapper>
            <CompanyStageCell
              chipStyle={{
                height: '26px',
              }}
              stage={company.stage}
            />
            {company?.sectors[0] && (
              <Chip
                style={{
                  backgroundColor: colors.primary[20],
                  height: '26px',
                }}
                label={
                  <Typography variant='body' color={colors.primary[90]}>
                    {company?.sectors[0].name}
                  </Typography>
                }
              />
            )}
            {company?.city && company?.country && (
              <Chip
                style={{
                  backgroundColor: 'transparent',
                  height: '26px',
                  fontSize: '14px',
                }}
                icon={<LocationIcon style={{ marginLeft: '-5px' }} />}
                label={
                  <Typography variant='body' color={colors.primary[90]}>
                    {company?.city}, {company?.country}
                  </Typography>
                }
              />
            )}
          </CompanyDetailsWrapper>
          <ButtonsWrapper>
            <Button
              variant={'text'}
              startIcon={<EditIcon />}
              style={{ minWidth: 'auto' }}
              onClick={() => setIsModalOpen(true)}
            >
              <Typography variant='subtitle2' color={colors.accent[50]}>
                Edit
              </Typography>
            </Button>
            {!isInScreeningFlow && <ExportSingleCompany />}
            {!isInScreeningFlow && (
              <Button
                variant='outlined'
                style={{
                  minWidth: 'auto',
                  backgroundColor: isActivityDrawerOpen ? colors.accent[20] : colors.accent[5],
                  borderColor: isActivityDrawerOpen ? colors.accent[50] : colors.accent[20],
                }}
                onClick={() => onToggleActivityDrawer?.()}
              >
                <Typography variant='subtitle2' color={colors.accent[50]}>
                  Activity
                </Typography>
              </Button>
            )}
          </ButtonsWrapper>
        </CompanyDetailsContainer>
      </MainDetailsWrapper>
      <EditCompanyModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </HeaderWrapper>
  );
};
