import { useMemo } from 'react';
import { PieChart } from '../../../../../../../components/Charts/PieChart/PieChart';
import { AggregatedReportWidget, ChartDataItem } from '../../../../../../../types';
import { useWidgetQuestion } from '../../hooks/useWidgetQuestion';
import { WidgetCard } from '../WidgetCard';
import { OTHER_OPTION } from '../../constants';
import { CallbackDataParams, TopLevelFormatterParams } from 'echarts/types/dist/shared';
import { styled } from '@mui/material';
import { usePieChartWidgetData } from './usePieChartWidgetData';

const Wrapper = styled('div')`
  flex: 1;
  min-width: 400px;
`;

interface Props {
  widget: AggregatedReportWidget;
}

export const PieChartWidget = ({ widget }: Props) => {
  const widgetQuestion = useWidgetQuestion(widget);

  const widgetData = usePieChartWidgetData(widget);

  const total = useMemo(() => {
    return widgetData.reduce((acc, item) => acc + (item.value || 0), 0);
  }, [widgetData]);

  const labelFormatter = (params: any) => {
    const { name, value } = (params as CallbackDataParams).data as ChartDataItem;

    const pctValue = value ? `${((value / total) * 100).toFixed(2)}%` : '0%';

    return `{icon${params.dataIndex}|} ${name} \n{bold|${pctValue}}`;
  };

  const tooltipFormatter = (params: TopLevelFormatterParams) => {
    const { name, value, id, groupedData, icon } = (params as CallbackDataParams)
      .data as ChartDataItem;

    if (id === OTHER_OPTION.id) {
      if (!groupedData?.length) return '';

      return `
        <div style="
            display:flex;
            flex-direction:column;
            width: 200px;
            gap: 5px;
        ">
        ${
          groupedData
            .map((data) => {
              const { name, value } = data;
              const pctValue = value ? `${((value / total) * 100).toFixed(2)}%` : '0%';
              const companyLabel = value === 1 ? 'company' : 'companies';
              return `<div style="
            font-size: 14px;
            color: #161717;
            display:flex;
            justify-content:space-between;
          ">
           <div style="
            display:flex;
            gap: 5px;
            align-items: center;
          ">
          ${icon ? `<image style="height: 16px; width: 16px;" src="${icon}" />` : ''}
            <div style="text-wrap: wrap">${name}</div> <strong style="font-weight: bold;">${pctValue}</strong> </div>
            <strong style="font-weight: bold;">${value} ${companyLabel}</strong>
          </div>`;
            })
            ?.join('') || ''
        }
        </div>
      `;
    }

    const pctValue = value ? `${((value / total) * 100).toFixed(2)}%` : '0%';
    const companyLabel = value === 1 ? 'company' : 'companies';
    return `
       <div style="
            font-size: 14px;
            color: #161717;
            display:flex;
            gap: 15px;
          ">
          <div style="
            display:flex;
            gap: 5px;
            align-items: center;
          ">
       ${icon ? `<image style="height: 16px; width: 16px;" src="${icon}" />` : ''}
            ${name} <strong style="font-weight: bold;">${pctValue}</strong>
            </div>
            <strong style="font-weight: bold;">${value} ${companyLabel}</strong> 
          </div>
    `;
  };

  const richStyles = widgetData.reduce((acc: any, item, index) => {
    if (!item.icon) return acc;
    acc[`icon${index}`] = {
      height: 16,
      width: 16,
      backgroundColor: {
        image: item.icon,
      },
    };
    return acc;
  }, {});

  if (!widgetQuestion) return null;

  return (
    <Wrapper>
      <WidgetCard widget={widget} titleContainerStyles={{ flexDirection: 'column', gap: '3px' }}>
        <PieChart
          data={widgetData || []}
          customLabelFormatter={labelFormatter}
          customTooltipFormatter={tooltipFormatter}
          richStyles={richStyles}
        />
      </WidgetCard>
    </Wrapper>
  );
};
