import { useMemo } from 'react';
import { useCompanies } from '../queries/useCompanies';
import { useAtomValue } from 'jotai';
import { activeRoleState, userState } from '../state/UIState';
import {
  COMPANY_INFO_STEP,
  COMPANY_STAGE,
  REPORT_STATUS,
  STEP_TYPE,
  User,
  USER_ROLE,
} from '../types';
import { DUE_DILIGENCE_SURVEY_NAME, SASB_ASSESSMENT_NAME } from '../constants/constants';
import { useAllSteps } from './useAllSteps';

export const useIssuersPendingEvaluation = () => {
  const { isLoading, data: companies } = useCompanies();

  const activeRole = useAtomValue(activeRoleState);

  const { allSteps } = useAllSteps();
  const user = useAtomValue(userState) as User;

  const companiesData = useMemo(() => {
    if (!activeRole) return [];

    const pendingIssuers = companies?.filter(
      (company) => company?.stage === COMPANY_STAGE.SCREENING
    );

    return pendingIssuers?.filter((issuer) => {
      if (!user) return;

      const notCompletedSteps = allSteps?.filter(
        (step) => !issuer?.completedWorkflowSteps?.includes(step?.id)
      );

      const hasAccessToSteps = notCompletedSteps?.some((step) => {
        const accessRoles = step?.accessRoles?.map((role) => role.name);
        const evaluationRoles = step?.approvalRoles?.map((role) => role.name);
        const isAssessmentStep = step?.type === STEP_TYPE?.ASSESSMENT;
        const isAnalystRatingStep = step?.companyInfoStep === COMPANY_INFO_STEP.ANALYST_RATING;
        let hasAccess = accessRoles?.includes(activeRole);
        let hasEvaluationAccess = evaluationRoles?.includes(activeRole);

        if (activeRole === USER_ROLE?.ANALYST) {
          hasAccess = accessRoles?.includes(activeRole);
          hasEvaluationAccess = evaluationRoles?.includes(activeRole);
        }

        if (isAssessmentStep) {
          const stepReport = issuer.reports?.find((report) => {
            if (step?.name === DUE_DILIGENCE_SURVEY_NAME) {
              return report?.survey?.name === DUE_DILIGENCE_SURVEY_NAME;
            }

            if (step?.name === SASB_ASSESSMENT_NAME) {
              return report?.survey?.name === SASB_ASSESSMENT_NAME;
            }

            return report?.survey?.id === step?.survey?.id;
          });

          if (stepReport?.approvedBy?.includes(user.id)) {
            return false;
          }

          if (
            !stepReport ||
            stepReport?.status === REPORT_STATUS.NOT_STARTED ||
            stepReport?.status === REPORT_STATUS.IN_PROGRESS ||
            stepReport?.status === REPORT_STATUS.REJECTED
          ) {
            return hasAccess;
          }

          if (stepReport?.status === REPORT_STATUS?.IN_REVIEW) {
            return hasEvaluationAccess;
          }
        }

        if (isAnalystRatingStep) {
          if (issuer?.analystRatingApprovedBy?.includes(user.id)) {
            return false;
          }

          if (!issuer?.esgRiskLevel && !issuer?.esgOpportunitiesLevel) {
            return hasAccess;
          }

          if (issuer?.esgRiskLevel && issuer?.esgOpportunitiesLevel) {
            return hasEvaluationAccess;
          }
        }

        return hasAccess;
      });

      return hasAccessToSteps;
    });
  }, [activeRole, allSteps, companies, user]);

  return useMemo(() => {
    return {
      isLoading,
      companiesData,
    };
  }, [companiesData, isLoading]);
};
