import { useMemo } from 'react';
import { FormField } from '../../../../../../../components/FormField/FormField';
import { SingleSelect } from '../../../../../../../components/SingleSelect/SingleSelect';
import {
  CORE_KPI_FORMAT,
  REPORT_DATA_SOURCE,
  SelectItem,
  WIDGET_TYPE,
} from '../../../../../../../types';
import { useBreakdownData } from '../../../../../hooks/useBreakdownData';
import { DatePicker } from '../../../../../../../components/DatePicker/DatePicker';
import { styled } from '@mui/material';
import { ChipsSelectorV2 } from '../../../../../../../components/ButtonSelector/ChipsSelectorV2';

interface Props {
  selectedQuestion?: SelectItem | null;
  setSelectedQuestion: (question: SelectItem | null) => void;
  startYear?: string;
  endYear?: string;
  setStartYear: (year: string) => void;
  setEndYear: (year: string) => void;
  widgetTypeOptions: SelectItem[];
  selectedType?: SelectItem | null;
  setSelectedType: (type: SelectItem | null) => void;
}

export const PerformanceTypeContent = ({
  selectedQuestion,
  setSelectedQuestion,
  startYear,
  endYear,
  setStartYear,
  setEndYear,
  widgetTypeOptions,
  selectedType,
  setSelectedType,
}: Props) => {
  const { selectedColumns } = useBreakdownData();

  const questionOptions: SelectItem[] = useMemo(() => {
    return selectedColumns
      ?.filter(
        (q) => q.sourceType === REPORT_DATA_SOURCE.PERFORMANCE && q.format !== CORE_KPI_FORMAT.TEXT
      )
      ?.map((q) => {
        if (!q) return null;
        return { id: q?.id, value: q?.name };
      })
      ?.filter((q) => Boolean(q)) as SelectItem[];
  }, [selectedColumns]);
  return (
    <>
      <FormField label={"KPI's"}>
        <SingleSelect
          style={{ width: '100%' }}
          options={questionOptions}
          value={selectedQuestion}
          onChange={(_, questionOption) => setSelectedQuestion(questionOption)}
          disablePortal
          fieldPlaceholder={'Select Question'}
        />
      </FormField>
      <FormField label={'Widget Type'}>
        <ChipsSelectorV2
          options={widgetTypeOptions?.filter((w) => w?.id !== WIDGET_TYPE.PIE_CHART)}
          selectedItem={selectedType || null}
          onSelect={(type) => setSelectedType(type)}
        />
      </FormField>
      <YearRangeWrapper>
        <FormField label={'Start Year'}>
          <DatePicker
            selectedDate={startYear || ''}
            setSelectedDate={(date) =>
              date?.getFullYear() && setStartYear(date.getFullYear().toString())
            }
            width='230px'
            views={['year']}
            shouldDisableYear={(year) => {
              return year.getFullYear() > parseInt(endYear || '') || year.getFullYear() < 2000;
            }}
          />
        </FormField>
        <Divider />
        <FormField label={'End Year'}>
          <DatePicker
            selectedDate={endYear || ''}
            setSelectedDate={(date) =>
              date?.getFullYear() && setEndYear(date.getFullYear().toString())
            }
            width='230px'
            views={['year']}
          />
        </FormField>
      </YearRangeWrapper>
    </>
  );
};

const YearRangeWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Divider = styled('div')`
  width: 8px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary[70]};
  position: relative;
  top: 38px;
`;
