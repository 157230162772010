import { useParams } from 'react-router';
import { COMPANY_TYPE } from '../../../../types';
import { useCompanyById } from '../../../../queries/useCompanies';
import { ControlledCompany } from './ControlledCompany';
import { NotControlledCompany } from './NotControlledCompany';

export const EsgCompleteCompanyContent = () => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));

  if (company?.type === COMPANY_TYPE.CONTROLLED) {
    return <ControlledCompany />;
  }

  return <NotControlledCompany />;
};
