import { styled, useTheme } from '@mui/material';
import { AGGREGATED_REPORT_ISSUERS_FILTER, SelectItem } from '../../../../../types';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { CompaniesFilter } from './components/CompaniesFilter';
import { RiskLevelFilter } from './components/RiskLevelFilter';
import { GovScoreFilter } from './components/GovScoreFilter';
import { TypeFilter } from './components/TypeFilter';
import { ActivePositionFilter } from './components/ActivePositionFilter';
import { CompanySectorsFilter } from './components/CompanySectorsFilter';
import { GeographyFilter } from './components/GeographyFIlter';
import { OpportunitiesLevelFilter } from './components/OpportunitiesLevelFilter';
import { Typography } from '../../../../../components/Typography/Typography';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close-gray.svg';

interface Props {
  issuerFilterLabel: string;
  issuerFilterType: AGGREGATED_REPORT_ISSUERS_FILTER;
  setSelectedFilters: Dispatch<SetStateAction<SelectItem[]>>;
  removeFilterFromReport: (filterType: AGGREGATED_REPORT_ISSUERS_FILTER) => void;
}

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  height: 32px;
`;

const LabelWrapper = styled('div')`
  width: 144px;
  padding: 0 12px;
`;

const FilterWrapper = styled('div')`
  flex: 1;
  border-left: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  border-right: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};

  & .MuiInputBase-root.MuiOutlinedInput-root {
    border: none;
    width: 100%;
    padding-left: 4px;
  }

  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  & .MuiOutlinedInput-root .MuiSelect-select {
    padding: 0;
    border-radius: 0;
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-ee9qdn-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding-right: 16px;
  }
`;

const CloseIconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const FilterItem = ({
  issuerFilterLabel,
  issuerFilterType,
  setSelectedFilters,
  removeFilterFromReport,
}: Props) => {
  const { colors } = useTheme();

  const filter = useMemo(() => {
    if (issuerFilterType === AGGREGATED_REPORT_ISSUERS_FILTER.COMPANIES) return <CompaniesFilter />;

    if (issuerFilterType === AGGREGATED_REPORT_ISSUERS_FILTER.RISK_LEVEL)
      return <RiskLevelFilter />;

    if (issuerFilterType === AGGREGATED_REPORT_ISSUERS_FILTER.OPPORTUNITIES)
      return <OpportunitiesLevelFilter />;

    if (issuerFilterType === AGGREGATED_REPORT_ISSUERS_FILTER.GOVERNANCE_SCORE_TYPE)
      return <GovScoreFilter />;

    if (issuerFilterType === AGGREGATED_REPORT_ISSUERS_FILTER.TYPE) return <TypeFilter />;

    if (issuerFilterType === AGGREGATED_REPORT_ISSUERS_FILTER.ACTIVE_POSITION)
      return <ActivePositionFilter />;

    if (issuerFilterType === AGGREGATED_REPORT_ISSUERS_FILTER.SECTOR)
      return <CompanySectorsFilter />;

    if (issuerFilterType === AGGREGATED_REPORT_ISSUERS_FILTER.GEOGRAPHY) return <GeographyFilter />;
  }, [issuerFilterType]);

  const onRemoveFilter = () => {
    setSelectedFilters((prev) => prev.filter((item) => item.value !== issuerFilterLabel));
    removeFilterFromReport(issuerFilterType);
  };

  return (
    <Wrapper>
      <LabelWrapper>
        <Typography variant='body' color={colors.primary[90]}>
          {issuerFilterLabel}
        </Typography>
      </LabelWrapper>
      <FilterWrapper>{filter}</FilterWrapper>
      <CloseIconWrapper onClick={onRemoveFilter}>
        <CloseIcon />
      </CloseIconWrapper>
    </Wrapper>
  );
};
