import { Button, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../../../../../components/Typography/Typography';

const Link = styled(Button)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
`;

interface Props {
  link: string;
  text: string;
}

export const LinkContent = ({ link, text }: Props) => {
  const { colors } = useTheme();

  return (
    <Link href={link} variant='text'>
      <Typography variant='subtitle2' color={colors.accent[50]}>
        {text}
      </Typography>
    </Link>
  );
};
