import { Checkbox, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../components/Typography/Typography';
import { AggregatedReportColumn } from '../../../../../types';
import { useMemo } from 'react';

import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../state/UIState';

const Wrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

interface Props {
  column: AggregatedReportColumn;
}

export const ColumnSelectItem = ({ column }: Props) => {
  const { colors } = useTheme();
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const onToggleSelect = () => {
    if (!aggregatedReport) return;

    let updatedSelectedColumns = aggregatedReport?.selectedColumns;
    const selectedColumnsIds = aggregatedReport?.selectedColumns?.map((c) => c.id);

    if (selectedColumnsIds?.includes(column.id)) {
      updatedSelectedColumns = aggregatedReport.selectedColumns?.filter(
        (col) => col.id !== column.id
      );
    } else {
      updatedSelectedColumns = [...(aggregatedReport?.selectedColumns || []), column];
    }

    setAggregatedReport({ ...aggregatedReport, selectedColumns: updatedSelectedColumns });
  };

  const isSelected = useMemo(() => {
    const selectedColumnIds = aggregatedReport?.selectedColumns?.map((c) => c.id);
    return selectedColumnIds?.includes(column.id);
  }, [aggregatedReport?.selectedColumns, column.id]);

  return (
    <Wrapper>
      <Checkbox checked={isSelected} onClick={onToggleSelect} style={{ padding: 0 }} />
      <Typography variant='body' color={colors.primary[90]}>
        {column.name}
      </Typography>
    </Wrapper>
  );
};
