import { useAuth0 } from '@auth0/auth0-react';
import { Http } from '../services/Http';
import { useEffect } from 'react';
import { fetchUserByEmail, useCreateUser } from '../queries/useUsers';
import { useSetAtom } from 'jotai';
import { impersonateRoleState, userState } from '../state/UIState';
import { LOCAL_USER_STATE, USER_ROLE } from '../types';

export function useInitUser() {
  const { getAccessTokenSilently, user: authUser, isAuthenticated } = useAuth0();

  const setUserLocalState = useSetAtom(userState);
  const setImpersonateRole = useSetAtom(impersonateRoleState);
  const createUser = useCreateUser();

  useEffect(() => {
    const initUser = async () => {
      if (isAuthenticated && !Http.axios) {
        const accessToken = getAccessTokenSilently();
        Http.axios = await Http.init(accessToken);
      }

      if (!authUser || !Http.axios) return;

      const user = await fetchUserByEmail(authUser?.email || '');
      setUserLocalState(user || LOCAL_USER_STATE.NO_USER_FOUND);

      if (user) {
        setUserLocalState(user);
        const impersonateRole = localStorage.getItem('impersonate');
        if (impersonateRole && user.role.name === USER_ROLE.ADMIN) {
          setImpersonateRole(impersonateRole as USER_ROLE);
        }
      }
      if (!user) {
        const newUser = await createUser.mutateAsync({
          fullName: authUser?.name || authUser?.given_name || authUser?.family_name || '',
          email: authUser?.email ?? '',
        });
        setUserLocalState(newUser);
      }
    };
    initUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, authUser?.email, isAuthenticated, Http?.axios?.config?.baseURL]);
}
