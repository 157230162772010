import {
  AggregatedReportColumn,
  Company,
  QUESTION_TYPE,
  QuestionOption,
  REPORT_DATA_SOURCE,
  Response,
} from '../../../types';
import { getChecklistAnswer } from './getChecklistAnswer';
import { getExclusionAnswer } from './getExclusionAnswer';

interface Args {
  selectedColumn?: AggregatedReportColumn;
  allResponsesMap: Record<string, Response>;
  company?: Company;
}
export const getAnswer = ({ selectedColumn, allResponsesMap, company }: Args) => {
  if (selectedColumn?.sourceType === REPORT_DATA_SOURCE.ASSESSMENT) {
    const assessmentAnswer = allResponsesMap[selectedColumn.id];
    let textResponse = '';
    if (selectedColumn?.assessmentQuestion?.type === QUESTION_TYPE.MULTI) {
      textResponse = (assessmentAnswer as QuestionOption[])?.map((a) => a.name)?.join('; ') || '';
    }
    if (
      selectedColumn?.assessmentQuestion?.type === QUESTION_TYPE.SINGLE ||
      selectedColumn?.assessmentQuestion?.type === QUESTION_TYPE.YES_NO
    ) {
      textResponse = (assessmentAnswer as QuestionOption)?.name || '';
    }
    if (selectedColumn?.assessmentQuestion?.type === QUESTION_TYPE.TEXT) {
      textResponse = assessmentAnswer as string;
    }
    return textResponse || '';
  }

  if (selectedColumn?.sourceType === REPORT_DATA_SOURCE.EXCLUSIONS) {
    const answer = getExclusionAnswer({ company, questionId: selectedColumn.id });

    return answer || 'N/A';
  }

  if (selectedColumn?.sourceType === REPORT_DATA_SOURCE.CHECKLIST) {
    return getChecklistAnswer({
      company,
      questionId: selectedColumn.id,
    });
  }

  if (selectedColumn?.sourceType === REPORT_DATA_SOURCE.COMPANY_DETAILS) {
    return (company?.[selectedColumn.id as keyof Company] as string) || 'N/A';
  }

  return '';
};
