import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { getReportProgressPercentage, isTableQuestionAnswered } from '../queries/useRequestGroups';
import {
  COMPANY_TYPE,
  ChecklistCategory,
  ChecklistData,
  ChecklistItem,
  Company,
  CompanyKpisData,
  CoreKpi,
  CoreKpiCategory,
  CoreKpiRequestFrequency,
  EXPORT_COMPANIES_TYPE,
  EvaluationRule,
  ExclusionCategory,
  ExclusionCondition,
  ExportCompanyColumn,
  ExportCompanyExclusionsColumn,
  ExtendedToastProps,
  FlattenedItem,
  KPISValueResponse,
  LoadingId,
  MENU,
  QUESTION_TYPE,
  QuestionTableData,
  Report,
  Response,
  QuestionReview,
  ReviewSidebarStats,
  SelectItem,
  Survey,
  SurveyQuestion,
  SurveySection,
  SurveyTemplateSection,
  USER_ROLE,
  User,
  WorkflowStage,
  WorkflowStep,
  RISK_LEVEL,
  REVIEW_TYPE,
  EVALUATION_SCALE,
  SURVEY_FREQUENCY,
  LOCAL_USER_STATE,
  AGGREGATED_REPORT_FILTER,
  AggregatedReport,
} from '../types';
import { HEADER_ROW_ID, KPI_VALUE_COLUMN_ID } from '../constants/defaultValues';
import { buildTree, flattenTree } from '../utils/treeUtilities';
import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { getQuestionsWithSubQuestions } from '../utils/getQuestionsWithSubQuestions';
import { getEvaluationRules } from '../utils/getEvaluationRules';

//create request state
export const requestNameState = atom<string>('');
export const createRequestCompletedProgressState = atom<0 | 33 | 50 | 66 | 100>(0);
export const selectedSurveyState = atom<Survey | null>(null);
export const selectedCompaniesState = atom<Company[]>([]);
export const selectedFrequencyState = atom<SelectItem | null>(null);

//upload company kpis state
export const uploadedCompanyKpisFileState = atom<File | null>(null);
export const uploadCompanyKpisProgressState = atom<0 | 50 | 100>((get) => {
  const file = get(uploadedCompanyKpisFileState);
  if (file) return 50;
  return 0;
});

//upload companies state
export const excelCompaniesState = atom<Record<string, string | number>[]>([]);
export const rowSelectionUploadCompaniesState = atom<Record<number, boolean>>({});
export const uploadedFileState = atom<File | null>(null);
export const uploadedFileProgress = atom<0 | 100>(0);

export const excelCompaniesMapState = atom<Record<number, Record<string, string | number>>>(
  (get) => {
    const data = get(excelCompaniesState);

    const map: Record<number, Record<string, string | number>> = {};
    data.forEach(({ id, ...rest }) => {
      map[id as number] = rest;
    });
    return map;
  }
);

export const selectedCompaniesToUploadState = atom<Record<string, string | number>[]>((get) => {
  const rowSelection = get(rowSelectionUploadCompaniesState);
  const companiesMap = get(excelCompaniesMapState);
  const selectedIds = Object.keys(rowSelection).map((key) => parseInt(key));
  const newSelectedCompanies = selectedIds.map((id) => companiesMap[id]);
  return newSelectedCompanies;
});

export const uploadCompaniesProgressState = atom<0 | 50 | 100>((get) => {
  const excelCompanies = get(excelCompaniesState);
  const selectedCompanies = get(selectedCompaniesToUploadState);
  if (excelCompanies.length && selectedCompanies.length) return 100;
  if (excelCompanies.length) return 50;
  return 0;
});

// export companies state
export const exportCompaniesProgressState = atom<0 | 50 | 100>(0);
export const exportCompaniesState = atom<Company[]>([]);
export const exportColumnsState = atom<(ExportCompanyColumn | ExportCompanyExclusionsColumn)[]>([]);
export const exportTypeState = atom<EXPORT_COMPANIES_TYPE | null>(null);

//survey state
export const isApplyingEvaluationRulesModeActiveState = atom<boolean>(false);
export const isSingleActionMoveModeActiveState = atom<boolean>(false);
export const isMultiActionMoveModeActiveState = atom<boolean>(false);
export const selectedQuestionsState = atom<SurveyQuestion[]>([]);
export const rulesToApplyState = atom<EvaluationRule[]>([]);
export const selectedSectionToMoveQuestionsState = atom<SurveySection | null>(null);

export const surveyTemplatesDataState = atom<{ name: string; sections: SurveyTemplateSection[] }[]>(
  []
);
export const filteredSurveyTemplatesDataState = atom<
  { name: string; sections: SurveyTemplateSection[] }[]
>([]);

export const evaluationRulesState = atom<EvaluationRule[]>(
  getEvaluationRules(EVALUATION_SCALE.BINARY)
);
export const evaluationScaleState = atom<EVALUATION_SCALE | undefined>(EVALUATION_SCALE.BINARY);
export const includeEvaluationState = atom<boolean>(false);
export const includeWeightState = atom<boolean>(false);
export const includeQuestionNumbersState = atom<boolean>(true);

export const activeSurveyIdState = atom<number | null>(null);
export const activeSurveyState = atom<Survey | null>(null);

export const sectionsState = atom<SurveySection[]>([]);

export const sectionsMapState = atom((get) => {
  const sections = get(sectionsState);
  const sectionsMap = new Map<string, SurveySection>();
  function createSectionsMap(sections: SurveySection[]) {
    sections.forEach((section) => {
      sectionsMap.set(section.id, { ...section });
      if (section.subSections) {
        createSectionsMap(section.subSections);
      }
    });
  }
  createSectionsMap(sections);
  return sectionsMap;
});

export const flattenedSectionsState = atom(
  (get) => {
    const sections = get(sectionsState);
    return flattenTree(sections);
  },
  (get, set, flattenTree: FlattenedItem[]) => {
    const sections = buildTree(flattenTree);
    set(sectionsState, sections);
  }
);

export const activeSectionIdState = atom<string | null>(null);

export const sectionState = atomFamily((sectionId: string | null) =>
  atom(
    (get) => {
      if (!sectionId) return null;
      const sectionsMap = get(sectionsMapState);
      return sectionsMap.get(sectionId) ?? null;
    },
    (get, set, section: SurveySection) => {
      const flattenedSections = get(flattenedSectionsState);
      const newSections = flattenedSections.map((s) => {
        if (s.id === section.id) {
          return section;
        }
        return s;
      });

      set(flattenedSectionsState, newSections as FlattenedItem[]);
    }
  )
);

export const activeQuestionIdState = atom<string | null>(null);
export const activeFollowUpQuestionIdState = atom<string | null>(null);

export const hoveredRowIdState = atom<string | null>(null);
export const hoveredColumnIdState = atom<string | null>(null);
export const activeRowIdState = atom<string | null>(null);
export const activeColumnIdState = atom<string | null>(null);

export const questionState = atomFamily((questionId: string | null) =>
  atom(
    (get) => {
      const activeSectionId = get(activeSectionIdState);
      if (!activeSectionId) return null;
      const activeSection = get(sectionState(activeSectionId));
      return activeSection && questionId
        ? activeSection.questions.find((question) => question.id === questionId)
        : null;
    },
    (get, set, question: SurveyQuestion) => {
      const section = get(sectionState(question.sectionId));
      if (!section) return;
      const newQuestions = section.questions.map((q) => {
        if (q.id === question.id) {
          return question;
        }
        return q;
      });
      set(sectionState(question.sectionId), { ...section, questions: newQuestions });
    }
  )
);

export const activeSubQuestionState = atom<SurveyQuestion | null>(null);

export const responsesState = atom<Record<string, Response>>({});
export const evaluationState = atom<Record<string, EvaluationRule>>({});
export const reviewState = atom<Record<string, QuestionReview>>({});

export const survayNameState = atom<string>('');
export const isNameModalOpenState = atom<boolean>(false);

export const surveyCompletedPercentageState = atom((get) => {
  const sectionsMap = get(sectionsMapState);
  const responses = get(responsesState);
  return getReportProgressPercentage(sectionsMap, responses);
});

export const reportEvaluationCompletedPercentageState = atom((get) => {
  const sectionsMap = get(sectionsMapState);
  const evaluation = get(evaluationState);
  const allSectionsArr = Array.from(sectionsMap.values());
  const responses = get(responsesState);
  const stats = allSectionsArr.reduce(
    (acc: Record<'all' | 'answered', number>, section) => {
      const allQuestions = getQuestionsWithSubQuestions(section, responses)?.filter(
        (q) => responses[q?.id]
      );
      const answeredQuestions = allQuestions.filter((question) => {
        return evaluation[question.id];
      });
      acc.all += allQuestions.length;
      acc.answered += answeredQuestions.length;
      return acc;
    },
    { all: 0, answered: 0 }
  );
  const percentage = (stats.answered / stats.all) * 100;
  return Math.round(isNaN(percentage) ? 0 : percentage);
});

export const reportReviewCompletedPercentageState = atom((get) => {
  const sectionsMap = get(sectionsMapState);
  const review = get(reviewState);
  const allSectionsArr = Array.from(sectionsMap.values());
  const responses = get(responsesState);
  const stats = allSectionsArr.reduce(
    (acc: Record<'all' | 'reviewed', number>, section) => {
      const allQuestions = getQuestionsWithSubQuestions(section, responses)?.filter(
        (q) => responses[q?.id]
      );
      const reviewedQuestions = allQuestions.filter((question) => {
        return review?.[question.id];
      });
      acc.all += allQuestions.length;
      acc.reviewed += reviewedQuestions.length;
      return acc;
    },
    { all: 0, reviewed: 0 }
  );
  const percentage = (stats.reviewed / stats.all) * 100;
  return Math.round(isNaN(percentage) ? 0 : percentage);
});

export const sectionAnsweredPercentageState = atomFamily((sectionId: string) =>
  atom((get) => {
    const section = get(sectionState(sectionId));
    const responses = get(responsesState);
    if (!section) return 0;
    function recursiveCountAnsweredQuestionsAndAllQuestions(section: SurveySection) {
      const allQuestions = getQuestionsWithSubQuestions(section, responses);
      let allQuestionsNum = allQuestions?.length;
      let answeredQuestions =
        allQuestions.filter((question) => {
          if (question.type === QUESTION_TYPE.TABLE) {
            const tableData = question.tableData as QuestionTableData;
            const readOnlyColumns = tableData?.readOnlyColumns ?? [];
            return isTableQuestionAnswered(
              responses[question.id] as QuestionTableData,
              readOnlyColumns
            );
          }

          return responses[question.id] !== null && responses[question.id] !== undefined;
        }).length ?? 0;

      section.subSections.forEach((subSection) => {
        const { answered, all } = recursiveCountAnsweredQuestionsAndAllQuestions(subSection);
        allQuestionsNum += all;
        answeredQuestions += answered;
      });
      return { all: allQuestionsNum, answered: answeredQuestions };
    }
    const { all, answered } = recursiveCountAnsweredQuestionsAndAllQuestions(section);
    const percentage = (answered / all) * 100;
    return Math.round(isNaN(percentage) ? 0 : percentage);
  })
);
export const kpisSurveySectionAnsweredPercentageState = atomFamily((sectionId: string) =>
  atom((get) => {
    const section = get(sectionState(sectionId));
    const responses = get(responsesState);

    if (!section) return 0;
    if (!section.questions.length) return 0;
    if (section.questions[0].type !== QUESTION_TYPE.TABLE) return 0;
    const [question] = section.questions;
    const response = responses[question.id] as QuestionTableData;
    const allRows = response?.rows.length - 1;
    const answeredRows =
      response?.rows.filter((row) => {
        if (row.id === HEADER_ROW_ID) return true;
        return (row[KPI_VALUE_COLUMN_ID] as KPISValueResponse)?.value;
      }).length - 1;

    const percentage = (answeredRows / allRows) * 100;
    return Math.round(isNaN(percentage) ? 0 : percentage);
  })
);

export const sectionEvaluatedStatsState = atomFamily((sectionId: string) =>
  atom((get) => {
    const section = get(sectionState(sectionId));
    const evaluation = get(evaluationState);
    const responses = get(responsesState);
    if (!section) return { percentage: 0, completed: 0, allQuestions: 0 };
    function recursiveCountEvaluatedQuestionsAndAllQuestions(section: SurveySection) {
      const allQuestions = getQuestionsWithSubQuestions(section, responses)?.filter(
        (q) => responses[q?.id]
      );

      let allQuestionsNum = allQuestions?.length || 0;

      let completed =
        allQuestions.filter((question) => {
          return evaluation[question.id];
        }).length ?? 0;

      section.subSections.forEach((subSection) => {
        const { evaluated, all } = recursiveCountEvaluatedQuestionsAndAllQuestions(subSection);
        allQuestionsNum += all;
        completed += evaluated;
      });

      return { all: allQuestionsNum, evaluated: completed };
    }
    const { all, evaluated } = recursiveCountEvaluatedQuestionsAndAllQuestions(section);
    const percentage = (evaluated / all) * 100;

    return {
      percentage: Math.round(isNaN(percentage) ? 0 : percentage),
      completed: evaluated,
      allQuestions: all,
    } as ReviewSidebarStats;
  })
);
export const sectionReviewedStatsState = atomFamily((sectionId: string) =>
  atom((get) => {
    const section = get(sectionState(sectionId));
    const review = get(reviewState);
    const responses = get(responsesState);
    if (!section) return { percentage: 0, completed: 0, allQuestions: 0 };
    function recursiveCountReviewedQuestionsAndAllQuestions(section: SurveySection) {
      const allQuestions = getQuestionsWithSubQuestions(section, responses)?.filter(
        (q) => responses[q?.id]
      );

      let allQuestionsNum = allQuestions?.length || 0;

      let reviewedQuestions =
        allQuestions.filter((question) => {
          return review?.[question.id];
        }).length ?? 0;

      section.subSections.forEach((subSection) => {
        const { reviewed, all } = recursiveCountReviewedQuestionsAndAllQuestions(subSection);
        allQuestionsNum += all;
        reviewedQuestions += reviewed;
      });

      return { all: allQuestionsNum, reviewed: reviewedQuestions };
    }
    const { all, reviewed } = recursiveCountReviewedQuestionsAndAllQuestions(section);
    const percentage = (reviewed / all) * 100;

    return {
      percentage: Math.round(isNaN(percentage) ? 0 : percentage),
      completed: reviewed,
      allQuestions: all,
    } as ReviewSidebarStats;
  })
);

export const notCompletedSectionsState = atom((get) => {
  const sections = get(flattenedSectionsState);
  const filteredSections = sections.filter((section) => {
    const sectionPercantage = get(sectionAnsweredPercentageState(section.id));
    if (sectionPercantage < 100) return true;
  });
  return buildTree(filteredSections);
});

export const notCompletedSectionsKPISSurveyState = atom((get) => {
  const sections = get(flattenedSectionsState);
  const filteredSections = sections.filter((section) => {
    const sectionPercantage = get(kpisSurveySectionAnsweredPercentageState(section.id));
    if (sectionPercantage < 100) return true;
  });
  return buildTree(filteredSections);
});

export const notEvaluatedSectionsState = atom((get) => {
  const sections = get(flattenedSectionsState);
  const filteredSections = sections.filter((section) => {
    const { percentage } = get(sectionEvaluatedStatsState(section.id));
    if (percentage < 100) return true;
  });
  return buildTree(filteredSections);
});
export const notReviewedSectionsState = atom((get) => {
  const sections = get(flattenedSectionsState);
  const filteredSections = sections.filter((section) => {
    const { percentage } = get(sectionReviewedStatsState(section.id));
    if (percentage < 100) return true;
  });
  return buildTree(filteredSections);
});

export const activeReportState = atom<Report | null>(null);

export const areShowedAllQuestionsState = atom<boolean>(true);

//portfolio state
export const selectedPerformanceCategoryState = atom<SelectItem>({
  id: 'esgScore',
  value: 'Total ESG',
});

//company page state
export const showEsgHeatmapState = atom<boolean>(false);
export const companyKpisDataState = atom<CompanyKpisData | null>(null);
export const companyKpiRequestFrequencyState = atom<CoreKpiRequestFrequency | null>(null);
export const activeKpiMatrixTableCellState = atom<string | null>(null);
export const companiesTableSortingState = atom<SortingState>([]);
export const selectedCompanyAnalystsFilterState = atom<SelectItem[]>([]);
export const selectedCompanySectorsFilterState = atom<SelectItem[]>([]);
export const selectedCompanyCountriesFilterState = atom<SelectItem[]>([]);
export const selectedCompanyTypeFilterState = atom<SelectItem | null>(null);
export const selectedCompanyStageFilterState = atom<SelectItem | null>(null);
export const companyChecklistDataState = atom<ChecklistData | null>(null);
export const companySearchState = atom<string>('');

export const selectedKpisMatrixRowsState = atom<number[] | null>(null);
export const initialSelectedKpisMatrixState = atom<number[] | null>(null);
export const selectedFrequencyPreviewState = atom<CoreKpiRequestFrequency>(
  SURVEY_FREQUENCY.QUARTERLY
);

// pending evaluation page state
export const companiesTableColumnFiltersState = atom<ColumnFiltersState>([]);
export const selectedAnalystsFilterState = atom<SelectItem[]>([]);
export const selectedCountriesFilterState = atom<SelectItem[]>([]);
export const selectedSectorsFilterState = atom<SelectItem[]>([]);
export const selectedTypeFilterState = atom<SelectItem | null>(null);
export const selectedStageFilterState = atom<SelectItem | null>(null);
export const pendingCompanySearchState = atom<string>('');

//general ui state
export const loadingIdsState = atom<LoadingId[]>([]);
export const activeMenuState = atom<MENU>(MENU.MAIN);
export const isOpenToastMessageState = atom(false);
export const configToastMessageState = atom<ExtendedToastProps | null>(null);
export const userState = atom<User | null | LOCAL_USER_STATE>(LOCAL_USER_STATE.LOADING);
export const impersonateRoleState = atom<USER_ROLE | null>(null);
export const activeRoleState = atom<USER_ROLE | null>((get) => {
  const impersonateRole = get(impersonateRoleState);
  const user = get(userState);
  if (impersonateRole) return impersonateRole;
  return (user as User)?.role?.name ?? null;
});
export const isUserLoadingState = atom<boolean>(true);

//investor exclusions state
export const expandedConditionsState = atom<string[]>([]);

//Core KPIs state
export const sortedKpiCategoriesState = atom<CoreKpiCategory[]>([]);
export const activeKpiCategoryState = atom<CoreKpiCategory | null>(null);
export const isConfirmBarActiveState = atom<boolean>(false);
export const isMoveModeActiveState = atom<boolean>(false);
export const selectedCoreKpiCategoryState = atom<CoreKpiCategory | null>(null);
export const selectedCoreKpiState = atom<CoreKpi | null>(null);
export const selectedCoreKpisState = atom<CoreKpi[]>([]);
export const kpisTableRowSelectionState = atom<Record<number, boolean>>({});

// assessment summary filters state
export const controlledAssessmentSummaryFiltersState = atom<ColumnFiltersState>([]);
export const controlledAssessmentSummarySortingState = atom<SortingState>([]);
export const issuersAssessmentSummaryFiltersState = atom<ColumnFiltersState>([]);
export const issuersAssessmentSummarySortingState = atom<SortingState>([]);
export const selectedRisksFilterState = atom<SelectItem[]>([]);
export const selectedOpportunitiesFilterState = atom<SelectItem[]>([]);
export const filteredCompaniesIdsState = atom<number[]>([]);
export const issuersSelectedTypeFilterState = atom<SelectItem | null>(null);
export const issuersSectorsFilterState = atom<SelectItem[]>([]);
export const issuersCountriesFilterState = atom<SelectItem[]>([]);
export const issuersAnalystsFilterState = atom<SelectItem[]>([]);
export const issuersGovernanceScoreTypeState = atom<SelectItem | null>(null);
export const issuerActivePositionState = atom<SelectItem | null>(null);

// Checklist state
export const sortedChecklistCategoriesState = atom<ChecklistCategory[]>([]);
export const activeChecklistCategoryState = atom<ChecklistCategory | null>(null);
export const isConfirmChecklistBarActiveState = atom<boolean>(false);
export const isMoveChecklistModeActiveState = atom<boolean>(false);
export const selectedChecklistCategoryState = atom<ChecklistCategory | null>(null);
export const selectedChecklistItemState = atom<ChecklistItem | null>(null);
export const selectedChecklistItemsState = atom<ChecklistItem[]>([]);
export const checklistTableRowSelectionState = atom<Record<number, boolean>>({});

// Exclusions state
export const sortedExclusionsCategoriesState = atom<ExclusionCategory[]>([]);
export const activeExclusionCategoryState = atom<ExclusionCategory | null>(null);
export const isConfirmExclusionsBarActiveState = atom<boolean>(false);
export const selectedExclusionCategoryState = atom<ExclusionCategory | null>(null);
export const isMoveExclusionModeActiveState = atom<boolean>(false);
export const expandedExclusionsConditionsState = atom<number[]>([]);
export const activeConditionIdState = atom<number | null>(null);
export const activeExclusionIdState = atom<number | null>(null);

// Add Exclusions state
export const selectedConditionState = atom<ExclusionCondition | null>(null);
export const companyExclusionIdsState = atom<number[]>([]);
export const expandedCategoryState = atom<ExclusionCategory | null>(null);

// Workflow state
export const activePortCoStageState = atom<WorkflowStage | null>(null);
export const activeIssuersStageState = atom<WorkflowStage | null>(null);
export const activePortCoStepState = atom<WorkflowStep | null>(null);
export const activeIssuersStepState = atom<WorkflowStep | null>(null);
export const activeWorkflowCompanyType = atom<COMPANY_TYPE | null>(null);

// Analyst Rating Override
export const esgRiskLevelOverrideState = atom<RISK_LEVEL | null>(null);
export const esgOpportunitiesLevelOverrideState = atom<RISK_LEVEL | null>(null);
export const selectedReviewOptionState = atom<REVIEW_TYPE | null>(null);

// AggregatedReports
export const reportNameState = atom<string>('');
export const reportDescriptionState = atom<string>('');
export const assessmentStepsRowSelectionState = atom<Record<number, boolean>>({});
export const selectedAssessmentIdsState = atom<number[]>([]);

export const aggregatedReportFilterTypeState = atom<AGGREGATED_REPORT_FILTER | null>(null);
export const aggregatedReportState = atom<AggregatedReport | null>(null);

export const companiesState = atom<Company[]>([]);
