import { FC, useMemo } from 'react';
import { useFormik } from 'formik';
import { EsgContactSchema as validationSchema } from './EsgContactSchema';
import { ACTIVITY_TYPE, COMPANY_INFO_STEP, COMPANY_INFO_SUBTYPE, Company, User } from '../../types';
import { CompanyPayload, useUpdateCompany } from '../../queries/useCompanies';
import { AssignUserModal } from '../AssignUserModal/AssignUserModal';
import { useAllStepsByCompanyType } from '../../hooks/useAllStepsByCompanyType';
import { FormField } from '../FormField/FormField';
import { TextField } from '@mui/material';
import { useAtomValue } from 'jotai';
import { userState } from '../../state/UIState';
import { useCreateCompanyActivity } from '../../queries/useCompanyActivity';

interface Props {
  onClose: () => void;
  company?: Company;
  isOpen: boolean;
}

export const EsgContactModal: FC<Props> = ({ onClose, company, isOpen }) => {
  const updateCompany = useUpdateCompany();
  const allSteps = useAllStepsByCompanyType(company?.type);
  const { mutate: createActivity } = useCreateCompanyActivity(company?.id);

  const user = useAtomValue(userState) as User;

  const stepId = useMemo(
    () => allSteps?.find((step) => step.companyInfoStep === COMPANY_INFO_STEP.ESG_CONTACT)?.id,
    [allSteps]
  );

  const isReassign = useMemo(
    () => Boolean(company?.externalEsgContact?.email),
    [company?.externalEsgContact?.email]
  );

  const {
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    isValid,
    handleSubmit,
    dirty,
    resetForm,
  } = useFormik<Partial<CompanyPayload>>({
    initialValues: {
      esgContact: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!company || !user) return;
      onClose();

      let payload: Partial<CompanyPayload> = {
        id: company?.id,
        esgContact: values.esgContact,
      };

      const alreadyCompletedStep = company?.completedWorkflowSteps?.find((step) => step === stepId);

      if (stepId) {
        payload = {
          ...payload,
          completedWorkflowSteps: alreadyCompletedStep
            ? company?.completedWorkflowSteps
            : [...(company?.completedWorkflowSteps || []), stepId],
        };
      }
      await updateCompany.mutateAsync(payload);

      createActivity({
        type: ACTIVITY_TYPE.COMPANY_INFO_CHANGE,
        subtype: COMPANY_INFO_SUBTYPE.ASSIGN_ESG_CONTACT,
        company: company.id,
        user: user.id,
        value: {
          newValue: values.esgContact || '',
        },
      });

      resetForm();
    },
  });

  const handleError = (field: keyof CompanyPayload) => (touched[field] ? errors[field] : '');

  return (
    <AssignUserModal
      isOpen={isOpen}
      title={
        isReassign ? 'Reassign ESG Contact to the Company' : 'Assign ESG Contact to the Company'
      }
      confirmBtnText={isReassign ? 'Reassign' : 'Assign'}
      onClose={onClose}
      isConfirmDisabled={!isValid || !dirty}
      companyName={company?.name || ''}
      companyLogo={company?.logoUrl}
      handleSubmit={handleSubmit}
      assignedUser={company?.externalEsgContact?.email}
    >
      <FormField
        label={isReassign ? 'New ESG Contact Email' : 'ESG Contact Email'}
        error={handleError('esgContact')}
      >
        <TextField
          name={'esgContact'}
          value={values?.esgContact || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          variant='outlined'
        />
      </FormField>
    </AssignUserModal>
  );
};
