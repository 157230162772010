import { styled } from '@mui/material';
import { CompanyActivity } from '../../../../../../../../types';
import { ActivityContent } from './ActivityContent/ActivityContent';
import { ActivityHeader } from '../components/ActivityHeader/ActivityHeader';
import { ActivityFooter } from '../components/ActivityFooter';

interface Props {
  activity: CompanyActivity;
  isInGroupActivity?: boolean;
}

const Wrapper = styled('div')<{ isInGroupActivity?: boolean }>`
  padding: ${({ isInGroupActivity }) => (isInGroupActivity ? '6px 0 12px 0' : '12px 16px')};
`;

const ContentWrapper = styled('div')<{ isInGroupActivity?: boolean }>`
  padding-left: ${({ isInGroupActivity }) => (isInGroupActivity ? 0 : '32px')};
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Activity = ({ activity, isInGroupActivity }: Props) => {
  return (
    <Wrapper isInGroupActivity={isInGroupActivity}>
      <ActivityHeader activity={activity} isInGroupActivity={isInGroupActivity} />
      <ContentWrapper isInGroupActivity={isInGroupActivity}>
        <ActivityContent activity={activity} />
        <ActivityFooter createdDate={activity.created_at} users={activity?.user?.fullName || ''} />
      </ContentWrapper>
    </Wrapper>
  );
};
