import { Button, styled } from '@mui/material';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';

import { useCallback } from 'react';
import { ROUTES } from '../../../constants/routes';
import { useNavigate } from 'react-router';
import { HeaderTitle } from '../../../components/Layout/HeaderTitle';
import { useCreateSurvey } from '../../../queries/useSurveys';
import { getEvaluationRules } from '../../../utils/getEvaluationRules';
import { EVALUATION_SCALE, SURVEY_TYPE } from '../../../types';
import { DEFAULT_SURVEY_TITLE, getDefaultSurveySection } from '../../../constants/defaultValues';

const MainSection = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ControllsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const SurveysHeader = () => {
  const navigate = useNavigate();
  const createSurvey = useCreateSurvey();

  const onCreateSurveyClick = useCallback(async () => {
    const defaultSection = getDefaultSurveySection();

    await createSurvey.mutateAsync(
      {
        name: DEFAULT_SURVEY_TITLE,
        sections: [defaultSection],
        description: '',
        evaluationRules: getEvaluationRules(EVALUATION_SCALE.BINARY),
        type: SURVEY_TYPE.USER,
        includeEvaluation: false,
        evaluationScale: EVALUATION_SCALE.BINARY,
        includeWeight: false,
        includeQuestionNumbers: true,
      },
      {
        onSuccess: (newSuvey) => {
          navigate(`/${ROUTES.SURVEYS}/${newSuvey.id}/build`);
        },
      }
    );
  }, [createSurvey, navigate]);

  return (
    <MainSection>
      <HeaderTitle
        title='Surveys'
        description='ESG survey evaluates sustainability via ESG practices.'
      />
      <ControllsWrapper>
        <Button
          onClick={onCreateSurveyClick}
          startIcon={<AddIcon />}
          variant='contained'
          style={{ width: '179px' }}
        >
          Create Survey
        </Button>
      </ControllsWrapper>
    </MainSection>
  );
};
