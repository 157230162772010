import { useParams } from 'react-router';
import { useCompanyById, useUpdateCompany } from '../../../queries/useCompanies';
import { ExclusionSection } from './ExclusionSection';
import { ACTIVITY_TYPE, COMPANY_INFO_SUBTYPE, COMPANY_STAGE, User } from '../../../types';
import { useEffect } from 'react';
import { AnalystRating } from './AnalystRating';
import { CompanySection } from './CompanySection';
import { Objectives } from './Objectives/Objectives';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import { FeatureFlagKey } from '../../../constants/featureFlags';
import { useAtomValue } from 'jotai';
import { userState } from '../../../state/UIState';
import { useCreateCompanyActivity } from '../../../queries/useCompanyActivity';

export const OverviewTab = () => {
  const params = useParams();
  // const [showEsgHeatmap] = useAtom(showEsgHeatmapState);
  const { isLoading: isLoadingCompany, data: company } = useCompanyById(Number(params.id));
  const updateCompany = useUpdateCompany();
  const showObjectives = useFeatureFlag(FeatureFlagKey.OBJECTIVES);
  const user = useAtomValue(userState) as User;

  const { mutate: createActivity } = useCreateCompanyActivity(Number(params.id));

  useEffect(() => {
    const changeStage = async () => {
      if (!company || !user) return;
      if (company?.stage !== COMPANY_STAGE.ESG_COMPLETE) {
        await updateCompany.mutateAsync(
          {
            id: company?.id,
            stage: COMPANY_STAGE.ESG_COMPLETE,
            screeningCompletedDate: new Date().toISOString(),
          },
          {
            onSuccess: () => {
              createActivity({
                type: ACTIVITY_TYPE.COMPANY_INFO_CHANGE,
                subtype: COMPANY_INFO_SUBTYPE.STAGE_CHANGE,
                company: company?.id,
                user: user?.id,
                value: {
                  previousValue: company?.stage,
                  newValue: COMPANY_STAGE.ESG_COMPLETE,
                },
              });
            },
          }
        );
      }
    };

    changeStage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!company || isLoadingCompany) return null;

  return (
    <>
      <CompanySection>
        <AnalystRating />
        <ExclusionSection />
      </CompanySection>
      {showObjectives && <Objectives />}
    </>
  );
};
