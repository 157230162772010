import { styled } from '@mui/material';
import { AddWidget } from './components/AddWidget';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../state/UIState';
import { WIDGET_TYPE } from '../../../../../types';
import { BarChartWidget } from './components/BarChartWidget/BarChartWidget';
import { PieChartWidget } from './components/PieChartWidget/PieChartWidget';
import { CompanyBreakdownWidget } from './components/CompanyBreakdownWidget';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useCallback } from 'react';

const Wrapper = styled('div')`
  padding: 24px 0 32px 0;
`;

const WidgetsList = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-top: 24px;
`;

export const Widgets = () => {
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const handleReorder = useCallback(
    (event: DragEndEvent) => {
      if (!aggregatedReport) return;
      const widgets = aggregatedReport?.widgets;
      const { active, over } = event;
      if (widgets?.length && over?.id && active.id !== over.id) {
        const oldIndex = widgets.findIndex((s) => s.id === active.id);
        const newIndex = widgets.findIndex((s) => s.id === over.id);
        const reorderedWidgets = arrayMove(widgets, oldIndex, newIndex);

        setAggregatedReport({
          ...aggregatedReport,
          widgets: reorderedWidgets,
        });
      }
    },
    [aggregatedReport, setAggregatedReport]
  );

  return (
    <Wrapper>
      <AddWidget />
      <WidgetsList>
        <DndContext sensors={sensors} onDragEnd={handleReorder} collisionDetection={closestCenter}>
          <SortableContext
            items={aggregatedReport?.widgets || []}
            strategy={horizontalListSortingStrategy}
          >
            {aggregatedReport?.widgets?.map((widget) => {
              if (widget.widgetType === WIDGET_TYPE.BAR_CHART) {
                return <BarChartWidget key={widget.id} widget={widget} />;
              }
              if (widget.widgetType === WIDGET_TYPE.PIE_CHART) {
                return <PieChartWidget key={widget.id} widget={widget} />;
              }
              if (widget.widgetType === WIDGET_TYPE.COMPANY_BREAKDOWN) {
                return <CompanyBreakdownWidget key={widget.id} widget={widget} />;
              }
            })}
          </SortableContext>
        </DndContext>
      </WidgetsList>
    </Wrapper>
  );
};
