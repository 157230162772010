import {
  ANALYST_RATING_SUBTYPE,
  ASSESSMENT_SUBMITTED_SUBTYPE,
  CHECKLIST_SUBTYPE,
  COMPANY_CREATED_SUBTYPE,
  COMPANY_INFO_SUBTYPE,
  CompanyActivity,
  EXCLUSION_SUBTYPE,
  GOOD_GOVERNANCE_SCORE_SUBTYPE,
  KPI_RESPONSE_SUBMITTED_SUBTYPE,
  NOTE_SUBTYPE,
  SURVEY_REQUEST_SUBTYPE,
} from '../../../../../../../../../../types';

export const getActivityTitle = (activity: CompanyActivity) => {
  const activitySubType = activity.subtype;

  if (activitySubType === COMPANY_INFO_SUBTYPE.STAGE_CHANGE) {
    return 'Stage Changed';
  }

  if (activitySubType === COMPANY_INFO_SUBTYPE.SECTOR_CHANGE) {
    return 'SASB sector changed';
  }

  if (activitySubType === COMPANY_INFO_SUBTYPE.INDUSTRY_CHANGE) {
    return 'SASB industry changed';
  }

  if (activitySubType === COMPANY_INFO_SUBTYPE.LOCATION_CHANGE) {
    return 'HQ location changed';
  }
  if (activitySubType === COMPANY_INFO_SUBTYPE.ASSIGN_ANALYST) {
    return 'Analyst assigned';
  }

  if (activitySubType === COMPANY_INFO_SUBTYPE.ASSIGN_ESG_CONTACT) {
    return 'ESG contact assigned';
  }

  if (activitySubType === ANALYST_RATING_SUBTYPE.ESG_OPPORTUNITIES_CHANGE) {
    return 'ESG opportunity changed';
  }

  if (activitySubType === ANALYST_RATING_SUBTYPE.ESG_RISK_LEVEL_CHANGE) {
    return 'ESG risk level changed';
  }

  if (activitySubType === ASSESSMENT_SUBMITTED_SUBTYPE.ASSESSMENT_SUBMITTED) {
    return 'Assessment submitted';
  }

  if (activitySubType === GOOD_GOVERNANCE_SCORE_SUBTYPE.GOOD_GOVERNANCE_SCORE) {
    return 'Governance score published';
  }

  if (activitySubType === EXCLUSION_SUBTYPE.NEW_EXCLUSION) {
    return 'Exclusion added';
  }

  if (activitySubType === EXCLUSION_SUBTYPE.SATISFIED_EXCLUSION) {
    return 'Governance score published';
  }

  if (activitySubType === CHECKLIST_SUBTYPE.CHECKLIST_ITEM_CREATED) {
    return 'Checklist item created';
  }

  if (activitySubType === SURVEY_REQUEST_SUBTYPE.REQUEST_SENT) {
    return `Survey request sent ${
      activity?.company?.externalEsgContact?.email
        ? `to ${activity?.company?.externalEsgContact?.email}`
        : ''
    }`;
  }

  if (activitySubType === SURVEY_REQUEST_SUBTYPE.RESPONSE_SUBMITTED) {
    return 'Response Submitted';
  }

  if (activitySubType === KPI_RESPONSE_SUBMITTED_SUBTYPE.KPI_RESPONSE_SUBMITTED) {
    return 'KPI collection response submitted';
  }

  if (activitySubType === NOTE_SUBTYPE.NOTE_CREATED) {
    return 'Note added';
  }

  if (activitySubType === COMPANY_CREATED_SUBTYPE.COMPANY_CREATED) {
    return 'Company created';
  }
};
