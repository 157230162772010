import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { AssessmentsSelect } from './AssessmentsSelect';
import { CompanyDetailsSelect } from './CompanyDetailsSelect';
import { useSurveys } from '../../../../queries/useSurveys';
import { AggregatedReportsAssessmentStepSkeletonLoader } from '../../../../components/SkeletonLoader/AggregatedReportAssessmentStep.SkeletonLoader';

const Wrapper = styled('div')`
  height: calc(100vh - 180px);
  overflow: hidden;
`;

const InnerWrapper = styled('div')`
  overflow: auto;
  height: 100%;
`;

export const ReportDataStep = () => {
  const { colors } = useTheme();

  const { isLoading } = useSurveys();

  if (isLoading) {
    return <AggregatedReportsAssessmentStepSkeletonLoader />;
  }

  return (
    <Wrapper>
      <Typography variant='h3' color={colors.primary[90]}>
        What do you want to report on?
      </Typography>
      <InnerWrapper>
        <CompanyDetailsSelect />
        <AssessmentsSelect />
      </InnerWrapper>
    </Wrapper>
  );
};
