import { useNavigate } from 'react-router';
import { SideSection } from './SideSection/SideSection';
import { MainContent } from './MainContent/MainContent';
import { LayoutWithActionsFooter } from '../../components/LayoutWithActionsFooter/LayoutWithActionsFooter';
import { ControlButtons } from './ControlButton';
import { useAtom } from 'jotai';
import {
  selectedCompanyExclusionsState,
  expandedCategoryState,
  selectedConditionState,
} from '../../state/UIState';

export const AddCompanyExclusions = () => {
  const navigate = useNavigate();
  const [, setSelectedCompanyExclusions] = useAtom(selectedCompanyExclusionsState);
  const [, setExpandedCategory] = useAtom(expandedCategoryState);
  const [, setSelectedConditionState] = useAtom(selectedConditionState);

  const onBack = () => {
    setSelectedCompanyExclusions([]);
    setExpandedCategory(null);
    setSelectedConditionState(null);
    navigate(-1);
  };

  return (
    <LayoutWithActionsFooter
      sideBar={<SideSection />}
      controlButtons={<ControlButtons />}
      headerTitle={'Add Exclusion'}
      onBackClick={onBack}
    >
      <MainContent />
    </LayoutWithActionsFooter>
  );
};
