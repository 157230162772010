import { useTheme } from '@mui/material';
import { Typography } from '../../../../../../../../../../components/Typography/Typography';
import {
  CHECKLIST_SUBTYPE,
  CompanyActivity,
  EXCLUSION_SUBTYPE,
} from '../../../../../../../../../../types';
import { getActivityTitle } from './getActivityTitle';
import { ComposedTitle } from './ComposedTitle';
import { ReactComponent as YesAnswer } from '../../../../../../../../../../assets/icons/checkmark.svg';
import { ReactComponent as SuccessIcon } from '../../../../../../../../../../assets/icons/success-green-round-small.svg';
import { GroupedActivity } from '../../../../../../../../../../types';
import { getActivityGroupTitle } from './getActivityGroupTitle';

interface Props {
  activity: CompanyActivity | GroupedActivity;
}

export const ActivityTitle = ({ activity }: Props) => {
  const { colors } = useTheme();
  const isGroup = Boolean((activity as GroupedActivity)?.items);

  const activityTitle = getActivityTitle(activity as CompanyActivity);
  const groupActivityTitle = `${getActivityGroupTitle(activity.type)} (${
    (activity as GroupedActivity)?.items?.length
  })`;

  const title = isGroup ? groupActivityTitle : activityTitle;

  if ((activity as CompanyActivity)?.subtype === EXCLUSION_SUBTYPE.SATISFIED_EXCLUSION) {
    return <ComposedTitle title='Exclusion' icon={<SuccessIcon />} subtext={'Satisfied'} />;
  }

  if ((activity as CompanyActivity)?.subtype === CHECKLIST_SUBTYPE.CHECKLIST_ITEM_COMPLETED) {
    return <ComposedTitle title='checklist item marked as' icon={<YesAnswer />} subtext={'Yes'} />;
  }

  return (
    <Typography
      variant='overline'
      color={colors.primary[70]}
      style={{ textTransform: 'uppercase' }}
    >
      {title}
    </Typography>
  );
};
