import {
  GOVERNANCE_SCORE_TYPE,
  REPORT_COMPANY_DETAILS,
  RISK_LEVEL,
  TWidgetDataType,
} from '../../../../../../types';

import highGreen from '../../../../../../assets/icons/high-green.svg';
import medium from '../../../../../../assets/icons/medium.svg';
import lowRed from '../../../../../../assets/icons/low-red.svg';
import highRed from '../../../../../../assets/icons/high-red.svg';
import lowGreen from '../../../../../../assets/icons/low-green.svg';

const OPPORTUNITIES_ICONS: Record<RISK_LEVEL, string> = {
  [RISK_LEVEL.HIGH]: highGreen,
  [RISK_LEVEL.MEDIUM]: medium,
  [RISK_LEVEL.LOW]: lowRed,
};

const RISK_ICONS: Record<RISK_LEVEL, string> = {
  [RISK_LEVEL.HIGH]: highRed,
  [RISK_LEVEL.MEDIUM]: medium,
  [RISK_LEVEL.LOW]: lowGreen,
};

const GOVERNANCE_SCORE_ICONS: Record<GOVERNANCE_SCORE_TYPE, string> = {
  [GOVERNANCE_SCORE_TYPE.GOOD]: highGreen,
  [GOVERNANCE_SCORE_TYPE.BAD]: lowRed,
};

export const getCompanyDetailsIconUrl = ({
  widgetDataType,
  answerId,
}: {
  widgetDataType: TWidgetDataType;
  answerId: RISK_LEVEL | GOVERNANCE_SCORE_TYPE;
}): string => {
  if (widgetDataType === REPORT_COMPANY_DETAILS.ESG_OPPORTUNITIES) {
    return OPPORTUNITIES_ICONS[answerId as RISK_LEVEL];
  }

  if (widgetDataType === REPORT_COMPANY_DETAILS.ESG_RISK_LEVEL) {
    return RISK_ICONS[answerId as RISK_LEVEL];
  }

  if (widgetDataType === REPORT_COMPANY_DETAILS.GOVERNANCE_SCORE) {
    return GOVERNANCE_SCORE_ICONS[answerId as GOVERNANCE_SCORE_TYPE];
  }

  return '';
};
