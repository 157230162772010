import { styled } from '@mui/material';
import { ActivityResult } from '../../../../../../types';
import { ActivitiesList } from './ActivitiesList/ActivitiesList';
import { useEffect, useRef } from 'react';

interface Props {
  activities?: ActivityResult[];
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  isLoading: boolean;
}

const ActivitiesWrapper = styled('div')`
  height: calc(100vh - 165px);
  overflow-y: auto;
`;

const ActivitiesContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
`;

export const Activities = ({
  activities,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  isLoading,
}: Props) => {
  const activitiesWrapperRef = useRef<HTMLDivElement>(null);
  const activitiesContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!activitiesContentRef?.current || !activitiesWrapperRef?.current) return;
    if (
      activitiesContentRef?.current?.clientHeight < activitiesWrapperRef?.current?.clientHeight &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
    const handleScroll = () => {
      if (
        activitiesWrapperRef?.current &&
        activitiesWrapperRef?.current.scrollTop + activitiesWrapperRef?.current.clientHeight >=
          activitiesWrapperRef?.current.scrollHeight
      ) {
        if (hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      }
    };

    const container = activitiesWrapperRef?.current;
    container?.addEventListener('scroll', handleScroll);
    return () => container?.removeEventListener('scroll', handleScroll);
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, activitiesWrapperRef]);

  return (
    <ActivitiesWrapper ref={activitiesWrapperRef}>
      <ActivitiesContent ref={activitiesContentRef}>
        <ActivitiesList
          activities={activities}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
        />
      </ActivitiesContent>
    </ActivitiesWrapper>
  );
};
