import { CHECKLIST_OPTION, EXCLUSION_STATUS } from '../../../../../types';

export const NO_ANSWER_OPTION = { name: 'No answer', id: 'noAnswer' };
export const OTHER_OPTION = { name: 'Other', id: 'other' };

export const EXCLUSION_ANSWER_OPTIONS = [
  {
    id: EXCLUSION_STATUS.SATISFIED,
    value: EXCLUSION_STATUS.SATISFIED,
    name: EXCLUSION_STATUS.SATISFIED,
  },
  {
    id: EXCLUSION_STATUS.VIOLATED,
    value: EXCLUSION_STATUS.VIOLATED,
    name: EXCLUSION_STATUS.VIOLATED,
  },
];

export const CHECKLIST_ANSWER_OPTIONS = [
  {
    id: CHECKLIST_OPTION.YES,
    name: CHECKLIST_OPTION.YES,
    value: CHECKLIST_OPTION.YES,
  },
  {
    id: CHECKLIST_OPTION.NO,
    name: CHECKLIST_OPTION.NO,
    value: CHECKLIST_OPTION.NO,
  },
  {
    id: CHECKLIST_OPTION.NA,
    name: CHECKLIST_OPTION.NA,
    value: CHECKLIST_OPTION.NA,
  },
];
