import { useMemo } from 'react';
import {
  AggregatedReportWidget,
  ChartDataItem,
  REPORT_DATA_SOURCE,
} from '../../../../../../../types';
import { useWidgetChartColor } from '../../hooks/useWidgetChartColor';
import { OTHER_OPTION } from '../../constants';
import { useChartsData } from '../../hooks/useChartsData';
import { isCompanyDetailsWidget } from '../../utils/isCompanyDetailsWidget';

export const usePieChartWidgetData = (widget: AggregatedReportWidget): ChartDataItem[] => {
  const getWidgetChartColor = useWidgetChartColor();
  const data = useChartsData(widget);

  const widgetData = useMemo(() => {
    const sortedData = data?.sort((a, b) => b.value - a.value);

    if (
      isCompanyDetailsWidget(widget) ||
      widget?.widgetDataType === REPORT_DATA_SOURCE.EXCLUSIONS ||
      widget?.widgetDataType === REPORT_DATA_SOURCE.CHECKLIST
    ) {
      return sortedData?.map((data, index) => {
        return {
          ...data,
          itemStyle: {
            color: getWidgetChartColor(sortedData, index),
          },
        };
      });
    }

    const mostFrequentDataCap = sortedData?.slice(0, 5);

    const otherData = sortedData?.slice(5);

    const combinedData =
      otherData?.length > 0
        ? [
            ...mostFrequentDataCap,
            {
              name: OTHER_OPTION.name,
              value: otherData?.reduce((acc, curr) => acc + curr.value, 0),
              id: OTHER_OPTION.id,
              groupedData: otherData,
            },
          ]
        : mostFrequentDataCap;

    return combinedData?.map((data, index) => {
      return {
        ...data,
        itemStyle: {
          color: getWidgetChartColor(combinedData, index),
        },
      };
    });
  }, [data, getWidgetChartColor, widget]);

  return widgetData;
};
