import { FC, useCallback, useMemo, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { Table } from '../../../components/Table/Table';
import { CompanyExclusion, EXCLUSION_STATUS, SelectItem } from '../../../types';

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  getFacetedUniqueValues,
} from '@tanstack/react-table';
import { styled, useTheme } from '@mui/material';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { formatDate } from '../../../utils/formatters';
import { ExclusionStatusCell } from '../../../components/Table/CellRenderers/ExclusionStatusCell';
import { Multiselect } from '../../../components/Multiselect/Multiselect';
import { ChipsSelectorMultiselect } from '../../../components/ButtonSelector/ChipsSelectorMultiselect';
import { ChipsSelectorV2 } from '../../../components/ButtonSelector/ChipsSelectorV2';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 40px;
`;
const TableHeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const FiltersWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
`;
const ViewSelectorWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const CellWrapper = styled('div')`
  &&& p {
    white-space: normal;
    text-overflow: clip;
  }
`;

interface Props {
  exclusions: CompanyExclusion[];
}

interface InvestorExclusionRow {
  id: number;
  category: string;
  status: EXCLUSION_STATUS;
  condition: string;
  investorId: string;
  text: string;
  companyId: number;
  updated_at: string;
  funds: string[];
}

enum INVESTOR_EXCLUSIONS_TABLE_VIEWS {
  EXCLUSIONS = 'Exclusions',
  INVESTORS = 'Investors',
}

const columnHelper = createColumnHelper<InvestorExclusionRow>();

export const InvestorExclusionsTable: FC<Props> = ({ exclusions }) => {
  const { colors } = useTheme();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedInvestors, setSelectedInvestors] = useState<SelectItem[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<SelectItem[]>([]);
  const statusOptions = useMemo<SelectItem[]>(
    () => [
      { id: EXCLUSION_STATUS.SATISFIED, value: EXCLUSION_STATUS.SATISFIED },
      { id: EXCLUSION_STATUS.VIOLATED, value: EXCLUSION_STATUS.VIOLATED },
    ],
    []
  );
  const [selectedStatuses, setSelectedStatuses] = useState<SelectItem[]>(statusOptions);
  const viewOptions = useMemo<SelectItem[]>(
    () => [
      {
        id: INVESTOR_EXCLUSIONS_TABLE_VIEWS.EXCLUSIONS,
        value: INVESTOR_EXCLUSIONS_TABLE_VIEWS.EXCLUSIONS,
      },
      {
        id: INVESTOR_EXCLUSIONS_TABLE_VIEWS.INVESTORS,
        value: INVESTOR_EXCLUSIONS_TABLE_VIEWS.INVESTORS,
      },
    ],
    []
  );
  const [selectedView, setSelectedView] = useState<SelectItem>(viewOptions[0]);

  const data = useMemo<InvestorExclusionRow[]>(() => {
    return (
      exclusions?.map((exclusion) => ({
        id: exclusion.id,
        category: exclusion.category,
        status: exclusion.status,
        condition: exclusion.condition,
        text: exclusion.text,
        investorId: exclusion.investor,
        companyId: exclusion.company.id,
        updated_at: exclusion.updated_at,
        funds: exclusion?.funds?.map((f) => f.name),
      })) ?? []
    );
  }, [exclusions]);

  const columns = useMemo(() => {
    const categoryColumn = columnHelper.accessor('category', {
      cell: (info) => (
        <Typography variant='body' color={colors.primary[90]}>
          {info.getValue()}
        </Typography>
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Exclusion Category
        </Typography>
      ),
      meta: {
        width: '200px',
        minWidth: '200px',
        maxWidth: '200px',
      },
      filterFn: (row: any, columnId, selectedFilters) => {
        const value: string = row.getValue(columnId) || '';
        return selectedFilters.includes(value);
      },
      enableColumnFilter: true,
    });
    const statusColumn = columnHelper.accessor('status', {
      cell: (info) => (
        <ExclusionStatusCell
          status={info.getValue()}
          exclusionId={Number(info.row.id)}
          companyId={info.row.original.companyId}
          exclusionText={info.row.original.text}
        />
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Checkup Conclusion
        </Typography>
      ),
      meta: {
        width: '168px',
        minWidth: '168px',
        maxWidth: '168px',
      },
      filterFn: (row: any, columnId, selectedFilters) => {
        const value: string = row.getValue(columnId) || '';
        return selectedFilters.includes(value);
      },
      enableColumnFilter: true,
    });
    const conditionColumn = columnHelper.accessor('condition', {
      cell: (info) => (
        <CellWrapper>
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        </CellWrapper>
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Condition
        </Typography>
      ),
      meta: {
        width: '300px',
        minWidth: '300px',
        maxWidth: '300px',
      },
    });
    const exclusionColumn = columnHelper.accessor('text', {
      cell: (info) => (
        <CellWrapper>
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        </CellWrapper>
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Investor Exclusion
        </Typography>
      ),
      meta: {
        width: '455px',
        minWidth: '455px',
        maxWidth: '455px',
      },
    });
    const investorColumn = columnHelper.accessor('investorId', {
      cell: (info) => (
        <Typography variant='body' color={colors.primary[90]}>
          {info.getValue()}
        </Typography>
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Investor Id
        </Typography>
      ),
      meta: {
        width: '160px',
        minWidth: '160px',
        maxWidth: '160px',
      },
      filterFn: (row: any, columnId, selectedFilters) => {
        const value: string = row.getValue(columnId) || '';
        return selectedFilters.includes(value);
      },
      enableColumnFilter: true,
    });
    const fundsColumn = columnHelper.accessor('funds', {
      cell: (info) => (
        <CellWrapper>
          {info.getValue().map((fund) => {
            return (
              <Typography key={fund} variant='body' color={colors.primary[90]}>
                {fund}
              </Typography>
            );
          })}
        </CellWrapper>
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Fund
        </Typography>
      ),
      meta: {
        width: '110px',
        minWidth: '110px',
        maxWidth: '110px',
      },
    });
    const lastUpdateColumn = columnHelper.accessor('updated_at', {
      cell: (info) => (
        <Typography variant='body' color={colors.primary[90]}>
          {info.getValue() ? formatDate(info.getValue() as string) : null}
        </Typography>
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Last Updated
        </Typography>
      ),
      meta: {
        width: '139px',
        minWidth: '139px',
        maxWidth: '139px',
      },
    });
    return selectedView.id === INVESTOR_EXCLUSIONS_TABLE_VIEWS.EXCLUSIONS
      ? [
          categoryColumn,
          statusColumn,
          conditionColumn,
          exclusionColumn,
          investorColumn,
          fundsColumn,
          lastUpdateColumn,
        ]
      : [
          investorColumn,
          fundsColumn,
          statusColumn,
          categoryColumn,
          conditionColumn,
          exclusionColumn,
          lastUpdateColumn,
        ];
  }, [colors.primary, selectedView]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (exclusion) => String(exclusion.id),
  });

  const sortedUniqueInvestors = useMemo(() => {
    const allValues = Array.from(
      table.getColumn('investorId')?.getFacetedUniqueValues().keys() ?? []
    );
    const unique = allValues.sort().map((value, id) => ({ id, value })) ?? [];
    setSelectedInvestors(unique);
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, exclusions]);

  const onChangeInvestorsFilter = useCallback(
    (investors: SelectItem[]) => {
      setSelectedInvestors(investors);
      table.getColumn('investorId')?.setFilterValue(investors.map((i) => i.value));
    },
    [table]
  );

  const sortedUniqueCategories = useMemo(() => {
    const allValues = Array.from(
      table.getColumn('category')?.getFacetedUniqueValues().keys() ?? []
    );
    const unique = allValues.sort().map((value, id) => ({ id, value })) ?? [];
    setSelectedCategories(unique);
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, exclusions]);

  const onChangeCategoriesFilter = useCallback(
    (categories: SelectItem[]) => {
      setSelectedCategories(categories);
      table.getColumn('category')?.setFilterValue(categories.map((i) => i.value));
    },
    [table]
  );

  const onChangeStatusesFilter = useCallback(
    (statuses: SelectItem[]) => {
      setSelectedStatuses(statuses);
      table.getColumn('status')?.setFilterValue(statuses.map((i) => i.value));
    },
    [table]
  );

  const onChangeView = useCallback((view: SelectItem) => {
    setSelectedView(view);
  }, []);

  return (
    <Wrapper>
      <TableHeaderWrapper>
        <FiltersWrapper>
          <SearchInput
            placeholder='Search for an exclusion'
            style={{ width: '345px', marginRight: '12px' }}
            onClear={() => table.getColumn('text')?.setFilterValue('')}
            onChange={(e) => table.getColumn('text')?.setFilterValue(e.target.value)}
            value={table.getColumn('text')?.getFilterValue() ?? ''}
          />
          <ChipsSelectorMultiselect
            options={statusOptions}
            selectedItems={selectedStatuses}
            onSelect={onChangeStatusesFilter}
          />
          <Multiselect
            style={{ width: '240px' }}
            options={sortedUniqueInvestors}
            value={selectedInvestors}
            onChange={(_, val) => {
              onChangeInvestorsFilter(val as SelectItem[]);
            }}
            disablePortal
            optionName='Investor'
            fieldPlaceholder='Filter by Investors'
          />
          <Multiselect
            style={{ width: '240px' }}
            options={sortedUniqueCategories}
            value={selectedCategories}
            onChange={(_, val) => {
              onChangeCategoriesFilter(val as SelectItem[]);
            }}
            disablePortal
            optionName='Category'
            fieldPlaceholder='Filter by Category'
          />
        </FiltersWrapper>
        <ViewSelectorWrapper>
          <Typography variant='body' color={colors.primary[90]}>
            Display by:
          </Typography>
          <ChipsSelectorV2
            options={viewOptions}
            selectedItem={selectedView}
            onSelect={onChangeView}
          />
        </ViewSelectorWrapper>
      </TableHeaderWrapper>
      <Table
        table={table}
        height='calc(100vh - 244px)'
        trStyle={{ height: 'auto' }}
        tdStyle={{
          paddingTop: '10px',
          paddingBottom: '10px',
          verticalAlign: 'baseline',
        }}
        disableAnimation={true}
      />
    </Wrapper>
  );
};
