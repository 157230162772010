import { Chip, styled, useTheme } from '@mui/material';
import { Typography } from '../../Typography/Typography';
import { EditableTextareaField } from '../../EditableTextField/EditableTextareaField';
import { FC, useCallback, useState } from 'react';
import { EvaluationRule } from '../../../types';

interface Props {
  rule: EvaluationRule;
  rulesLength: number;
  onNameChange: (weight: number, newName: string) => void;
  onDeleteRule: (weight: number) => void;
}

const RuleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RuleItem: FC<Props> = ({ rule, onNameChange }) => {
  const { colors } = useTheme();
  const [inputValue, setInputValue] = useState<string>(rule.name);

  const onNameChangeHandler = useCallback(
    (newName: string) => {
      if (!newName && rule.name) {
        setInputValue(rule.name);
        return;
      }
      onNameChange(rule.weight, newName);
    },
    [onNameChange, rule]
  );

  return (
    <Wrapper>
      <RuleWrapper key={rule.weight}>
        <Chip
          style={{
            backgroundColor: colors[rule.color][10],
            width: '70px',
            height: '17px',
          }}
          sx={{ '.MuiChip-label': { width: '100%', textAlign: 'center' } }}
          label={
            <Typography variant='subtitle2' color={colors.primary[90]}>
              {rule.weight}
            </Typography>
          }
        />
        <EditableTextareaField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onValueChanged={onNameChangeHandler}
          autoFocus={rule.name === ''}
        />
      </RuleWrapper>
    </Wrapper>
  );
};
