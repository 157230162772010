import { getGovernanceScoreIcon } from '../../../../../../../../../utils/governanceScoreUtils';
import { ComparedValues } from './components/ComparedValues';

interface Props {
  governanceScore?: number;
}
export const GovernanceScoreContent = ({ governanceScore }: Props) => {
  const Icon = getGovernanceScoreIcon(governanceScore);
  return (
    <ComparedValues
      comparedValuesConfig={[
        {
          value: String(governanceScore) || '',
          icon: <Icon />,
        },
      ]}
    />
  );
};
