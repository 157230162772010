import { styled, useTheme } from '@mui/material';
import { useCompanies } from '../../../../queries/useCompanies';
import { SelectableCompaniesTable } from '../../../../components/SelectableCompaniesTable/SelectableCompaniesTable';
import { useEffect, useMemo, useState } from 'react';
import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { AGGREGATED_REPORT_PARAMS, Company, COMPANY_STAGE } from '../../../../types';
import { Typography } from '../../../../components/Typography/Typography';
import { useAtom } from 'jotai';
import { createReportCompletedProgressState } from '../../../../state/UIState';

const Wrapper = styled('div')``;
export const CompaniesStep = () => {
  const { colors } = useTheme();
  const { isLoading, data } = useCompanies();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const { params, setParam } = useQueryParams();
  const companyIdsFromParams = params[AGGREGATED_REPORT_PARAMS.COMPANY_IDS] as string[];
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);
  const [, setProgress] = useAtom(createReportCompletedProgressState);

  const [rowSelection, setRowSelection] = useState(() =>
    companyIdsFromParams?.length
      ? companyIdsFromParams.reduce((acc: Record<number, boolean>, companyId) => {
          acc[Number(companyId)] = true;
          return acc;
        }, {})
      : {}
  );

  const esgCompleteCompanies = useMemo(() => {
    return data?.filter((company) => company?.stage === COMPANY_STAGE.ESG_COMPLETE);
  }, [data]);

  useEffect(() => {
    if (!esgCompleteCompanies) return;
    const newCompanies = Object.keys(rowSelection)
      .map((id) => esgCompleteCompanies.find((company) => company.id === Number(id)))
      .filter(Boolean) as Company[];

    setSelectedCompanies(newCompanies);
    const companyIds = newCompanies.map((c) => c.id);
    setParam({ param: AGGREGATED_REPORT_PARAMS.COMPANY_IDS, value: companyIds });
    setProgress(() => {
      if (companyIds?.length) {
        return 100;
      }
      return 50;
    });
  }, [esgCompleteCompanies, rowSelection, setParam, setProgress, setSelectedCompanies]);

  if (!esgCompleteCompanies) return null;

  return (
    <Wrapper>
      <Typography variant='h3' color={colors.primary[90]}>
        What companies should be part of this report?
      </Typography>
      <SelectableCompaniesTable
        companiesTableData={esgCompleteCompanies}
        disabledText='Assigned'
        sorting={sorting}
        setSorting={setSorting}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        selectedCompanies={selectedCompanies}
        isLoading={isLoading || !data}
        tableHeight='calc(100vh - 468px)'
      />
    </Wrapper>
  );
};
