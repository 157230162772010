import { ReactComponent as CompanyInfoIcon } from '../../../../../../../../../assets/icons/companyActivities/company-info.svg';
import { ReactComponent as AnalystRatingActivityIcon } from '../../../../../../../../../assets/icons/companyActivities/analyst-rating-activities.svg';
import { ReactComponent as ExclusionsIcon } from '../../../../../../../../../assets/icons/companyActivities/exclusion-activity.svg';
import { ReactComponent as AssessmentIcon } from '../../../../../../../../../assets/icons/companyActivities/assessment-activities.svg';
import { ReactComponent as ChecklistIcon } from '../../../../../../../../../assets/icons/companyActivities/checklist-activities.svg';
import { ReactComponent as SurveyRequestIcon } from '../../../../../../../../../assets/icons/companyActivities/survey-request-activities.svg';
import { ReactComponent as KPIResponseIcon } from '../../../../../../../../../assets/icons/companyActivities/kpi-response-submitted.svg';
import { ReactComponent as NoteIcon } from '../../../../../../../../../assets/icons/companyActivities/note-activity.svg';
import { ReactComponent as GovernanceScoreIcon } from '../../../../../../../../../assets/icons/companyActivities/governance-score-activity.svg';
import { ACTIVITY_TYPE } from '../../../../../../../../../types';

interface Props {
  activityType: ACTIVITY_TYPE;
}
export const ActivityIcon = ({ activityType }: Props) => {
  if (
    ACTIVITY_TYPE.COMPANY_INFO_CHANGE === activityType ||
    ACTIVITY_TYPE.COMPANY_CREATED === activityType
  ) {
    return <CompanyInfoIcon />;
  }

  if (ACTIVITY_TYPE.ANALYST_RATING_CHANGE === activityType) {
    return <AnalystRatingActivityIcon />;
  }

  if (ACTIVITY_TYPE.GOOD_GOVERNANCE_SCORE === activityType) {
    return <GovernanceScoreIcon />;
  }

  if (ACTIVITY_TYPE.EXCLUSIONS === activityType) {
    return <ExclusionsIcon />;
  }

  if (ACTIVITY_TYPE.ASSESSMENT_SUBMITTED === activityType) {
    return <AssessmentIcon />;
  }

  if (ACTIVITY_TYPE.CHECKLIST === activityType) {
    return <ChecklistIcon />;
  }

  if (ACTIVITY_TYPE.SURVEY_REQUEST === activityType) {
    return <SurveyRequestIcon />;
  }

  if (ACTIVITY_TYPE.KPI_RESPONSE_SUBMITTED === activityType) {
    return <KPIResponseIcon />;
  }

  if (ACTIVITY_TYPE.NOTE === activityType) {
    return <NoteIcon />;
  }

  return null;
};
