import { styled, Switch as MuiSwitch, useTheme } from '@mui/material';
import { Typography } from './Typography/Typography';

const SwitchWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: -9px;
`;

interface Props {
  checked?: boolean;
  onChange?: () => void;
  label: string;
}

export const Switch = ({ checked, onChange, label }: Props) => {
  const { colors } = useTheme();

  return (
    <SwitchWrapper>
      <MuiSwitch checked={checked} onChange={onChange} />
      <Typography variant='body' color={colors.primary[80]}>
        {label}
      </Typography>
    </SwitchWrapper>
  );
};
