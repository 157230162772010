import { Menu, MenuItem, styled, useTheme } from '@mui/material';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-down-dropdown.svg';

import { ACTIVITY_TYPE, EXCLUSION_STATUS, EXCLUSION_SUBTYPE, User } from '../../../types';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { COMPANIES, useCompanyById, useUpdateCompany } from '../../../queries/useCompanies';
import { useParams } from 'react-router';
import { useAtomValue } from 'jotai';
import { userState } from '../../../state/UIState';
import { useExclusionsByCompany } from '../../../queries/useExclusionsByCompany';
import { useCreateCompanyActivity } from '../../../queries/useCompanyActivity';
import { ExclusionStatus } from '../../ExclusionStatus';

interface Props {
  status: EXCLUSION_STATUS;
  exclusionId: number;
  companyId: number;
  exclusionText: string;
}

const StatusSelect = styled('div')<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 128px;
  height: 24px;
  border-radius: 2px;
  padding: 0 4px;
  ${({ isActive, theme }) => isActive && `background-color: ${theme.colors.gray[20]};`}
  cursor: pointer;
`;

export const ExclusionStatusCell: FC<Props> = ({ status, exclusionId, companyId }) => {
  const { colors } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const params = useParams();

  const { data: exclusions } = useExclusionsByCompany(Number(params.id));
  const { mutate: createCompanyActivity } = useCreateCompanyActivity(Number(params.id));

  const open = Boolean(anchorEl);
  const updateCompany = useUpdateCompany();
  const queryClient = useQueryClient();

  const user = useAtomValue(userState) as User;

  const { data: company } = useCompanyById(Number(params.id));

  const options = useMemo(() => {
    return [EXCLUSION_STATUS.SATISFIED, EXCLUSION_STATUS.VIOLATED];
  }, []);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = useCallback(
    async (e: MouseEvent<HTMLLIElement>, option: EXCLUSION_STATUS) => {
      e.stopPropagation();

      if (!user) return;

      let violatedExclusionsIds = company?.violatedExclusionsIds || [];

      if (option === EXCLUSION_STATUS.VIOLATED && !violatedExclusionsIds?.includes(exclusionId)) {
        violatedExclusionsIds = [...violatedExclusionsIds, exclusionId];
      }

      if (option === EXCLUSION_STATUS.SATISFIED) {
        violatedExclusionsIds = violatedExclusionsIds?.filter((id) => id !== exclusionId);
      }

      await updateCompany.mutateAsync(
        {
          id: companyId,
          violatedExclusionsIds,
        },
        {
          onSuccess: () => {
            queryClient.setQueryData(`${COMPANIES}-${companyId}`, (old) => {
              if (!old) return;
              return {
                ...old,
                violatedExclusionsIds,
              };
            });
          },
        }
      );

      if (option === EXCLUSION_STATUS.SATISFIED) {
        const currentExclusion = exclusions?.find((exclusion) => exclusion.id === exclusionId);

        createCompanyActivity({
          company: Number(params.id),
          type: ACTIVITY_TYPE.EXCLUSIONS,
          subtype: EXCLUSION_SUBTYPE.SATISFIED_EXCLUSION,
          value: {
            exclusion: currentExclusion,
          },
          user: user.id,
        });
      }

      handleCloseMenu();
    },
    [
      company?.violatedExclusionsIds,
      companyId,
      createCompanyActivity,
      exclusionId,
      exclusions,
      params.id,
      queryClient,
      updateCompany,
      user,
    ]
  );

  return (
    <>
      <StatusSelect
        isActive={open}
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <ExclusionStatus status={status} />
        <ArrowIcon />
      </StatusSelect>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseMenu();
        }}
        sx={{
          '& .MuiList-root .MuiButtonBase-root:hover': { backgroundColor: colors.accent[5] },
          '& .MuiList-root .MuiButtonBase-root': { backgroundColor: 'transparent' },
        }}
        PaperProps={{
          style: {
            width: '128px',
            transform: 'translateY(4px)',
            boxShadow: 'none',
            border: `1px solid ${colors.primary[30]}`,
          },
        }}
      >
        {options.map((o) => (
          <MenuItem key={o} onClick={(e) => handleSelectOption(e, o)} style={{ width: '100%' }}>
            <ExclusionStatus status={o} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
