import { styled, useTheme } from '@mui/material';
import { Exclusion } from '../../../../types';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { Typography } from '../../../../components/Typography/Typography';

const Container = styled('div')<{ isDisabled?: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 10px 16px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};

  &:not(:last-of-type) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary[10]}`};
  }
`;

interface Props {
  exclusion: Exclusion;
  isChecked: boolean;
  isDisabled: boolean;
  onToggleCheck: (exclusion: Exclusion) => void;
}

export const ExclusionItem = ({ exclusion, isChecked, isDisabled, onToggleCheck }: Props) => {
  const { colors } = useTheme();

  return (
    <Container
      isDisabled={isDisabled}
      onClick={() => {
        if (isDisabled) return;
        onToggleCheck(exclusion);
      }}
    >
      <Checkbox
        disabled={isDisabled}
        checked={isChecked}
        style={{ padding: 0 }}
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 24,
          },
        }}
      />
      <Typography variant='body' color={colors.primary[90]} style={{ maxWidth: '600px' }}>
        {exclusion.exclusion}
      </Typography>
    </Container>
  );
};
