import { useCallback } from 'react';
import { getFormattedChecklistData } from '../../../utils/getFormattedChecklistData';
import {
  ACTIVITY_TYPE,
  CHECKLIST_ITEM_FREQUENCY,
  CHECKLIST_SUBTYPE,
  ChecklistData,
  User,
} from '../../../types';
import { useUpdateCompany } from '../../../queries/useCompanies';
import { useAtom, useAtomValue } from 'jotai';
import { activeReportState, userState } from '../../../state/UIState';
import { useCreateCompanyActivities } from '../../../queries/useCompanyActivity';

export const useUpdateCompanyChecklistData = () => {
  const updateCompany = useUpdateCompany();
  const [report] = useAtom(activeReportState);

  const { mutate: createCompanyActivities } = useCreateCompanyActivities(report?.company?.id);
  const user = useAtomValue(userState) as User;

  const updateCompanyChecklistData = useCallback(async () => {
    if (!report || !user) return;

    const checklistFrequency = report.request.frequency as unknown as CHECKLIST_ITEM_FREQUENCY;

    const formattedChecklistData = getFormattedChecklistData(
      report.survey,
      checklistFrequency,
      report
    );

    const newChecklistData = {
      ...(report.company.checklistData || {}),
      [checklistFrequency]: formattedChecklistData,
    };

    await updateCompany.mutateAsync({
      id: report.company.id,
      checklistData: newChecklistData as ChecklistData,
    });
    const companyActivities =
      newChecklistData?.[checklistFrequency]?.map((item) => ({
        company: report.company.id,
        type: ACTIVITY_TYPE.CHECKLIST,
        subtype: CHECKLIST_SUBTYPE.CHECKLIST_ITEM_CREATED,
        value: {
          checklistItem: item,
        },
        user: user?.id,
      })) || [];

    createCompanyActivities({ data: companyActivities });
  }, [createCompanyActivities, report, updateCompany, user]);

  return { updateCompanyChecklistData };
};
