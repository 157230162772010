import { Button, styled, useTheme } from '@mui/material';
import { GroupedActivity, User } from '../../../../../../../../types';
import { Typography } from '../../../../../../../../components/Typography/Typography';
import { useMemo, useState } from 'react';
import { Activity } from '../Activity/Activity';
import { ActivityHeader } from '../components/ActivityHeader/ActivityHeader';
import { ActivityFooter } from '../components/ActivityFooter';
import { ReactComponent as DownArrow } from '../../../../../../../../assets/icons/arrow-down-blue.svg';
import { ReactComponent as UpArrow } from '../../../../../../../../assets/icons/arrow-up-blue.svg';

interface Props {
  activityGroup: GroupedActivity;
}

const Wrapper = styled('div')`
  padding: 12px 16px;
`;

const ContentWrapper = styled('div')`
  padding-left: 32px;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonContent = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ActivityGroup = ({ activityGroup }: Props) => {
  const { colors } = useTheme();

  const [isShowAll, setShowAll] = useState(false);

  const users = useMemo(() => {
    if (!activityGroup?.users?.length) return '';

    const uniqueUsers = activityGroup?.users?.filter(
      (user, index, self) => self.findIndex((u) => u?.id === user?.id) === index
    );

    return uniqueUsers?.map((user) => (user as User)?.fullName || user?.email)?.join(', ') || '';
  }, [activityGroup?.users]);

  return (
    <Wrapper>
      <ActivityHeader activity={activityGroup} />
      <ContentWrapper>
        {isShowAll &&
          activityGroup?.items?.map((item) => (
            <Activity key={item.id} activity={item} isInGroupActivity />
          ))}
        <Button
          variant='text'
          onClick={() => setShowAll((prev) => !prev)}
          style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}
        >
          <ButtonContent>
            <Typography variant='subtitle2' color={colors.accent[50]}>
              {isShowAll ? 'Hide All' : 'Show All'}
            </Typography>
            {isShowAll ? <UpArrow /> : <DownArrow />}
          </ButtonContent>
        </Button>
        <ActivityFooter createdDate={activityGroup.created_at} users={users} />
      </ContentWrapper>
    </Wrapper>
  );
};
