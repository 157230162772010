import { useParams } from 'react-router';
import { ScreeningFlow } from '../../components/ScreeningFlow/ScreeningFlow';
import { useCompanyById } from '../../queries/useCompanies';
import { useWorkflowsByCompanyType } from '../../queries/useWorkflows';
import { COMPANY_TYPE } from '../../types';
import { SingleCompanyHeader } from '../../components/SingleCompanyHeader';
import { useCompanyReports } from './hooks/useCompanyReports';
import { ROUTES } from '../../constants/routes';
import { ResponsesTable } from './components/ResponsesTable';

export const ScreeningCompany = () => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));
  const { data: workflow } = useWorkflowsByCompanyType(company?.type);

  const { nonAssessmentReports } = useCompanyReports();

  if (!company) return null;

  return (
    <>
      <SingleCompanyHeader company={company} isInScreeningFlow />
      <ScreeningFlow
        stages={workflow?.config?.stages || []}
        companyType={company?.type || COMPANY_TYPE.CONTROLLED}
      />
      {Boolean(
        nonAssessmentReports?.length &&
          params.activeTab !== ROUTES.COMPANY_PERFORMANCE &&
          params.activeTab !== ROUTES.COMPANY_CHECKLIST
      ) && <ResponsesTable reports={nonAssessmentReports || []} />}
    </>
  );
};
