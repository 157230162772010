import { useMemo } from 'react';
import { useWidgetQuestion } from './useWidgetQuestion';
import {
  AggregatedReportWidget,
  Company,
  QUESTION_TYPE,
  QuestionOption,
  REPORT_DATA_SOURCE,
  Response,
  SURVEY_FREQUENCY,
  WidgetCompanyAnswer,
} from '../../../../../../types';
import { useBreakdownData } from '../../../../hooks/useBreakdownData';
import { NO_ANSWER_OPTION } from '../constants';
import { isCompanyDetailsWidget } from '../utils/isCompanyDetailsWidget';
import { getExclusionAnswer } from '../../../../utils/getExclusionAnswer';
import { getChecklistAnswer } from '../../../../utils/getChecklistAnswer';

export const useCompaniesAnswers = (widget: AggregatedReportWidget) => {
  const widgetQuestion = useWidgetQuestion(widget);
  const { filteredCompanies } = useBreakdownData();

  const companiesAnswers: WidgetCompanyAnswer[] = useMemo(() => {
    const isCompanyDetails = isCompanyDetailsWidget(widget);

    if (isCompanyDetails) {
      const allAnswers = filteredCompanies?.map((company) => {
        return {
          companyName: company.name,
          companyLogo: company.logoUrl,
          companyId: company.id,
          questionId: widget?.questionId as string,
          sourceType: REPORT_DATA_SOURCE.COMPANY_DETAILS,
          answer: company[widget?.widgetDataType as keyof Company]
            ? {
                name: company[widget?.widgetDataType as keyof Company],
                id: company[widget?.widgetDataType as keyof Company],
              }
            : null,
        } as WidgetCompanyAnswer;
      });
      if (!widget?.questionAnswer) return allAnswers;

      return allAnswers?.filter((companyAnswer) => {
        return (companyAnswer?.answer as QuestionOption)?.id === widget?.questionAnswer?.id;
      });
    }

    if (widget?.widgetDataType === REPORT_DATA_SOURCE.EXCLUSIONS) {
      const allAnswers = filteredCompanies?.map((company) => {
        const answerText = getExclusionAnswer({
          company,
          questionId: widget?.questionId as string,
        });
        return {
          companyName: company.name,
          companyLogo: company.logoUrl,
          companyId: company.id,
          questionId: widget?.questionId as string,
          sourceType: REPORT_DATA_SOURCE.EXCLUSIONS,
          answer: answerText
            ? {
                name: answerText,
                id: answerText,
              }
            : {
                name: NO_ANSWER_OPTION.name,
                id: NO_ANSWER_OPTION.id,
              },
        };
      });

      if (!widget?.questionAnswer) return allAnswers;

      return allAnswers?.filter((companyAnswer) => {
        return (companyAnswer?.answer as QuestionOption)?.id === widget?.questionAnswer?.id;
      });
    }

    if (widget?.widgetDataType === REPORT_DATA_SOURCE.CHECKLIST) {
      const allAnswers = filteredCompanies?.map((company) => {
        const answerText = getChecklistAnswer({
          company,
          questionId: widget?.questionId as string,
        });
        return {
          companyName: company.name,
          companyLogo: company.logoUrl,
          companyId: company.id,
          questionId: widget?.questionId as string,
          sourceType: REPORT_DATA_SOURCE.CHECKLIST,
          answer: answerText
            ? {
                name: answerText,
                id: answerText,
              }
            : {
                name: NO_ANSWER_OPTION.name,
                id: NO_ANSWER_OPTION.id,
              },
        } as WidgetCompanyAnswer;
      });

      if (!widget?.questionAnswer) return allAnswers;

      return allAnswers?.filter((companyAnswer) => {
        return (companyAnswer?.answer as QuestionOption)?.id === widget?.questionAnswer?.id;
      });
    }

    if (widget?.widgetDataType === REPORT_DATA_SOURCE.PERFORMANCE) {
      return filteredCompanies?.map((company) => {
        const companyPerfrmanceQuarterly = company?.kpisData?.[SURVEY_FREQUENCY.QUARTERLY] || [];
        const performanceAllAnswers = companyPerfrmanceQuarterly?.map((quarter) => {
          return {
            id: `${widget?.questionId}-${quarter?.period}`,
            name: `${quarter?.period} `,
            year: new Date(quarter?.timestamp)?.getFullYear(),
            answer: quarter?.[Number(widget.questionId)]?.value || 'N/A',
          };
        });

        let performanceAnswers = performanceAllAnswers;
        if (widget?.startYear || widget?.endYear) {
          performanceAnswers = performanceAllAnswers?.filter((answer) => {
            if (widget?.startYear && widget?.endYear)
              return (
                widget?.startYear &&
                answer?.year >= widget?.startYear &&
                widget?.endYear &&
                answer?.year <= widget?.endYear
              );

            if (widget?.startYear) {
              return widget?.startYear && answer?.year >= widget?.startYear;
            }
            if (widget?.endYear) {
              return widget?.endYear && answer?.year <= widget?.endYear;
            }
          });
        }

        return {
          companyName: company.name,
          companyLogo: company.logoUrl,
          companyId: company.id,
          questionId: widget?.questionId as string,
          sourceType: REPORT_DATA_SOURCE.PERFORMANCE,
          answer: NO_ANSWER_OPTION,
          performanceAnswers,
        } as WidgetCompanyAnswer;
      });
    }

    const allAnswers = filteredCompanies?.map((company) => {
      const allAnswers = company?.reports?.map((report) => report?.response);
      const allAnswersMap: Record<string, Response> = allAnswers?.reduce((acc, curr) => {
        return {
          ...acc,
          ...curr,
        };
      }, {});

      return {
        companyName: company.name,
        companyLogo: company.logoUrl,
        companyId: company.id,
        questionId: widget?.questionId as string,
        sourceType: widget?.widgetDataType,
        answer: allAnswersMap[widgetQuestion?.id as string],
      } as WidgetCompanyAnswer;
    });

    if (!widget?.questionAnswer) return allAnswers;

    if (widgetQuestion?.assessmentQuestion?.type === QUESTION_TYPE.SINGLE) {
      return allAnswers?.filter((companyAnswer) => {
        return (companyAnswer?.answer as QuestionOption)?.id === widget?.questionAnswer?.id;
      });
    }

    return allAnswers?.filter((companyAnswer) => {
      return (companyAnswer?.answer as QuestionOption[])?.some((option) => {
        return option?.id === widget?.questionAnswer?.id;
      });
    });
  }, [filteredCompanies, widget, widgetQuestion?.assessmentQuestion?.type, widgetQuestion?.id]);

  const numOfCompaniesAnswers = useMemo(() => {
    return companiesAnswers?.filter((companyAnswer) => Boolean(companyAnswer?.answer))?.length;
  }, [companiesAnswers]);

  const completeCompanyAnswers = useMemo(() => {
    return (
      companiesAnswers?.map((companyAnswer) => {
        if (!companyAnswer?.answer)
          return {
            ...companyAnswer,
            answer:
              widgetQuestion?.assessmentQuestion?.type === QUESTION_TYPE.SINGLE
                ? NO_ANSWER_OPTION
                : [NO_ANSWER_OPTION],
          };

        return companyAnswer;
      }) || []
    );
  }, [companiesAnswers, widgetQuestion?.assessmentQuestion?.type]);

  return { companiesAnswers: completeCompanyAnswers, numOfCompaniesAnswers };
};
