import { useMemo } from 'react';
import {
  AggregatedReportWidget,
  ChartDataItem,
  GOVERNANCE_SCORE_TYPE,
  QUESTION_TYPE,
  QuestionOption,
  REPORT_COMPANY_DETAILS,
  REPORT_DATA_SOURCE,
  RISK_LEVEL,
} from '../../../../../../types';
import { useCompaniesAnswers } from './useCompaniesAnswers';
import { useWidgetQuestion } from './useWidgetQuestion';
import { getCompanyDetailsAnswerOptions } from '../utils/getCompanyDetailsAnswerOptions';
import { getCompanyDetailsIconUrl } from '../utils/getCompanyDetailsIconUrl';
import { CHECKLIST_ANSWER_OPTIONS, EXCLUSION_ANSWER_OPTIONS, NO_ANSWER_OPTION } from '../constants';
import { isCompanyDetailsWidget } from '../utils/isCompanyDetailsWidget';
import { getIsGoodScore } from '../../../../../../utils/governanceScoreUtils';

export const useChartsData = (widget: AggregatedReportWidget): ChartDataItem[] => {
  const { companiesAnswers } = useCompaniesAnswers(widget);
  const widgetQuestion = useWidgetQuestion(widget);

  const companyDetailsWidgetData = useMemo(() => {
    const companyDetailsAnswers = getCompanyDetailsAnswerOptions(widget?.widgetDataType);
    const questionAnswers = [...companyDetailsAnswers];
    if (widget?.widgetDataType === REPORT_COMPANY_DETAILS.GOVERNANCE_SCORE) {
      return companiesAnswers
        ?.map((companyAnswer) => {
          const companyGovernanceScore = Number((companyAnswer?.answer as QuestionOption)?.id);
          const hasGovernanceScore = !isNaN(companyGovernanceScore);
          const isGoodScore = getIsGoodScore(companyGovernanceScore);
          const dataId = isGoodScore ? GOVERNANCE_SCORE_TYPE.GOOD : GOVERNANCE_SCORE_TYPE.BAD;
          return {
            id: hasGovernanceScore ? dataId : '',
            name: hasGovernanceScore ? String(companyGovernanceScore) : '',
            value:
              companiesAnswers?.filter((cAnswer) => {
                const CGovernanceScore = Number((cAnswer?.answer as QuestionOption)?.id);
                return CGovernanceScore === companyGovernanceScore;
              })?.length || 0,
            icon: getCompanyDetailsIconUrl({
              widgetDataType: widget?.widgetDataType,
              answerId: dataId as GOVERNANCE_SCORE_TYPE,
            }),
          };
        })
        ?.filter((data) => data?.id);
    }
    return questionAnswers?.map((answer: QuestionOption) => {
      return {
        id: answer.id,
        name: answer.name,
        value: companiesAnswers?.filter((companyAnswer) => {
          return (companyAnswer?.answer as QuestionOption)?.id === answer?.id;
        }).length,
        icon: getCompanyDetailsIconUrl({
          widgetDataType: widget?.widgetDataType,
          answerId: answer.id as RISK_LEVEL | GOVERNANCE_SCORE_TYPE,
        }),
      };
    });
  }, [companiesAnswers, widget?.widgetDataType]);

  const exclusionWidgetData = useMemo(() => {
    const questionAnswers = [...EXCLUSION_ANSWER_OPTIONS, NO_ANSWER_OPTION];
    return questionAnswers?.map((answer: QuestionOption) => {
      return {
        id: answer.id,
        name: answer.name,
        value: companiesAnswers?.filter((companyAnswer) => {
          return (companyAnswer?.answer as QuestionOption)?.id === answer?.id;
        }).length,
      };
    });
  }, [companiesAnswers]);

  const checklistWidgetData = useMemo(() => {
    const questionAnswers = [...CHECKLIST_ANSWER_OPTIONS, NO_ANSWER_OPTION];
    return questionAnswers?.map((answer: QuestionOption) => {
      return {
        id: answer.id,
        name: answer.name,
        value: companiesAnswers?.filter((companyAnswer) => {
          return (companyAnswer?.answer as QuestionOption)?.id === answer?.id;
        }).length,
      };
    });
  }, [companiesAnswers]);

  const assessmentWidgetData = useMemo(() => {
    const questionAnswers = [
      ...(widgetQuestion?.assessmentQuestion?.options || []),
      NO_ANSWER_OPTION,
    ];

    return (
      (questionAnswers?.map((answer: QuestionOption) => {
        if (widgetQuestion?.assessmentQuestion?.type === QUESTION_TYPE.SINGLE) {
          const numOfCompanies = companiesAnswers?.filter((companyAnswer) => {
            return (companyAnswer?.answer as QuestionOption)?.id === answer?.id;
          }).length;

          return {
            id: answer.id,
            name: answer.name,
            value: numOfCompanies,
          };
        }

        if (widgetQuestion?.assessmentQuestion?.type === QUESTION_TYPE.MULTI) {
          const numOfCompanies = companiesAnswers?.filter((companyAnswer) => {
            return (companyAnswer?.answer as QuestionOption[])?.find((a) => a?.id === answer?.id);
          }).length;
          return {
            id: answer.id,
            name: answer.name,
            value: numOfCompanies,
          };
        }
      }) as ChartDataItem[]) || []
    );
  }, [
    companiesAnswers,
    widgetQuestion?.assessmentQuestion?.options,
    widgetQuestion?.assessmentQuestion?.type,
  ]);

  const performanceWidgetData = useMemo(() => {
    const cols = companiesAnswers?.map((companyAnswer) => {
      return {
        id: String(companyAnswer?.companyId),
        name: companyAnswer?.companyName,
        value: 0,
        seriesValue:
          companyAnswer?.performanceAnswers?.map((answer) => {
            const value = isNaN(Number(answer.answer)) ? 0 : Number(answer.answer);
            return [answer.name, value];
          }) || [],
      };
    });

    return cols;
  }, [companiesAnswers]);

  if (isCompanyDetailsWidget(widget)) {
    return companyDetailsWidgetData;
  }

  if (widget?.widgetDataType === REPORT_DATA_SOURCE.EXCLUSIONS) {
    return exclusionWidgetData;
  }

  if (widget?.widgetDataType === REPORT_DATA_SOURCE.CHECKLIST) {
    return checklistWidgetData;
  }

  if (widget?.widgetDataType === REPORT_DATA_SOURCE.ASSESSMENT) {
    return assessmentWidgetData;
  }

  if (widget?.widgetDataType === REPORT_DATA_SOURCE.PERFORMANCE) {
    return performanceWidgetData;
  }

  return [];
};
