import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  AggregatedReportWidget,
  REPORT_DATA_SOURCE,
  Response,
  WidgetCompanyAnswer,
} from '../../../../../../types';
import { useCompaniesAnswers } from '../hooks/useCompaniesAnswers';
import { useWidgetQuestion } from '../hooks/useWidgetQuestion';
import { WidgetCard } from './WidgetCard';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, styled, useTheme } from '@mui/material';
import { CompanyCell } from '../../../../../../components/Table/CellRenderers/CompanyCell';
import { Typography } from '../../../../../../components/Typography/Typography';
import { Table } from '../../../../../../components/Table/Table';
import { AnswerCell } from '../../../AnswerCell/AnswerCell';
import { getAnswer } from '../../../../utils/getAnswer';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../../state/UIState';
import { isCompanyDetailsWidget } from '../utils/isCompanyDetailsWidget';
import { formatNumber } from '../../../../../../utils/formatters';

const TableWrapper = styled('div')`
  padding-top: 10px;
`;

interface Props {
  widget: AggregatedReportWidget;
}

const columnHelper = createColumnHelper<WidgetCompanyAnswer>();

export const CompanyBreakdownWidget = ({ widget }: Props) => {
  const widgetQuestion = useWidgetQuestion(widget);
  const { colors } = useTheme();
  const [showAll, setShowAll] = useState(false);
  const tableInnerRef = useRef<HTMLTableElement | null>(null);
  const [showWidgetButton, setShowWidgetButton] = useState(false);
  const [aggregatedReport] = useAtom(aggregatedReportState);

  const { companiesAnswers } = useCompaniesAnswers(widget);

  useEffect(() => {
    if (!tableInnerRef.current) return;

    const tableHeight = tableInnerRef.current?.clientHeight;
    setShowWidgetButton(tableHeight > 300);
  }, [tableInnerRef, companiesAnswers]);

  const columns = useMemo(() => {
    const companyNameColumn = columnHelper.accessor('companyName', {
      cell: (info) => <CompanyCell name={info.getValue()} logo={info.row.original.companyLogo} />,
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Company
        </Typography>
      ),
      meta: {
        width: '30%',
      },
    });
    const answerColumn = columnHelper.accessor('answer', {
      cell: (info) => {
        return (
          <AnswerCell
            questionId={info.row.original.questionId}
            sourceType={info.row.original.sourceType}
            answer={info.getValue() as string}
          />
        );
      },
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Answer
        </Typography>
      ),
      meta: {
        width: '70%',
      },
      enableSorting: false,
    });

    const performanceAnswersColumns =
      companiesAnswers?.[0]?.performanceAnswers?.map((answer) => {
        return columnHelper.display({
          id: answer.id,
          cell: (info) => {
            const companyAnswer = companiesAnswers
              ?.find((company) => {
                return company.companyId === info.row.original.companyId;
              })
              ?.performanceAnswers?.find((answer) => answer.id === info.column.id);

            const isNumber = !isNaN(Number(companyAnswer?.answer));
            const answerText = isNumber
              ? formatNumber(companyAnswer?.answer || 0)
              : companyAnswer?.answer;
            return (
              <Typography
                variant='body'
                color={colors.primary[90]}
                style={{ textAlign: isNumber ? 'right' : 'left' }}
              >
                {answerText}
              </Typography>
            );
          },
          header: () => (
            <Typography variant='overline' color={colors.primary[70]}>
              {answer.name}
            </Typography>
          ),
        });
      }) || [];

    if (widget?.widgetDataType === REPORT_DATA_SOURCE.PERFORMANCE) {
      return [companyNameColumn, ...performanceAnswersColumns];
    }

    return [companyNameColumn, answerColumn];
  }, [colors.primary, companiesAnswers, widget?.widgetDataType]);

  const companyBreakDownWidgetData = useMemo(() => {
    if (!companiesAnswers?.length) return [];
    return companiesAnswers?.map((companyAnswer) => {
      const selectedColumn = aggregatedReport?.selectedColumns?.find((column) => {
        if (isCompanyDetailsWidget(widget)) {
          return column.id === widget?.widgetDataType;
        }
        return column.id === companyAnswer.questionId;
      });
      const company = aggregatedReport?.companies?.find(
        (company) => company.id === companyAnswer.companyId
      );
      const reports = company?.reports;

      const allResponses = reports?.map((report) => report?.response) || [];
      const allResponsesMap: Record<string, Response> = allResponses?.reduce((acc, curr) => {
        return {
          ...acc,
          ...curr,
        };
      }, {});
      const answer = getAnswer({ selectedColumn, allResponsesMap, company });

      return {
        companyName: companyAnswer?.companyName,
        companyLogo: companyAnswer?.companyLogo,
        questionId: companyAnswer?.questionId,
        sourceType: companyAnswer?.sourceType,
        answer: answer,
        companyId: companyAnswer?.companyId,
        performanceAnswers: companyAnswer?.performanceAnswers,
      };
    });
  }, [aggregatedReport?.companies, aggregatedReport?.selectedColumns, companiesAnswers, widget]);

  const table = useReactTable({
    data: companyBreakDownWidgetData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const onShowAllToggle = useCallback(() => {
    setShowAll((prev) => !prev);
  }, []);

  const buttonText = useMemo(() => {
    if (showAll) return 'Show less';
    return 'Show more';
  }, [showAll]);

  if (!widgetQuestion) return null;

  return (
    <WidgetCard widget={widget}>
      <TableWrapper>
        <Table
          tableInnerRef={tableInnerRef}
          table={table}
          maxHeight={showAll ? 'auto' : '300px'}
          height='auto'
        />
        {showWidgetButton && (
          <Button variant='text' onClick={onShowAllToggle} style={{ marginTop: '10px' }}>
            <Typography variant='subtitle2' color={colors.accent[50]}>
              {buttonText}
            </Typography>
          </Button>
        )}
      </TableWrapper>
    </WidgetCard>
  );
};
