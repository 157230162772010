import { Checkbox, Fade, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { useAllSteps } from '../../../../hooks/useAllSteps';
import { SearchInput } from '../../../../components/SearchInput/SearchInput';
import { useEffect, useMemo, useState } from 'react';
import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import { Table } from '../../../../components/Table/Table';
import { useSurveys } from '../../../../queries/useSurveys';
import { DataSelectionLayout } from './DataSelectionLayout';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { AGGREGATED_REPORT_PARAMS } from '../../../../types';
import { useAtom } from 'jotai';
import { createReportCompletedProgressState } from '../../../../state/UIState';

const CheckboxCellWrapper = styled('div')`
  display: flex;
  align-items: center;
  max-width: 350px;
  gap: 8px;
  width: 350px;
`;

const CellWrapper = styled('div')<{ isTextTruncated?: boolean }>`
  max-width: 250px;
  width: 250px;
  cursor: ${({ isTextTruncated }) => (isTextTruncated ? 'pointer' : 'auto')};
  &&& p {
    white-space: ${({ isTextTruncated }) => (isTextTruncated ? 'nowrap' : 'normal')};
    text-overflow: ${({ isTextTruncated }) => (isTextTruncated ? 'ellipsis' : 'clip')};
  }
`;

type AssessmentData = {
  surveyName: string;
  id: number | string;
  surveyDescription: string;
};

const columnHelper = createColumnHelper<AssessmentData>();

export const AssessmentsSelect = () => {
  const { colors } = useTheme();

  const { allUniqueAssessmentSteps: allAssessmentSteps } = useAllSteps();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { setParam, params: queryParams, getParam } = useQueryParams();
  const selectedAssessmentIdsFromParams =
    queryParams[AGGREGATED_REPORT_PARAMS.ASSESSMENT_IDS] || [];

  const [, setProgress] = useAtom(createReportCompletedProgressState);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    selectedAssessmentIdsFromParams?.length
      ? (selectedAssessmentIdsFromParams as string[]).reduce(
          (acc: Record<string, boolean>, assessmentId) => {
            acc[assessmentId] = true;
            return acc;
          },
          {}
        )
      : {}
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const { data: surveys } = useSurveys();

  useEffect(() => {
    const selectedRows = Object.keys(rowSelection);
    if (!selectedRows.length) {
      const companyDetails = getParam(AGGREGATED_REPORT_PARAMS.COMPANY_DETAILS);

      setProgress(() => {
        if (companyDetails?.length) {
          return 50;
        }
        return 0;
      });

      setParam({ param: AGGREGATED_REPORT_PARAMS.ASSESSMENT_IDS, value: [] });
      return;
    }

    setParam({ param: AGGREGATED_REPORT_PARAMS.ASSESSMENT_IDS, value: selectedRows });
    setProgress(50);
  }, [getParam, rowSelection, setParam, setProgress]);

  const tableData: AssessmentData[] = useMemo(() => {
    const allAssessmentSurveysIds = allAssessmentSteps
      ?.map((step) => step?.survey?.id)
      ?.filter(Boolean);
    const assessmentSurveys = surveys?.filter((survey) =>
      allAssessmentSurveysIds?.includes(survey.id)
    );
    return (
      assessmentSurveys?.map((survey) => {
        return {
          id: survey?.id || '',
          surveyName: survey?.name || '',
          surveyDescription: survey?.description || '',
        };
      }) || []
    );
  }, [allAssessmentSteps, surveys]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('surveyName', {
        cell: (info) => (
          <CheckboxCellWrapper style={{ position: 'relative', top: '7px' }}>
            <Checkbox checked={info.row.getIsSelected()} style={{ padding: 0 }} />
            <Typography variant='subtitle2' color={colors.accent[50]}>
              {info.getValue()}
            </Typography>
          </CheckboxCellWrapper>
        ),
        header: () => (
          <CheckboxCellWrapper>
            <Checkbox
              style={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                table.getToggleAllRowsSelectedHandler()(e);
              }}
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
              }}
            />
            <Typography variant='overline' color={colors.primary[70]}>
              Assessment
            </Typography>
          </CheckboxCellWrapper>
        ),
        meta: {
          width: '35%',
        },
      }),
      columnHelper.accessor('surveyDescription', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <CellWrapper isTextTruncated>
              <Typography variant='body' color={colors.primary[90]}>
                {info.getValue()}
              </Typography>
            </CellWrapper>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Description
          </Typography>
        ),
        meta: {
          width: '65%',
        },
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colors.primary]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: (newState: Updater<RowSelectionState>) => {
      setRowSelection(newState);
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (assessmentData) => String(assessmentData.id),
  });

  return (
    <DataSelectionLayout title='Assessments'>
      <SearchInput
        placeholder='Search for an assessment'
        style={{ width: '345px', marginRight: '12px' }}
        onClear={() => table.getColumn('surveyName')?.setFilterValue('')}
        onChange={(e) => table.getColumn('surveyName')?.setFilterValue(e.target.value)}
        value={table.getColumn('surveyName')?.getFilterValue() ?? ''}
      />
      <Table
        table={table}
        trStyle={{ height: 'auto' }}
        tdStyle={{
          verticalAlign: 'baseline',
          paddingBottom: '8px',
        }}
      />
    </DataSelectionLayout>
  );
};
