import { ROUTES } from '../../../../../../../../../constants/routes';
import {
  ACTIVITY_TYPE,
  COMPANY_INFO_SUBTYPE,
  COMPANY_STAGE,
  CompanyActivity,
  SURVEY_REQUEST_SUBTYPE,
} from '../../../../../../../../../types';
import { AnalystRatingContent } from './AnalystRatingContent';
import { ComparedValues } from './components/ComparedValues';
import { LinkContent } from './components/LinkContent';
import { TextContent } from './components/TextContent';
import { GovernanceScoreContent } from './GovernanceScoreContent';
import { ResponseSubmittedContent } from './ResponseSubmittedContent';
import { StageChangedContent } from './StageChangedContent';

interface Props {
  activity: CompanyActivity;
}

export const ActivityContent = ({ activity }: Props) => {
  if (activity.subtype === COMPANY_INFO_SUBTYPE.STAGE_CHANGE) {
    return (
      <StageChangedContent
        previousValue={activity.value?.previousValue as COMPANY_STAGE}
        newValue={activity.value?.newValue as COMPANY_STAGE}
      />
    );
  }

  if (activity.type === ACTIVITY_TYPE.COMPANY_CREATED) {
    return <TextContent text={`${activity?.company?.name} added to the database`} />;
  }

  if (
    [
      COMPANY_INFO_SUBTYPE.LOCATION_CHANGE,
      COMPANY_INFO_SUBTYPE.SECTOR_CHANGE,
      COMPANY_INFO_SUBTYPE.INDUSTRY_CHANGE,
      COMPANY_INFO_SUBTYPE.ASSIGN_ESG_CONTACT,
      COMPANY_INFO_SUBTYPE.ASSIGN_ANALYST,
    ].includes(activity.subtype as COMPANY_INFO_SUBTYPE)
  ) {
    return (
      <ComparedValues
        comparedValuesConfig={[
          {
            value: activity.value?.previousValue,
          },
          {
            value: activity.value?.newValue,
          },
        ]}
      />
    );
  }

  if (activity.type === ACTIVITY_TYPE.ANALYST_RATING_CHANGE) {
    return <AnalystRatingContent activity={activity} />;
  }

  if (activity.type === ACTIVITY_TYPE.EXCLUSIONS) {
    return <TextContent text={activity.value.exclusion?.exclusion} />;
  }

  if (activity.type === ACTIVITY_TYPE.ASSESSMENT_SUBMITTED) {
    return (
      <LinkContent
        link={`/${ROUTES.ASSESSMENTS}/${activity?.value?.assessmentReport?.id}`}
        text={activity?.value?.assessmentReport?.survey?.name || ''}
      />
    );
  }

  if (activity.type === ACTIVITY_TYPE.GOOD_GOVERNANCE_SCORE) {
    return <GovernanceScoreContent governanceScore={activity.value.governanceScore} />;
  }

  if (activity.type === ACTIVITY_TYPE.CHECKLIST) {
    return <TextContent text={activity.value.checklistItem?.itemName} />;
  }

  if (activity?.subtype === SURVEY_REQUEST_SUBTYPE.REQUEST_SENT) {
    return (
      <LinkContent
        link={`/${ROUTES.REQUESTS}/${activity?.value?.requestGroup?.id}`}
        text={activity?.value?.requestGroup?.requests?.[0]?.survey?.name || ''}
      />
    );
  }

  if (
    activity?.subtype === SURVEY_REQUEST_SUBTYPE.RESPONSE_SUBMITTED ||
    activity?.type === ACTIVITY_TYPE.KPI_RESPONSE_SUBMITTED
  ) {
    return <ResponseSubmittedContent activity={activity} />;
  }

  if (activity?.type === ACTIVITY_TYPE.NOTE) {
    return <TextContent text={activity?.value?.note} />;
  }

  return <></>;
};
