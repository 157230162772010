import { ReactComponent as TargetIcon } from '../../../../../../../../../assets/icons/target-yellow.svg';
import { ReactComponent as EsgCompleteIcon } from '../../../../../../../../../assets/icons/checkbox.svg';
import { COMPANY_STAGE } from '../../../../../../../../../types';
import { ComparedValues } from './components/ComparedValues';

interface Props {
  previousValue: COMPANY_STAGE;
  newValue: COMPANY_STAGE;
}

const getStageIcon = (stage: COMPANY_STAGE) => {
  if (stage === COMPANY_STAGE.SCREENING) {
    return <TargetIcon />;
  }

  if (stage === COMPANY_STAGE.ESG_COMPLETE) {
    return <EsgCompleteIcon />;
  }

  return null;
};

export const StageChangedContent = ({ previousValue, newValue }: Props) => {
  return (
    <ComparedValues
      comparedValuesConfig={[
        {
          value: previousValue,
          icon: getStageIcon(previousValue),
        },
        {
          value: newValue,
          icon: getStageIcon(newValue),
        },
      ]}
    />
  );
};
