import { Button, styled, useTheme } from '@mui/material';
import { useState } from 'react';
import { Accordion } from '../../../../../components/Accordion/Accordion';
import { ColumnSelectItem } from './ColumnSelectItem';
import { Typography } from '../../../../../components/Typography/Typography';
import { AggregatedReportSource, REPORT_DATA_SOURCE } from '../../../../../types';
import { SearchInput } from '../../../../../components/SearchInput/SearchInput';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../state/UIState';
import { DataPointsSubList } from './DataPointsSubList';

const Wrapper = styled('div')`
  padding: 20px 0;
`;

const QuestionsWrapper = styled('div')`
  padding: 16px 0;
`;

const QuestionsList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const DataPoints = () => {
  const [expanded, setExpanded] = useState<number | string | null>(null);
  const { colors } = useTheme();
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);
  const [searchValue, setSearchValue] = useState('');

  const [filteredSources, setFilteredSources] = useState(aggregatedReport?.sources);

  const onSearch = (searchValue: string) => {
    setSearchValue(searchValue);
    const sourcesWithFilteredColumns = aggregatedReport?.sources?.map((source) => {
      return {
        ...source,
        columns: source?.columns?.filter((col) =>
          col?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
        ),
      };
    });

    const sourcesFiltered = sourcesWithFilteredColumns?.filter((s) => s?.columns?.length);

    setFilteredSources(sourcesFiltered);
  };

  const onToggleExpanded = (sourceId: string | number) => {
    if (expanded === sourceId) {
      setExpanded(null);
      return;
    }
    setExpanded(sourceId);
  };

  const getIsAllSelected = (source: AggregatedReportSource) => {
    const allSelectedColumnsIds = aggregatedReport?.selectedColumns?.map((c) => c.id);
    const isAllSelected = source?.columns?.every((c) => allSelectedColumnsIds?.includes(c.id));
    return isAllSelected;
  };

  const onToggleSelectAll = (source: AggregatedReportSource) => {
    if (!aggregatedReport) return;
    const isAllSelected = getIsAllSelected(source);
    const columnsIds = source?.columns?.map((c) => c.id);

    if (isAllSelected) {
      const updatedSelectedColumns = aggregatedReport?.selectedColumns?.filter(
        (col) => !columnsIds?.includes(col.id)
      );

      setAggregatedReport({ ...aggregatedReport, selectedColumns: updatedSelectedColumns });

      return;
    }

    const updatedSelectedQuestions = aggregatedReport?.selectedColumns
      ? [...aggregatedReport.selectedColumns, ...source.columns]
      : [];

    setAggregatedReport({ ...aggregatedReport, selectedColumns: updatedSelectedQuestions });
  };

  return (
    <Wrapper>
      <SearchInput
        placeholder='Search for a question'
        style={{ width: '100%', marginRight: '12px' }}
        onClear={() => onSearch('')}
        onChange={(e) => onSearch(e.target.value)}
        value={searchValue}
      />
      <QuestionsWrapper>
        {filteredSources?.map((source) => {
          const isAllSelected = getIsAllSelected(source);
          return (
            <Accordion
              key={source.id}
              title={source.name}
              isExpanded={expanded === source.id}
              onExpandToggle={() => onToggleExpanded(source.id)}
              suffixButton={
                <Button variant='text' onClick={() => onToggleSelectAll(source)}>
                  <Typography variant='subtitle2' color={colors.accent[50]}>
                    {isAllSelected ? 'Deselect All' : 'Select All'}
                  </Typography>
                </Button>
              }
            >
              <QuestionsList>
                {source?.id === REPORT_DATA_SOURCE.EXCLUSIONS ||
                source?.id === REPORT_DATA_SOURCE.CHECKLIST ||
                source?.id === REPORT_DATA_SOURCE.PERFORMANCE ? (
                  <DataPointsSubList source={source} />
                ) : (
                  source?.columns?.map((column) => {
                    return <ColumnSelectItem key={column.id} column={column} />;
                  })
                )}
              </QuestionsList>
            </Accordion>
          );
        })}
      </QuestionsWrapper>
    </Wrapper>
  );
};
