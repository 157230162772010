import { useQueryClient } from 'react-query';
import { REPORTS, useUpdateReport } from '../../../../queries/useReports';
import {
  ACTIVITY_TYPE,
  ASSESSMENT_SUBMITTED_SUBTYPE,
  GOOD_GOVERNANCE_SCORE_SUBTYPE,
  LoadingId,
  QuestionOption,
  REPORT_STATUS,
  Response,
  STEP_TYPE,
  User,
} from '../../../../types';
import { useAtom, useAtomValue } from 'jotai';
import { activeReportState, responsesState, userState } from '../../../../state/UIState';
import { ROUTES } from '../../../../constants/routes';
import { useNavigate } from 'react-router';
import { useReportTaskClickHandler } from '../../../../hooks/useReportTaskClickHandler';
import { useOnReportClick } from '../../../../hooks/useOnReportClick';
import { useLoadingBar } from '../../../../hooks/useLoadingBar';
import { useCallback } from 'react';

import { CompanyPayload, useCompanyById, useUpdateCompany } from '../../../../queries/useCompanies';
import { useScreeningFlowSurveys } from './useScreeningFlowSurveys';
import { useNotifyUsers } from '../../../../queries/useNotifyUsers';
import {
  DUE_DILIGENCE_SURVEY_NAME,
  INVESTOR_EXCLUSIONS_SURVEY_ID,
} from '../../../../constants/constants';
import { useSurveyFlowData } from './useSurveyFlowData';
import { useGenerateExcelReport } from '../../../../queries/useGenerateExcelReport';
import { useCreateCompanyActivity } from '../../../../queries/useCompanyActivity';

const getGovernanceScore = (response: Record<string, Response>, stepType?: STEP_TYPE) => {
  if (stepType !== STEP_TYPE.SCORE) return null;

  const responseValues = Object.values(response);

  const flattenResponse = responseValues.flatMap((r) => {
    return r;
  });

  const score = flattenResponse.reduce(function (a, b) {
    return a + ((b as QuestionOption)?.weight || 0);
  }, 0);

  return score;
};

export const useInternalQuestionnaireSubmit = () => {
  const navigate = useNavigate();

  const updateReport = useUpdateReport();
  const queryClient = useQueryClient();
  const { onReportTaskClickHandler } = useReportTaskClickHandler();
  const { onReportClick } = useOnReportClick();

  const { stopLoading } = useLoadingBar();

  const [activeReport] = useAtom(activeReportState);
  const [response] = useAtom(responsesState);

  const { data: company } = useCompanyById(activeReport?.company?.id);
  const { mutateAsync: updateCompany } = useUpdateCompany();
  const { mutate: createCompanyActivity } = useCreateCompanyActivity(activeReport?.company?.id);

  const { nextSurveyData } = useSurveyFlowData({ companyId: activeReport?.company?.id });

  const { allAssessmentsStepsData, allAssessmentsSteps } = useScreeningFlowSurveys(
    activeReport?.company?.id
  );

  const { mutate: notifyUsers } = useNotifyUsers();
  const { mutate: generateExcelReport } = useGenerateExcelReport();
  const user = useAtomValue(userState) as User;

  const onSubmitInternal = useCallback(async () => {
    if (!activeReport || !company || !user) return null;

    const currentStep = allAssessmentsStepsData?.find(
      (stepData) => activeReport?.survey.id === stepData?.surveyId
    )?.step;

    const status = !currentStep?.isApprovalRequired
      ? REPORT_STATUS.SUBMITTED
      : REPORT_STATUS.IN_REVIEW;

    await updateReport.mutateAsync(
      { id: activeReport.id, status },
      {
        onSuccess: async () => {
          if (!company || !user) return;
          queryClient.refetchQueries(`${REPORTS}-${company.id}`);

          const governanceScore = getGovernanceScore(response, currentStep?.type);

          if (
            status === REPORT_STATUS.SUBMITTED &&
            activeReport?.status !== REPORT_STATUS.SUBMITTED
          ) {
            let companyPayload: Partial<CompanyPayload> = {
              id: company.id,
              completedWorkflowSteps: currentStep
                ? [...(company?.completedWorkflowSteps || []), currentStep?.id]
                : company?.completedWorkflowSteps,
            };

            if (governanceScore !== null) {
              companyPayload = {
                ...companyPayload,
                governanceScore,
              };
            }

            await updateCompany(companyPayload);

            if (governanceScore !== null) {
              createCompanyActivity({
                company: company.id,
                type: ACTIVITY_TYPE.GOOD_GOVERNANCE_SCORE,
                subtype: GOOD_GOVERNANCE_SCORE_SUBTYPE.GOOD_GOVERNANCE_SCORE,
                value: {
                  governanceScore,
                },
                user: user.id,
              });
            }
          }

          if (activeReport?.survey?.id === INVESTOR_EXCLUSIONS_SURVEY_ID) {
            const responses = Object.values(response);
            const hasFlaggedConditions = responses.some(
              (response) => (response as QuestionOption).name === 'Yes'
            );
            const dueDiligenceExclusionStep = allAssessmentsSteps?.find(
              (step) => step?.name === DUE_DILIGENCE_SURVEY_NAME
            );

            if (!hasFlaggedConditions) {
              const stepIds =
                status === REPORT_STATUS.SUBMITTED && currentStep
                  ? [currentStep.id, dueDiligenceExclusionStep?.id as number]
                  : [dueDiligenceExclusionStep?.id as number];

              updateCompany({
                id: company.id,
                completedWorkflowSteps: currentStep
                  ? [...(company?.completedWorkflowSteps || []), ...stepIds]
                  : company?.completedWorkflowSteps,
              });
            }
          }

          if (currentStep?.shouldSendExcelReport) {
            generateExcelReport(activeReport?.id);
          }

          if (currentStep?.isApprovalRequired) {
            notifyUsers({
              companyId: company.id,
              userIds: currentStep?.notifyUsers?.map((user) => user.id) || [],
              roleIds: currentStep?.approvalRoles?.map((role) => role.id) || [],
            });
          }

          if (!nextSurveyData) {
            navigate(`/${ROUTES.COMPANIES}/${company.id}/${ROUTES.COMPANY_OVERVIEW}`);
            return;
          }

          if (nextSurveyData && !nextSurveyData.report) {
            onReportTaskClickHandler(
              nextSurveyData.report,
              nextSurveyData?.surveyId,
              nextSurveyData?.surveyName,
              nextSurveyData?.companyId
            );

            return;
          }

          if (nextSurveyData.report) {
            onReportClick(nextSurveyData.report);
          }
        },
        onSettled: () => {
          stopLoading(LoadingId.completeReport);
        },
      }
    );

    createCompanyActivity({
      company: activeReport?.company?.id,
      user: user.id,
      type: ACTIVITY_TYPE.ASSESSMENT_SUBMITTED,
      subtype: ASSESSMENT_SUBMITTED_SUBTYPE.ASSESSMENT_SUBMITTED,
      value: {
        assessmentReport: activeReport,
      },
    });
  }, [
    activeReport,
    allAssessmentsSteps,
    allAssessmentsStepsData,
    company,
    createCompanyActivity,
    generateExcelReport,
    navigate,
    nextSurveyData,
    notifyUsers,
    onReportClick,
    onReportTaskClickHandler,
    queryClient,
    response,
    stopLoading,
    updateCompany,
    updateReport,
    user,
  ]);

  return { onSubmitInternal };
};
