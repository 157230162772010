import { Button } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  createRequestCompletedProgressState,
  requestNameState,
  selectedCompaniesState,
  selectedFrequencyState,
  selectedSurveyState,
  userState,
} from '../../../state/UIState';
import { useCreateRequestGroup } from '../../../queries/useRequestGroups';
import { CompanyPayload, useCompanyById, useUpdateCompany } from '../../../queries/useCompanies';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { useQueryParams } from '../../../hooks/useQueryParams';
import {
  ACTIVITY_TYPE,
  CREATE_REQUEST_PARAMS,
  SURVEY_FREQUENCY,
  SURVEY_REQUEST_SUBTYPE,
  User,
} from '../../../types';
import { useResetCreateRequestUiState } from '../hooks/useResetCreateRequestUiState';
import { ROUTES } from '../../../constants/routes';
import { REPORTS } from '../../../queries/useReports';
import { PromptModal } from '../../../components/PromptModal/PromptModal';
import { useCreateCompanyActivities } from '../../../queries/useCompanyActivity';

export const ControlButtons = () => {
  const [progress] = useAtom(createRequestCompletedProgressState);

  const createRequestGroup = useCreateRequestGroup();
  const updateCompany = useUpdateCompany();
  const queryClient = useQueryClient();
  const params = useParams();
  const { params: queryParams, navigateAndPreserveParams } = useQueryParams();

  const [requestNameValue, setRequestNameValue] = useAtom(requestNameState);
  const [selectedFrequency] = useAtom(selectedFrequencyState);
  const [selectedSurvey] = useAtom(selectedSurveyState);
  const [selectedCompanies] = useAtom(selectedCompaniesState);
  const user = useAtomValue(userState) as User;
  const { mutate: createCompanyActivities } = useCreateCompanyActivities();

  const activeStep = Number(params.activeStep);
  const navigate = useNavigate();
  const isInitalStageMode = queryParams[CREATE_REQUEST_PARAMS.MODE] === 'initial';
  const isNextDisabled = useMemo(() => {
    if (activeStep === 1 && progress === 0) return true;
    if (activeStep === 2 && progress === 33 && !isInitalStageMode) return true;
    if (activeStep === 3 && progress < 99) return true;
    return false;
  }, [activeStep, isInitalStageMode, progress]);

  const resetState = useResetCreateRequestUiState();

  const { data: company } = useCompanyById(Number(queryParams['companyId']));

  useEffect(() => {
    const requestNameFromParams = queryParams[CREATE_REQUEST_PARAMS.REQUEST_NAME];
    if (requestNameFromParams && !requestNameValue) {
      setRequestNameValue(requestNameFromParams as string);
    }
  }, [queryParams, requestNameValue, setRequestNameValue]);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const onCancel = useCallback(() => {
    resetState();
    if (!isInitalStageMode) {
      navigate(`/${ROUTES.REQUESTS}`);
    } else {
      navigate(
        `/${ROUTES.COMPANIES}/${Number(queryParams['companyId'])}/${ROUTES.COMPANY_OVERVIEW}`
      );
    }
  }, [isInitalStageMode, navigate, queryParams, resetState]);

  const onNext = useCallback(() => {
    if (isInitalStageMode && activeStep == 2) {
      setOpen(true);
      return;
    }
    if (activeStep !== 3) {
      navigateAndPreserveParams(`/${ROUTES.CREATE_REQUEST}/${activeStep + 1}`);
      return;
    }
    setOpen(true);
  }, [activeStep, isInitalStageMode, navigateAndPreserveParams]);

  const onConfirmModal = useCallback(
    async (newName: string) => {
      if (!user) return;
      handleClose();
      if (!isInitalStageMode) {
        navigate(`/${ROUTES.REQUESTS}`);
      } else {
        navigate(
          `/${ROUTES.COMPANIES}/${Number(queryParams['companyId'])}/${ROUTES.COMPANY_OVERVIEW}`
        );
      }
      const surveyId = selectedSurvey?.id || queryParams[CREATE_REQUEST_PARAMS.SURVEY_ID];
      const frequency = selectedFrequency?.id || queryParams[CREATE_REQUEST_PARAMS.FREQUENCY];

      resetState();
      const companyIds = !isInitalStageMode
        ? selectedCompanies.map((c) => c.id)
        : [Number(queryParams['companyId'])];
      const payload = {
        name: newName,
        survey: Number(surveyId),
        frequency: frequency as SURVEY_FREQUENCY,
        companyIds,
        isExternal: true,
      };
      const requestGroup = await createRequestGroup.mutateAsync(payload);

      const companyActivities = requestGroup?.requests?.map((request) => {
        return {
          company: request?.company?.id || 0,
          user: user.id,
          type: ACTIVITY_TYPE.SURVEY_REQUEST,
          subtype: SURVEY_REQUEST_SUBTYPE.REQUEST_SENT,
          value: {
            requestGroup: requestGroup,
          },
        };
      });

      createCompanyActivities({ data: companyActivities });

      if (isInitalStageMode && selectedFrequency) {
        const companyPayload: Partial<CompanyPayload> = {
          id: Number(queryParams['companyId']),
        };
        if (Boolean(company) && Number(queryParams['stepId'])) {
          companyPayload.completedWorkflowSteps = [
            ...(company?.completedWorkflowSteps || []),
            Number(queryParams['stepId']),
          ];
        }
        await updateCompany.mutateAsync(companyPayload, {
          onSuccess: () => {
            queryClient.refetchQueries(`${REPORTS}-${queryParams['companyId']}`);
          },
        });
      }
    },
    [
      company,
      createCompanyActivities,
      createRequestGroup,
      isInitalStageMode,
      navigate,
      queryClient,
      queryParams,
      resetState,
      selectedCompanies,
      selectedFrequency,
      selectedSurvey?.id,
      updateCompany,
      user,
    ]
  );

  const nextBtnText = useMemo(() => {
    return activeStep === 3 || (isInitalStageMode && activeStep === 2) ? 'Create Request' : 'Next';
  }, [activeStep, isInitalStageMode]);

  const nextBtnStyles = useMemo(() => {
    return {
      width: activeStep === 3 || (isInitalStageMode && activeStep === 2) ? '163px' : '95px',
    };
  }, [activeStep, isInitalStageMode]);

  return (
    <>
      <Button variant='outlined' style={{ width: '95px' }} onClick={onCancel}>
        Cancel
      </Button>
      <Button variant='contained' style={nextBtnStyles} disabled={isNextDisabled} onClick={onNext}>
        {nextBtnText}
      </Button>
      <PromptModal
        title='Create Request'
        message='Give it a name'
        onCancel={handleClose}
        value={requestNameValue || ''}
        onConfirm={onConfirmModal}
        placeholder='Request name'
        isOpen={open}
        confirmText='Create Request'
      />
    </>
  );
};
