import { useTheme } from '@mui/material';
import { Activity } from './Activity/Activity';
import { CompanyActivity, ActivityResult, GroupedActivity } from '../../../../../../../types';
import { ActivityGroup } from './ActivityGroup/ActivityGroup';
import { Typography } from '../../../../../../../components/Typography/Typography';
import { LoadingSpinner } from '../../../../../../../components/LoadingSpinner';

interface Props {
  activities?: ActivityResult[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
}

export const ActivitiesList = ({ activities, isLoading, isFetchingNextPage }: Props) => {
  const { colors } = useTheme();

  if (isLoading) return <LoadingSpinner />;

  if (!activities?.length) {
    return (
      <Typography variant='body' color={colors.primary[90]}>
        No activities
      </Typography>
    );
  }

  return (
    <>
      {activities?.map((activity, i) => {
        if ((activity as GroupedActivity)?.items?.length)
          return <ActivityGroup key={i} activityGroup={activity as GroupedActivity} />;

        return (
          <Activity key={(activity as CompanyActivity).id} activity={activity as CompanyActivity} />
        );
      })}
      {isFetchingNextPage && <LoadingSpinner />}
    </>
  );
};
