import { useMemo } from 'react';
import { ExclusionStatus } from '../../../../components/ExclusionStatus';
import { Typography } from '../../../../components/Typography/Typography';
import { colors } from '../../../../theme/colors';
import { EXCLUSION_STATUS, REPORT_DATA_SOURCE } from '../../../../types';
import { formatNumber } from '../../../../utils/formatters';
import { ChecklistItemCell } from './ChecklistItemCell';
import { CompanyDetailsCell } from './CompanyDetailsCell';

interface Props {
  sourceType?: REPORT_DATA_SOURCE;
  answer: string;
  questionId?: string;
}

export const AnswerCell = ({ sourceType, answer, questionId }: Props) => {
  const isNumber = !isNaN(Number(answer));

  const answerText = useMemo(() => {
    if (isNumber) return formatNumber(answer);
    return answer;
  }, [answer, isNumber]);

  if (sourceType === REPORT_DATA_SOURCE.EXCLUSIONS) {
    return <ExclusionStatus status={answer as EXCLUSION_STATUS} />;
  }

  if (sourceType === REPORT_DATA_SOURCE.COMPANY_DETAILS) {
    return <CompanyDetailsCell questionId={questionId} answer={answer} />;
  }

  if (sourceType === REPORT_DATA_SOURCE.CHECKLIST) {
    return <ChecklistItemCell answer={answer} />;
  }

  return (
    <Typography
      variant='body'
      color={colors.primary[90]}
      style={{ textAlign: isNumber ? 'right' : 'left' }}
    >
      {answerText}
    </Typography>
  );
};
