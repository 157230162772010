import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import { SearchInput } from '../../../../components/SearchInput/SearchInput';
import { Table } from '../../../../components/Table/Table';
import { DataSelectionLayout } from './DataSelectionLayout';
import {
  AGGREGATED_REPORT_PARAMS,
  REPORT_COMPANY_DETAILS,
  REPORT_DATA_SOURCE,
} from '../../../../types';
import { useEffect, useMemo, useState } from 'react';
import { Checkbox, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { useAtom } from 'jotai';
import { createReportCompletedProgressState } from '../../../../state/UIState';

type CompanyDetails = {
  name: string;
  id: REPORT_DATA_SOURCE | REPORT_COMPANY_DETAILS;
};

const CheckboxCellWrapper = styled('div')`
  display: flex;
  align-items: center;
  max-width: 350px;
  gap: 8px;
  width: 350px;
`;

const columnHelper = createColumnHelper<CompanyDetails>();

const tableData: CompanyDetails[] = [
  {
    id: REPORT_COMPANY_DETAILS.ESG_RISK_LEVEL,
    name: 'ESG Risk Level',
  },
  {
    id: REPORT_COMPANY_DETAILS.ESG_OPPORTUNITIES,
    name: 'ESG Opportunities',
  },
  {
    id: REPORT_COMPANY_DETAILS.GOVERNANCE_SCORE,
    name: 'Governance Score',
  },
  {
    id: REPORT_DATA_SOURCE.EXCLUSIONS,
    name: 'Exclusions',
  },
  {
    id: REPORT_DATA_SOURCE.CHECKLIST,
    name: 'Checklist',
  },
  {
    id: REPORT_DATA_SOURCE.PERFORMANCE,
    name: 'Performance',
  },
];

export const CompanyDetailsSelect = () => {
  const { colors } = useTheme();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const { setParam, params: queryParams, getParam } = useQueryParams();
  const [, setProgress] = useAtom(createReportCompletedProgressState);
  const selectedCompanyDetailsFromParams =
    queryParams[AGGREGATED_REPORT_PARAMS.COMPANY_DETAILS] || [];

  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    selectedCompanyDetailsFromParams?.length
      ? (selectedCompanyDetailsFromParams as string[]).reduce(
          (acc: Record<string, boolean>, companyDetails) => {
            acc[companyDetails] = true;
            return acc;
          },
          {}
        )
      : {}
  );

  useEffect(() => {
    const selectedRows = Object.keys(rowSelection);
    if (!selectedRows.length) {
      const assesmentIds = getParam(AGGREGATED_REPORT_PARAMS.ASSESSMENT_IDS);
      setProgress(() => {
        if (assesmentIds) {
          return 50;
        }
        return 0;
      });
      setParam({ param: AGGREGATED_REPORT_PARAMS.COMPANY_DETAILS, value: [] });
      return;
    }

    setParam({ param: AGGREGATED_REPORT_PARAMS.COMPANY_DETAILS, value: selectedRows });
    setProgress(50);
  }, [getParam, rowSelection, setParam, setProgress]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <CheckboxCellWrapper style={{ position: 'relative', top: '7px' }}>
            <Checkbox checked={info.row.getIsSelected()} style={{ padding: 0 }} />
            <Typography variant='subtitle2' color={colors.accent[50]}>
              {info.getValue()}
            </Typography>
          </CheckboxCellWrapper>
        ),
        header: () => (
          <CheckboxCellWrapper>
            <Checkbox
              style={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                table.getToggleAllRowsSelectedHandler()(e);
              }}
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
              }}
            />
            <Typography variant='overline' color={colors.primary[70]}>
              Details
            </Typography>
          </CheckboxCellWrapper>
        ),
        meta: {
          width: '35%',
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colors.primary]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: (newState: Updater<RowSelectionState>) => {
      setRowSelection(newState);
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (companyDetailsData) => String(companyDetailsData.id),
  });

  return (
    <DataSelectionLayout title='Company Details'>
      <SearchInput
        placeholder='Search for an assessment'
        style={{ width: '345px', marginRight: '12px' }}
        onClear={() => table.getColumn('surveyName')?.setFilterValue('')}
        onChange={(e) => table.getColumn('surveyName')?.setFilterValue(e.target.value)}
        value={table.getColumn('surveyName')?.getFilterValue() ?? ''}
      />
      <Table
        table={table}
        height='250px'
        trStyle={{ height: 'auto' }}
        tdStyle={{
          verticalAlign: 'baseline',
          paddingBottom: '8px',
        }}
      />
    </DataSelectionLayout>
  );
};
