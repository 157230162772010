import { useMemo } from 'react';
import { AggregatedReportBreakdownGroup, TAggregatedReportData } from '../../../types';
import { filterCompany } from '../utils/filterCompany';
import { getBreakDownTableData } from '../utils/getBreakDownTableData';
import { getGroupsData } from '../utils/getGroupsData';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../state/UIState';

export const useBreakdownData = () => {
  const [aggregatedReport] = useAtom(aggregatedReportState);

  const filteredCompanies = useMemo(() => {
    if (!aggregatedReport?.companies?.length) return [];
    return aggregatedReport?.companies?.filter((company) => {
      return filterCompany(company, aggregatedReport?.issuerFilters);
    });
  }, [aggregatedReport?.companies, aggregatedReport?.issuerFilters]);

  const breakdownTableData: TAggregatedReportData[] = useMemo(() => {
    if (!filteredCompanies?.length) return [];
    return getBreakDownTableData({
      filteredCompanies,
      selectedColumns: aggregatedReport?.selectedColumns || [],
    });
  }, [aggregatedReport?.selectedColumns, filteredCompanies]);

  const groupedBy = useMemo(() => {
    return aggregatedReport?.groupedBy;
  }, [aggregatedReport?.groupedBy]);

  const aggregatedReportBreakdownGroups: AggregatedReportBreakdownGroup[] = useMemo(() => {
    if (!groupedBy?.length) return [];

    return getGroupsData({
      companies: filteredCompanies,
      groupedBy,
      selectedColumns: aggregatedReport?.selectedColumns || [],
    });
  }, [aggregatedReport?.selectedColumns, filteredCompanies, groupedBy]);

  return {
    breakdownTableData,
    filteredCompanies,
    groupedBy,
    aggregatedReportBreakdownGroups,
    selectedColumns: aggregatedReport?.selectedColumns,
  };
};
