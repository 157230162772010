import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export interface IAxiosHttpConf {
  baseURL: string;
  token?: string;
}

export class AxiosHttp {
  readonly config: IAxiosHttpConf;

  private axiosInstance: AxiosInstance;

  constructor(config: IAxiosHttpConf) {
    this.config = config;
    this.axiosInstance = this.initAxios();
  }

  async delete<ResponseType>(
    url: string,
    config: AxiosRequestConfig<any> | undefined = undefined
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.delete<ResponseType, AxiosResponse<ResponseType>, null>(url, config);
  }

  async get<ResponseType>(
    url: string,
    config: AxiosRequestConfig<null> | undefined = undefined
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.get<ResponseType, AxiosResponse<ResponseType>, null>(url, config);
  }

  async post<RequestType, ResponseType>(
    url: string,
    data?: RequestType
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.post<ResponseType, AxiosResponse<ResponseType>, RequestType>(
      url,
      data
    );
  }

  async put<RequestType, ResponseType>(
    url: string,
    data?: RequestType
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.put<ResponseType, AxiosResponse<ResponseType>, RequestType>(
      url,
      data
    );
  }

  async patch<RequestType, ResponseType>(
    url: string,
    data?: RequestType
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.patch<ResponseType, AxiosResponse<ResponseType>, RequestType>(
      url,
      data
    );
  }

  async setHeader(header: string, value: string): Promise<void> {
    this.axiosInstance.defaults.headers.common[header] = value;
  }

  async setResponseInterceptor(
    callback: (response: AxiosResponse<any>) => AxiosResponse<any>,
    onRejected?: (error: any) => any
  ): Promise<void> {
    this.axiosInstance.interceptors.response.use(callback, onRejected);
  }

  private initAxios(): AxiosInstance {
    const { baseURL, token } = this.config;
    const axiosInstance = axios.create();
    axiosInstance.defaults.baseURL = baseURL;

    //setInterceptor request: set token in from local storage in x-amz-authorization header
    axiosInstance.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    //setInterceptor response
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response?.status;
        if (status === 401) {
          console.log('401');
        }
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }
}

export class Http {
  static axios: AxiosHttp;

  static async init(promiseToken: Promise<string>) {
    const token = await promiseToken;
    this.axios = new AxiosHttp({
      baseURL: process.env.REACT_APP_API_BASE_URL as string,
      token,
    });
  }
}
