import { useMemo } from 'react';
import { ChipsSelectorV2 } from '../../../../../../../components/ButtonSelector/ChipsSelectorV2';
import { FormField } from '../../../../../../../components/FormField/FormField';
import { SingleSelect } from '../../../../../../../components/SingleSelect/SingleSelect';
import { SelectItem, TWidgetDataType, WIDGET_TYPE } from '../../../../../../../types';
import { getCompanyDetailsAnswerOptions } from '../../utils/getCompanyDetailsAnswerOptions';

interface Props {
  widgetTypeOptions: SelectItem[];
  selectedType?: SelectItem | null;
  setSelectedType: (type: SelectItem | null) => void;
  selectedAnswer?: SelectItem | null;
  setSelectedAnswer: (answer: SelectItem | null) => void;
  dataType: TWidgetDataType;
}

export const CompanyDetailsTypeContent = ({
  widgetTypeOptions,
  selectedType,
  setSelectedType,
  selectedAnswer,
  setSelectedAnswer,
  dataType,
}: Props) => {
  const answerOptions = useMemo(() => {
    return getCompanyDetailsAnswerOptions(dataType);
  }, [dataType]);
  return (
    <>
      <FormField label={'Widget Type'}>
        <ChipsSelectorV2
          options={widgetTypeOptions}
          selectedItem={selectedType || null}
          onSelect={(type) => setSelectedType(type)}
        />
      </FormField>
      {selectedType?.id === WIDGET_TYPE.COMPANY_BREAKDOWN && (
        <FormField label={'Scores'}>
          <SingleSelect
            style={{ width: '100%' }}
            options={answerOptions}
            value={selectedAnswer}
            onChange={(_, answer) => setSelectedAnswer(answer)}
            disablePortal
            fieldPlaceholder={'Select Question'}
          />
        </FormField>
      )}
    </>
  );
};
