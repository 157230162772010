import {
  REPORT_COMPANY_DETAILS,
  REPORT_DATA_SOURCE,
  SelectItem,
  TWidgetDataType,
} from '../../../../../../../types';
import { AssessmentTypeContent } from './AssessmentTypeContent';
import { ChecklistTypeContent } from './ChecklistTypeContent';
import { CompanyDetailsTypeContent } from './CompanyDetailsTypeContent';
import { ExclusionsTypeContent } from './ExclusionsTypeContent';
import { PerformanceTypeContent } from './PerformanceTypeContent';

interface Props {
  dataType: TWidgetDataType;
  selectedQuestion?: SelectItem | null;
  setSelectedQuestion: (question: SelectItem | null) => void;
  widgetTypeOptions: SelectItem[];
  selectedType?: SelectItem | null;
  setSelectedType: (type: SelectItem | null) => void;
  selectedAnswer?: SelectItem | null;
  setSelectedAnswer: (answer: SelectItem | null) => void;
  startYear?: string;
  endYear?: string;
  setStartYear: (year: string) => void;
  setEndYear: (year: string) => void;
}

export const WidgetModalContent = ({
  dataType,
  selectedQuestion,
  setSelectedQuestion,
  widgetTypeOptions,
  selectedType,
  setSelectedType,
  selectedAnswer,
  setSelectedAnswer,
  startYear,
  endYear,
  setStartYear,
  setEndYear,
}: Props) => {
  if (dataType === REPORT_DATA_SOURCE.ASSESSMENT)
    return (
      <AssessmentTypeContent
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        widgetTypeOptions={widgetTypeOptions}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedAnswer={selectedAnswer}
        setSelectedAnswer={setSelectedAnswer}
      />
    );

  if (
    dataType === REPORT_COMPANY_DETAILS.ESG_OPPORTUNITIES ||
    dataType === REPORT_COMPANY_DETAILS.ESG_RISK_LEVEL ||
    dataType === REPORT_COMPANY_DETAILS.GOVERNANCE_SCORE
  ) {
    return (
      <CompanyDetailsTypeContent
        widgetTypeOptions={widgetTypeOptions}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedAnswer={selectedAnswer}
        setSelectedAnswer={setSelectedAnswer}
        dataType={dataType}
      />
    );
  }

  if (dataType === REPORT_DATA_SOURCE.EXCLUSIONS) {
    return (
      <ExclusionsTypeContent
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        widgetTypeOptions={widgetTypeOptions}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedAnswer={selectedAnswer}
        setSelectedAnswer={setSelectedAnswer}
      />
    );
  }

  if (dataType === REPORT_DATA_SOURCE.CHECKLIST) {
    return (
      <ChecklistTypeContent
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        widgetTypeOptions={widgetTypeOptions}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedAnswer={selectedAnswer}
        setSelectedAnswer={setSelectedAnswer}
      />
    );
  }

  if (dataType === REPORT_DATA_SOURCE.PERFORMANCE) {
    return (
      <PerformanceTypeContent
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        startYear={startYear}
        endYear={endYear}
        setStartYear={setStartYear}
        setEndYear={setEndYear}
        widgetTypeOptions={widgetTypeOptions}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />
    );
  }
  return null;
};
