import { Button, styled, useTheme } from '@mui/material';
import { FC } from 'react';
import { APPLY_EVALUATION_RULES_HEADER_CONTROLS } from '../../constants/layoutSizes';
import { Typography } from '../Typography/Typography';

const ApplyEvaluationRulesWrapper = styled('div')<{ isActive: boolean; bgColor?: string }>`
  height: ${({ isActive }) => (isActive ? `${APPLY_EVALUATION_RULES_HEADER_CONTROLS}px` : '0px')};
  background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.colors.accent[10])};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
`;
const PlaceHolder = styled('div')`
  height: 100%;
  width: 252px;
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

interface Props {
  text: string;
  onCancel?: () => void;
  onConfirm: () => void;
  isActive: boolean;
  cancelText?: string;
  confirmText?: string;
  isConfirmDisabled?: boolean;
  isCancelDisabled?: boolean;
  bgColor?: string;
  cancelButtonStyle?: React.CSSProperties;
}

export const ConfirmActionBar: FC<Props> = ({
  text,
  onCancel,
  onConfirm,
  isActive,
  cancelText,
  confirmText,
  isConfirmDisabled,
  isCancelDisabled,
  bgColor,
  cancelButtonStyle,
}) => {
  const { colors } = useTheme();

  return (
    <ApplyEvaluationRulesWrapper isActive={isActive} bgColor={bgColor}>
      <PlaceHolder />
      <Typography variant='h5' color={colors.accent[60]} style={{ textAlign: 'center' }}>
        {text}
      </Typography>
      <ButtonsWrapper>
        {onCancel && (
          <Button
            variant='outlined'
            style={{ width: '120px', height: '32px', ...cancelButtonStyle }}
            onClick={onCancel}
            disabled={!!isCancelDisabled}
          >
            {cancelText || 'Cancel'}
          </Button>
        )}
        <Button
          variant='contained'
          style={{ width: '120px', height: '32px' }}
          onClick={onConfirm}
          disabled={!!isConfirmDisabled}
        >
          {confirmText || 'Confirm'}
        </Button>
      </ButtonsWrapper>
    </ApplyEvaluationRulesWrapper>
  );
};
