import { Survey, SurveyQuestion, SurveySection, TReportSurveyData } from '../types';
import { flattenTree } from './treeUtilities';

export const getQuestionsMap = (survey: Survey) => {
  const flatSurvey = flattenTree(survey?.sections as SurveySection[]);

  const questionsMap: Record<string, SurveyQuestion> = flatSurvey.reduce(
    (acc: Record<string, SurveyQuestion>, curr) => {
      for (const question of curr.questions) {
        acc[question.id] = question;
        if (question.includeConditions) {
          for (const option of question.options) {
            if (option.subQuestions) {
              for (const subQuestion of option.subQuestions) {
                acc[subQuestion.id] = subQuestion;
              }
            }
          }
        }
      }
      return acc;
    },
    {}
  );

  return questionsMap;
};

export const getAggregatedReportSurveysMap = (surveysList: Survey[]) => {
  if (!surveysList?.length) return {};
  return surveysList?.reduce((acc: Record<string, TReportSurveyData>, curr) => {
    const questionsMap = getQuestionsMap(curr);
    const surveyData = {
      id: curr.id,
      name: curr.name,
      questions: Object.values(questionsMap),
    };
    return {
      ...acc,
      [curr.id]: surveyData,
    };
  }, {});
};
