import { styled } from '@mui/material';
import { Modal } from '../../../../Modal/Modal';
import { AnalystRatingPreview } from './AnalystRatingPreview';
import { AnalystRatingReview } from './AnalystRatingReview/AnalystRatingReview';
import { useIsAnalystRatingReviewed } from './useIsAnalystRatingReviewed';
import { useCallback } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  esgOpportunitiesLevelOverrideState,
  esgRiskLevelOverrideState,
  selectedReviewOptionState,
  userState,
} from '../../../../../state/UIState';
import { useParams } from 'react-router';
import {
  CompanyPayload,
  useCompanyById,
  useUpdateCompany,
} from '../../../../../queries/useCompanies';
import { useIsAnalyticsStepCompleted } from '../hooks/useIsAnalyticsStepCompleted';
import { useAnalystRatingStep } from '../hooks/useAnalystRatingStep';
import { REVIEW_TYPE, User } from '../../../../../types';

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ReviewAnalystRatingModal = ({ isOpen, onClose }: Props) => {
  const isReviewed = useIsAnalystRatingReviewed();

  const params = useParams();

  const { data: company } = useCompanyById(Number(params.id));
  const { mutateAsync: updateCompany } = useUpdateCompany();

  const getIsStepCompleted = useIsAnalyticsStepCompleted();

  const [selectedReviewOption, setSelectedReviewOption] = useAtom(selectedReviewOptionState);
  const [esgRiskLevelOverride, setEsgRiskLevelOverride] = useAtom(esgRiskLevelOverrideState);
  const [esgOpportunitiesLevelOverride, setEsgOpportunitiesLevelOverride] = useAtom(
    esgOpportunitiesLevelOverrideState
  );

  const step = useAnalystRatingStep();
  const user = useAtomValue(userState) as User;

  const onSubmit = useCallback(() => {
    if (!company || !selectedReviewOption) return;

    let companyPayload: Partial<CompanyPayload> = {
      id: company.id,
      analystRatingReview: {
        type: selectedReviewOption,
        name: selectedReviewOption,
        reviewedBy: user,
      },
    };

    if (selectedReviewOption === REVIEW_TYPE.OVERRIDE) {
      companyPayload = {
        ...companyPayload,
        esgRiskLevel: esgRiskLevelOverride || company.esgRiskLevel,
        esgOpportunitiesLevel: esgOpportunitiesLevelOverride || company.esgOpportunitiesLevel,
      };
    }

    if (
      (selectedReviewOption === REVIEW_TYPE.APPROVE ||
        selectedReviewOption === REVIEW_TYPE.OVERRIDE) &&
      step &&
      user
    ) {
      const approvedBy = [...(company?.analystRatingApprovedBy || []), user?.id];
      const isCompleted = getIsStepCompleted(approvedBy);

      companyPayload = {
        ...companyPayload,
        completedWorkflowSteps: isCompleted
          ? [...(company?.completedWorkflowSteps || []), step.id]
          : company.completedWorkflowSteps,
        analystRatingApprovedBy: approvedBy,
      };
    }

    updateCompany(companyPayload);
    onClose();
  }, [
    company,
    esgOpportunitiesLevelOverride,
    esgRiskLevelOverride,
    getIsStepCompleted,
    onClose,
    selectedReviewOption,
    step,
    updateCompany,
    user,
  ]);

  const onCloseModal = () => {
    onClose();
    setSelectedReviewOption(null);
    setEsgOpportunitiesLevelOverride(null);
    setEsgRiskLevelOverride(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={'Evaluate Analyst Rating'}
      onClose={onCloseModal}
      onConfirm={onSubmit}
      confirmBtnText='Submit'
      cancelBtnText='Cancel'
      isConfirmDisabled={!isReviewed}
      contentContainerStyle={{ padding: '32px' }}
      modalContainerStyle={{ width: '796px' }}
    >
      <Container>
        <AnalystRatingPreview />
        <AnalystRatingReview />
      </Container>
    </Modal>
  );
};
