import { Company, EXCLUSION_STATUS } from '../../../types';

export const getExclusionAnswer = ({
  company,
  questionId,
}: {
  company?: Company;
  questionId: string | number;
}) => {
  const isViolated = company?.violatedExclusionsIds?.includes(Number(questionId));
  const companyExclusionIds = company?.exclusions?.map((exclusion) => exclusion.id) || [];
  const hasExclusion = companyExclusionIds?.includes(Number(questionId));

  let answer = null;
  if (isViolated && hasExclusion) {
    answer = EXCLUSION_STATUS.VIOLATED;
  }

  if (hasExclusion && !isViolated) {
    answer = EXCLUSION_STATUS.SATISFIED;
  }
  return answer;
};
