import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';

interface Props {
  title: string;
  children: React.ReactNode;
}

const Wrapper = styled('div')`
  margin-top: 32px;
`;

const AssessmentsWrapper = styled('div')`
  margin-top: 16px;
`;

export const DataSelectionLayout = ({ title, children }: Props) => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      <Typography variant='h4' color={colors.primary[90]}>
        {title}
      </Typography>
      <AssessmentsWrapper>{children}</AssessmentsWrapper>
    </Wrapper>
  );
};
