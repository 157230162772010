import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../../../components/Typography/Typography';
import { formatDate } from '../../../../../../../../utils/formatters';

const Footer = styled('div')`
  display: flex;
  align-items: center;
`;

interface Props {
  createdDate: string;
  users: string;
}

export const ActivityFooter = ({ createdDate, users }: Props) => {
  const { colors } = useTheme();
  return (
    <Footer>
      <Typography variant='caption' color={colors.primary[70]}>
        {formatDate(createdDate)} • {users}
      </Typography>
    </Footer>
  );
};
