import { useMemo } from 'react';
import { ChipsSelectorV2 } from '../../../../../../../components/ButtonSelector/ChipsSelectorV2';
import { FormField } from '../../../../../../../components/FormField/FormField';
import { SingleSelect } from '../../../../../../../components/SingleSelect/SingleSelect';
import { REPORT_DATA_SOURCE, SelectItem, WIDGET_TYPE } from '../../../../../../../types';
import { useBreakdownData } from '../../../../../hooks/useBreakdownData';
import { CHECKLIST_ANSWER_OPTIONS } from '../../constants';

interface Props {
  selectedQuestion?: SelectItem | null;
  setSelectedQuestion: (question: SelectItem | null) => void;
  widgetTypeOptions: SelectItem[];
  selectedType?: SelectItem | null;
  setSelectedType: (type: SelectItem | null) => void;
  selectedAnswer?: SelectItem | null;
  setSelectedAnswer: (answer: SelectItem | null) => void;
}

export const ChecklistTypeContent = ({
  selectedQuestion,
  setSelectedQuestion,
  widgetTypeOptions,
  selectedType,
  setSelectedType,
  selectedAnswer,
  setSelectedAnswer,
}: Props) => {
  const { selectedColumns } = useBreakdownData();

  const questionOptions: SelectItem[] = useMemo(() => {
    return selectedColumns
      ?.filter((q) => q.sourceType === REPORT_DATA_SOURCE.CHECKLIST)
      ?.map((q) => {
        if (!q) return null;
        return { id: q?.id, value: q?.name };
      })
      ?.filter((q) => Boolean(q)) as SelectItem[];
  }, [selectedColumns]);

  const answerOptions: SelectItem[] = useMemo(() => {
    return CHECKLIST_ANSWER_OPTIONS;
  }, []);
  return (
    <>
      <FormField label={'Checklist Items'}>
        <SingleSelect
          style={{ width: '100%' }}
          options={questionOptions}
          value={selectedQuestion}
          onChange={(_, questionOption) => setSelectedQuestion(questionOption)}
          disablePortal
          fieldPlaceholder={'Select Checklist Item'}
        />
      </FormField>
      <FormField label={'Widget Type'}>
        <ChipsSelectorV2
          options={widgetTypeOptions}
          selectedItem={selectedType || null}
          onSelect={(type) => setSelectedType(type)}
        />
      </FormField>
      {selectedType?.id === WIDGET_TYPE.COMPANY_BREAKDOWN && selectedQuestion && (
        <FormField label={'Answers'}>
          <SingleSelect
            style={{ width: '100%' }}
            options={answerOptions}
            value={selectedAnswer}
            onChange={(_, answer) => setSelectedAnswer(answer)}
            disablePortal
            fieldPlaceholder={'Select Answer'}
          />
        </FormField>
      )}
    </>
  );
};
