import { Button } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import {
  selectedCompanyExclusionsState,
  expandedCategoryState,
  selectedConditionState,
  userState,
} from '../../state/UIState';
import { useCompanyExclusions } from '../SIngleCompany/hooks/useCompanyExclusions';
import { useCallback, useMemo } from 'react';
import { ACTIVITY_TYPE, EXCLUSION_STATUS, EXCLUSION_SUBTYPE, User } from '../../types';
import { useUpdateCompany } from '../../queries/useCompanies';
import { useNavigate, useParams } from 'react-router';
import { useInvalidateExclusionsByCompany } from '../../queries/useExclusionsByCompany';
import { ROUTES } from '../../constants/routes';
import { useCreateCompanyActivities } from '../../queries/useCompanyActivity';

export const ControlButtons = () => {
  const [selectedCompanyExclusions, setSelectedCompanyExclusions] = useAtom(
    selectedCompanyExclusionsState
  );
  const [, setExpandedCategory] = useAtom(expandedCategoryState);
  const [, setSelectedConditionState] = useAtom(selectedConditionState);
  const { data: companyExclusions } = useCompanyExclusions();
  const params = useParams();
  const { mutate: createCompanyActivities } = useCreateCompanyActivities(Number(params.id));

  const navigate = useNavigate();

  const user = useAtomValue(userState) as User;

  const updateCompany = useUpdateCompany();
  const invalidateExclusionsByCompany = useInvalidateExclusionsByCompany();

  const onResetAndNavigate = useCallback(() => {
    setSelectedCompanyExclusions([]);
    setExpandedCategory(null);
    setSelectedConditionState(null);
    navigate(`/${ROUTES.COMPANIES}/${params?.id}/${ROUTES.INVESTOR_EXCLUSIONS}`);
  }, [
    setSelectedCompanyExclusions,
    setExpandedCategory,
    setSelectedConditionState,
    navigate,
    params?.id,
  ]);

  const existingCompanyExclusionIds = useMemo(() => {
    return companyExclusions?.map((exclusion) => exclusion.id) || [];
  }, [companyExclusions]);

  const violatedExistingCompanyIds = useMemo(() => {
    return (
      companyExclusions
        ?.filter((exclusion) => exclusion.status === EXCLUSION_STATUS.VIOLATED)
        ?.map((exclusion) => exclusion.id) || []
    );
  }, [companyExclusions]);

  const nextCTALabel = useMemo(() => {
    if (!selectedCompanyExclusions?.length) return 'Add Exclusion';

    return `Add Exclusion (${selectedCompanyExclusions?.length})`;
  }, [selectedCompanyExclusions?.length]);

  const onAddExclusions = useCallback(async () => {
    if (!selectedCompanyExclusions?.length || !user) return;

    const companyExclusionIds = selectedCompanyExclusions?.map((exclusion) => exclusion.id) || [];

    const exclusions = [...existingCompanyExclusionIds, ...companyExclusionIds];
    const violatedExclusionsIds = [...violatedExistingCompanyIds, ...companyExclusionIds];

    await updateCompany.mutateAsync({
      id: Number(params.id),
      exclusions,
      violatedExclusionsIds,
    });
    invalidateExclusionsByCompany(Number(params.id));

    const companyActivities = selectedCompanyExclusions?.map((exclusion) => {
      return {
        type: ACTIVITY_TYPE.EXCLUSIONS,
        subtype: EXCLUSION_SUBTYPE.NEW_EXCLUSION,
        value: {
          exclusion,
        },
        company: Number(params.id),
        user: user.id,
      };
    });

    createCompanyActivities({ data: companyActivities });

    onResetAndNavigate();
  }, [
    createCompanyActivities,
    existingCompanyExclusionIds,
    invalidateExclusionsByCompany,
    onResetAndNavigate,
    params.id,
    selectedCompanyExclusions,
    updateCompany,
    user,
    violatedExistingCompanyIds,
  ]);

  return (
    <>
      <Button variant='outlined' style={{ width: '95px' }} onClick={onResetAndNavigate}>
        Cancel
      </Button>
      <Button
        variant='contained'
        style={{ width: '166px' }}
        disabled={!selectedCompanyExclusions?.length}
        onClick={onAddExclusions}
      >
        {nextCTALabel}
      </Button>
    </>
  );
};
