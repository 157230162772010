import { BarSeriesOption, LineSeriesOption } from 'echarts';
import { BarLineChart } from '../../../../../../../components/Charts/BarCharts/BarLineChart';
import { AggregatedReportWidget, REPORT_DATA_SOURCE } from '../../../../../../../types';
import { useMemo } from 'react';
import { useBreakdownData } from '../../../../../hooks/useBreakdownData';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { useWidgetQuestion } from '../../hooks/useWidgetQuestion';
import { WidgetCard } from '../WidgetCard';
import { useWidgetChartColor } from '../../hooks/useWidgetChartColor';
import { styled } from '@mui/material';
import { useBarChartWidgetData } from './useBarChartWidgetData';

interface Props {
  widget: AggregatedReportWidget;
}

const Wrapper = styled('div')`
  flex: 1;
  min-width: 400px;
`;

export const BarChartWidget = ({ widget }: Props) => {
  const { filteredCompanies } = useBreakdownData();
  const getWidgetChartColor = useWidgetChartColor();

  const widgetQuestion = useWidgetQuestion(widget);

  const widgetData = useBarChartWidgetData(widget);

  const seriesPerformance: BarSeriesOption[] = useMemo(() => {
    if (widget?.widgetDataType !== REPORT_DATA_SOURCE.PERFORMANCE) return [];
    return widgetData?.map((kpiData, i) => {
      const shouldStack = widgetData?.length > 5;
      return {
        name: kpiData?.name,
        type: 'bar',
        barGap: 0.2,
        color: getWidgetChartColor(widgetData, i),
        data: kpiData?.seriesValue,
        stack: shouldStack ? 'total' : undefined,
      };
    });
  }, [getWidgetChartColor, widget?.widgetDataType, widgetData]);

  const series: BarSeriesOption | LineSeriesOption = useMemo(() => {
    const formatter = (params: CallbackDataParams) => {
      const { value } = (params as CallbackDataParams).data as { value: number };

      const pctValue = value
        ? `${(((value as number) / filteredCompanies?.length) * 100).toFixed(2)}%`
        : '0%';
      return pctValue;
    };

    return {
      type: 'bar',
      colorBy: 'data',
      data:
        widgetData?.map((widget, i) => ({
          value: widget?.value,
          itemStyle: { color: getWidgetChartColor(widgetData, i) },
        })) || [],
      label: {
        show: true,
        position: 'top',
        formatter,
        fontSize: 14,
        fontWeight: 600,
      },
    };
  }, [filteredCompanies?.length, getWidgetChartColor, widgetData]);

  const yAxisOptions = {
    max: function (value: { max: number }) {
      return value.max + 1;
    },
  };

  const xAxisLabelFormatter = (value: string, index: number) => {
    return `{icon${index}|} ${value}`;
  };

  const xAxisRichStyles = useMemo(() => {
    return widgetData.reduce((acc: any, item, index) => {
      if (!item.icon) return acc;
      acc[`icon${index}`] = {
        height: 16,
        width: 16,
        backgroundColor: {
          image: item.icon as string,
        },
      };
      return acc;
    }, {});
  }, [widgetData]);

  const xAxisOptions = {
    rich: xAxisRichStyles,
    overflow: 'truncate',
    width: 60,
    interval: 0,
  };

  if (!widgetQuestion) return null;

  return (
    <Wrapper>
      <WidgetCard widget={widget} titleContainerStyles={{ flexDirection: 'column', gap: '3px' }}>
        <BarLineChart
          seriesData={
            widget?.widgetDataType === REPORT_DATA_SOURCE.PERFORMANCE
              ? (seriesPerformance as BarSeriesOption[])
              : [series]
          }
          xAxisData={
            widget?.widgetDataType === REPORT_DATA_SOURCE.PERFORMANCE
              ? undefined
              : widgetData?.map((d) => d?.name)
          }
          yAxisOptions={yAxisOptions}
          xAxisLabelFormatter={xAxisLabelFormatter}
          xAxisOptions={xAxisOptions}
        />
      </WidgetCard>
    </Wrapper>
  );
};
