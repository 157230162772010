import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import { starcierTheme } from './theme/material-custom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Provider as JotaiStateProvider } from 'jotai';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Auth0ProviderWithNavigate } from './Auth0ProviderWithNavigateProps';

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <QueryClientProvider client={queryClient}>
    <JotaiStateProvider>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={starcierTheme}>
            <Auth0ProviderWithNavigate>
              <App />
            </Auth0ProviderWithNavigate>
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </JotaiStateProvider>
  </QueryClientProvider>
);

reportWebVitals();
