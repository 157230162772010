import {
  AggregatedReportColumn,
  Company,
  REPORT_DATA_SOURCE,
  Response,
  SURVEY_FREQUENCY,
  TAggregatedReportData,
  TBreakdownReportData,
} from '../../../types';
import { getAnswer } from './getAnswer';

export const getBreakDownTableData = ({
  filteredCompanies,
  selectedColumns,
}: {
  filteredCompanies: Company[];
  selectedColumns: AggregatedReportColumn[];
}): TAggregatedReportData[] => {
  if (!filteredCompanies?.length) return [];
  return filteredCompanies?.map((company) => {
    const reports = company?.reports;

    const allResponses = reports?.map((report) => report?.response);
    const allResponsesMap: Record<string, Response> = allResponses?.reduce((acc, curr) => {
      return {
        ...acc,
        ...curr,
      };
    }, {});

    const breakdownReportData: TBreakdownReportData[] =
      selectedColumns
        ?.map((selectedColumn) => {
          if (selectedColumn?.sourceType === REPORT_DATA_SOURCE.PERFORMANCE) {
            const companyPerfrmanceQuarterly =
              company?.kpisData?.[SURVEY_FREQUENCY.QUARTERLY] || [];

            return companyPerfrmanceQuarterly?.map((quarter) => {
              return {
                questionId: `${selectedColumn.id}-${quarter?.period}`,
                questionName: `${quarter?.period} ${selectedColumn.name}`,
                sourceType: selectedColumn?.sourceType,
                answer: quarter?.[Number(selectedColumn.id)]?.value || 'N/A',
              };
            });
          }

          return {
            questionId: selectedColumn.id,
            questionName: selectedColumn.name,
            sourceType: selectedColumn?.sourceType,
            answer: getAnswer({ selectedColumn, allResponsesMap, company }),
          };
        })
        ?.flatMap((item) => item as TBreakdownReportData) || [];

    return {
      companyName: company?.name,
      logoUrl: company?.logoUrl,
      companyId: company?.id,
      breakdownReportData,
    };
  });
};
