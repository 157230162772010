import { useAtomValue } from 'jotai';
import { REPORT_STATUS, Report, User } from '../../../types';
import { userState } from '../../../state/UIState';
import { useMemo } from 'react';

export const useReportStatusIndicator = (report?: Report) => {
  const user = useAtomValue(userState) as User;

  const reportStatusIndicator = useMemo(() => {
    if (!user) return report?.status;
    if (report?.status === REPORT_STATUS.REJECTED || report?.status === REPORT_STATUS.APPROVED)
      return report?.status;

    if (report?.approvedBy?.includes(user.id)) {
      return REPORT_STATUS.APPROVED;
    }

    return report?.status;
  }, [report?.approvedBy, report?.status, user]);

  return reportStatusIndicator;
};
