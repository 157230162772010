import { FC, useCallback, useMemo } from 'react';
import { EVALUATION_SCALE, EvaluationRule, SelectItem } from '../../../types';
import { styled, useTheme } from '@mui/material';
import { Typography } from '../../Typography/Typography';
import { RuleItem } from './EvaluationRule';
import { ChipsSelectorV2 } from '../../ButtonSelector/ChipsSelectorV2';
import { evaluationScaleConfig } from './evaluationScaleConfig';
import { getEvaluationRules } from '../../../utils/getEvaluationRules';
import { Switch } from '../../Switch';

const Wrapper = styled('div')`
  position: relative;
`;
const HeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
`;
const SortWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 59px;
  margin-right: 8px;
`;

const RulesList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ScaleWrapper = styled('div')`
  margin-bottom: 12px;
  margin-top: 12px;
`;

interface Props {
  toggleIncludeEvaluation?: () => void;
  includeEvaluation?: boolean;
  evaluationScale: EVALUATION_SCALE;
  evaluationRules: EvaluationRule[];
  setEvaluationRules: (rules: EvaluationRule[]) => void;
  setEvaluationScale: (scale: EVALUATION_SCALE) => void;
}

export const EvaluationRulesConfig: FC<Props> = ({
  toggleIncludeEvaluation,
  includeEvaluation,
  evaluationRules,
  evaluationScale,
  setEvaluationRules,
  setEvaluationScale,
}) => {
  const { colors } = useTheme();

  const onNameChange = useCallback(
    (weight: number, newName: string) => {
      if (!newName) {
        setEvaluationRules(evaluationRules.filter((r) => r.weight !== weight));
        return;
      }
      const newEvaluationRules = evaluationRules.map((rule) => {
        if (rule.weight === weight) {
          return { ...rule, name: newName };
        }
        return rule;
      });
      setEvaluationRules(newEvaluationRules);
    },
    [evaluationRules, setEvaluationRules]
  );

  const onDeleteRule = useCallback(
    (weight: number) => {
      const newEvaluationRules = evaluationRules
        .filter((rule) => rule.weight !== weight)
        .map((rule, i) => {
          return { ...rule, weight: i };
        });
      setEvaluationRules(newEvaluationRules);
    },
    [evaluationRules, setEvaluationRules]
  );

  const selectedEvaluationScale = useMemo(() => {
    return evaluationScaleConfig.find((scale) => evaluationScale === scale.id) as SelectItem;
  }, [evaluationScale]);

  const onSetEvaluationScale = useCallback(
    (selectedItem: SelectItem) => {
      setEvaluationRules(getEvaluationRules(selectedItem.id as EVALUATION_SCALE));
      setEvaluationScale(selectedItem.id as EVALUATION_SCALE);
    },
    [setEvaluationRules, setEvaluationScale]
  );

  return (
    <Wrapper>
      <Switch
        checked={!!includeEvaluation}
        onChange={toggleIncludeEvaluation}
        label='Include evaluation'
      />
      {includeEvaluation && (
        <>
          <ScaleWrapper>
            <Typography variant='srOnly' color={colors.primary[90]} style={{ marginBottom: '4px' }}>
              Evaluation Scale
            </Typography>
            <ChipsSelectorV2
              options={evaluationScaleConfig}
              selectedItem={selectedEvaluationScale}
              onSelect={onSetEvaluationScale}
            />
          </ScaleWrapper>
          <HeaderWrapper>
            <SortWrapper>
              <Typography variant='overline' color={colors.primary[70]}>
                SCORE
              </Typography>
            </SortWrapper>
            <Typography variant='overline' color={colors.primary[70]}>
              EXPLANATION
            </Typography>
          </HeaderWrapper>
          <RulesList>
            {evaluationRules.map((rule) => (
              <RuleItem
                key={rule.name}
                rule={rule}
                rulesLength={evaluationRules.length}
                onNameChange={onNameChange}
                onDeleteRule={onDeleteRule}
              />
            ))}
          </RulesList>
        </>
      )}
    </Wrapper>
  );
};
