import { useInfiniteQuery, UseInfiniteQueryResult, useMutation, useQueryClient } from 'react-query';
import { Http } from '../services/Http';
import {
  ACTIVITY_SUBTYPE,
  ACTIVITY_TYPE,
  CompanyActivity,
  CompanyActivityPagination,
  CompanyActivityValue,
} from '../types';
import { useToastMessage } from '../hooks/useToastMessage';

export const COMPANY_ACTIVITY = 'companyActivity';

export type CompanyActivityPayload = {
  type: ACTIVITY_TYPE;
  company: number;
  user: number;
  subtype?: ACTIVITY_SUBTYPE;
  value: CompanyActivityValue;
};

export type CompanyActivityFilters = {
  users?: string;
  subtypes?: string;
};

async function fetchCompanyActivity(
  page: number,
  companyId: number,
  filters: CompanyActivityFilters
): Promise<CompanyActivityPagination> {
  const { data } = await Http.axios.get<CompanyActivityPagination>(
    `/company-activity/paginated/${companyId}`,
    {
      params: {
        page,
        perPage: 40,
        ...(filters || {}),
      },
    }
  );

  return data;
}

export function useCompanyActivity(
  companyId: number,
  filters: CompanyActivityFilters
): UseInfiniteQueryResult<CompanyActivityPagination> {
  return useInfiniteQuery(
    [`${COMPANY_ACTIVITY}-${companyId}`, companyId, filters],
    async ({ pageParam = 1 }) => fetchCompanyActivity(Number(pageParam), companyId, filters),
    {
      staleTime: Infinity,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      getNextPageParam: (params) => {
        if (!params?.pagination || params.pagination.page >= params.pagination.totalPages)
          return undefined;

        return params.pagination.page + 1;
      },
    }
  );
}

async function createActivity(payload: CompanyActivityPayload): Promise<CompanyActivity> {
  const { data } = await Http.axios.post<CompanyActivityPayload, CompanyActivity>(
    `/company-activity`,
    payload
  );
  return data;
}

export function useCreateCompanyActivity(companyId?: number) {
  const queryClient = useQueryClient();
  const { pushErrorToast } = useToastMessage();

  return useMutation(createActivity, {
    // onMutate: async () => {
    //   startLoading(LoadingId.createCompanies);
    // },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create company activity' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([`${COMPANY_ACTIVITY}-${companyId}`, companyId]);
    },
  });
}

async function createCompanyActivities(payload: {
  data: CompanyActivityPayload[];
}): Promise<CompanyActivity[]> {
  const { data } = await Http.axios.post<{ data: CompanyActivityPayload[] }, CompanyActivity[]>(
    `/company-activity/bulk`,
    payload
  );
  return data;
}

export function useCreateCompanyActivities(companyId?: number) {
  const queryClient = useQueryClient();
  const { pushErrorToast } = useToastMessage();

  return useMutation(createCompanyActivities, {
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create company activities' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([`${COMPANY_ACTIVITY}-${companyId}`, companyId]);
    },
  });
}
