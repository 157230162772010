import { RISK_LEVEL } from '../types';
import { ReactComponent as HighRed } from '../assets/icons/high-red.svg';
import { ReactComponent as Medium } from '../assets/icons/medium.svg';
import { ReactComponent as LowGreen } from '../assets/icons/low-green.svg';

interface Props {
  esgRiskLevel?: RISK_LEVEL;
}

export const EsgRiskIcon = ({ esgRiskLevel }: Props) => {
  if (esgRiskLevel === RISK_LEVEL.HIGH) return <HighRed />;
  if (esgRiskLevel === RISK_LEVEL.MEDIUM) return <Medium />;

  return <LowGreen />;
};
