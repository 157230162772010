import { ReactNode } from 'react';

export interface ColumnMeta {
  width: string;
  minWidth?: string;
  maxWidth?: string;
  alignHeader?: 'left' | 'right';
  hideHeader?: boolean;
  rightBorder?: string;
  headerWrapperWidth?: string;
  tdStyles?: React.CSSProperties;
  thStyles?: React.CSSProperties;
  hideTooltip?: boolean;
  cellInnerDivHeight?: string;
}

export interface User {
  id: number;
  created_at: Date;
  updated_at: Date;
  fullName: string;
  email: string;
  role: Role;
}

export interface UserPayload {
  fullName: string;
  email: string;
  roleId?: number;
  id?: number;
}

export interface Role {
  id: number;
  created_at: Date;
  updated_at: Date;
  name: USER_ROLE;
}

export enum USER_ROLE {
  ADMIN = 'Admin',
  ANALYST = 'Analyst',
  COMPLIANCE = 'Compliance',
  MANAGER = 'Manager',
  EXTERNAL_USER = 'External User',
}

export interface Investor {
  id: number;
  name: string;
}

export interface ExternalInvestor {
  id: number;
  investorCloakedId: string;
  investorCloaked: string;
  accountingFundName: string;
  investorFunds: InvestorExternalFund[];
}

export interface InvestorExternalFund {
  fundId: string;
  fundName: string;
  fundOfferingId: string;
}

export enum EXCLUSION_STATUS {
  SATISFIED = 'Satisfied',
  VIOLATED = 'Violated',
}

export interface RequestGroup {
  id: number;
  requests: Request[];
  dueDate: string | null;
  created_at: string;
  survey: Survey;
  frequency: SURVEY_FREQUENCY;
  type: REQUEST_GROUP_TYPE;
}

export interface Request {
  id: number;
  company: Partial<Company>;
  reports: Partial<Report>[];
  requestGroup?: RequestGroup;
  survey: Survey;
  frequency: SURVEY_FREQUENCY;
  name: string;
  dueDate?: string;
}

export enum SURVEY_FREQUENCY {
  ONE_TIME = 'one_time',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
}

export enum COMPANY_STAGE {
  SCREENING = 'Screening',
  ESG_COMPLETE = 'ESG Complete',
}

export interface SVP_Issuer {
  id: number;
  uniqueId: string;
  sector: string | null;
  region: string | null;
  companyType: COMPANY_TYPE;
  name: string;
  companyName: string;
  salesforceId: string;
}

export interface RawCompany {
  id: number;
  name: string;
  logoUrl: string;
  sectors: Sector[];
  externalId: string | null;
  analyst: string;
  region: Region;
  isRecurringKpiAssigned: boolean;
  isYearlyAssessmentAssigned: boolean;
  exclusionScreeningSurvey: Survey;
  exclusionDueDiligenceSurvey: Survey;
  kpisDataFrequency: CoreKpiRequestFrequency | null;
  kpisData: CompanyKpisData;
  stage: COMPANY_STAGE;
  disabled?: boolean;
  created_at: string;
  isActivePosition: string;
  esgScoreData: RawEsgScoreData;
  type: COMPANY_TYPE;
  esgRiskLevel: RISK_LEVEL;
  esgOpportunitiesLevel: RISK_LEVEL;
  analystRatingNotes: string;
  description: string;
  city: string;
  country: string;
  industry: Industry;
  reports: Report[];
  isQuarterlyChecklistAssigned: boolean;
  checklistData?: ChecklistData;
  exclusions: Exclusion[];
  violatedExclusionsIds: number[];
  externalEsgContact: ExternalUser | null;
}

export interface Company {
  id: number;
  externalId: string | null;
  name: string;
  logoUrl: string;
  sectors: Sector[];
  analyst: string;
  region: Region | null;
  isRecurringKpiAssigned: boolean;
  isYearlyAssessmentAssigned: boolean;
  exclusionScreeningSurvey: Survey | null;
  exclusionDueDiligenceSurvey: Survey;
  kpisDataFrequency: CoreKpiRequestFrequency | null;
  kpisData: CompanyKpisData;
  stage: COMPANY_STAGE;
  disabled?: boolean;
  created_at: string;
  isActivePosition: string;
  externalEsgContact: ExternalUser | null;
  esgScoreData: EsgScoreData;
  type: COMPANY_TYPE;
  esgRiskLevel: RISK_LEVEL;
  esgOpportunitiesLevel: RISK_LEVEL;
  analystRatingNotes: string;
  description: string;
  city: string;
  country: string;
  industry: Industry;
  reports: Report[];
  isQuarterlyChecklistAssigned: boolean;
  checklistData?: ChecklistData;
  objectives?: Objective[];
  violatedExclusionsIds: number[];
  exclusions: Exclusion[];
  areExclusionsExternallySaved?: boolean;
  areManagersNotified?: boolean;
  approvedByAdmin?: number;
  approvedByManager?: number;
  completedWorkflowSteps?: (string | number)[];
  analystRatingApprovedBy?: number[];
  analystRatingRejectedBy?: number[];
  governanceScore?: number | null;
  analystRatingReview?: AnalystRatingReview | null;
  screeningCompletedDate?: string;
}

export interface ExternalUser {
  created_at: string;
  email: string;
  updated_at: string;
  id: number;
}

export enum COMPANY_TYPE {
  PRIVATE = 'Private',
  PUBLIC = 'Public',
  CONTROLLED = 'Controlled',
  NOT_CONTROLLED = 'Not Controlled',
}

export enum RISK_LEVEL {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export type CoreKpiRequestFrequency =
  | SURVEY_FREQUENCY.ANNUALLY
  | SURVEY_FREQUENCY.QUARTERLY
  | SURVEY_FREQUENCY.MONTHLY;

export type CompanyKpisData = Record<CoreKpiRequestFrequency, KpisPeriodData[]>;

export type KpisPeriodData = Record<
  number,
  { value: number | string | null; format: CORE_KPI_FORMAT; notes?: string }
> & {
  period: string;
  timestamp: number;
};
export interface RawEsgScoreData {
  esgDistributionOverTime: DistributionOverTime[];
}

export interface EsgScoreData {
  esgDistributionOverTime: DistributionOverTimeWithAverage[];
}

export interface SingleCompanyData {
  esgTrendingPercent: number;
  socialTrendingPercent: number;
  governanceTrendingPercent: number;
  performanceTable: PerformanceTableItem[];
  environmentalTrendingPercent: number;
  exclusions: { name: string }[];
  esgDrivers: { name: string; drivers: string[] }[];
  esgSwotAnalysis: {
    strengths: string;
    weaknesses: string;
    opportunities: string;
    threats: string;
  };
}

export interface PerformanceTableItem {
  eKpiName: string | null;
  eKpiValue: number | string | null;
  eKpiTrend: 'positive' | 'negative' | null;
  sKpiName: string | null;
  sKpiValue: number | string | null;
  sKpiTrend: 'positive' | 'negative' | null;
  gKpiName: string | null;
  gKpiValue: number | string | null;
  gKpiTrend: 'positive' | 'negative' | null;
}

export interface Report {
  id: number;
  created_at: string;
  updated_at: string;
  response: Record<string, Response>;
  evaluation: Record<string, EvaluationRule>;
  month: number;
  year: number;
  status: REPORT_STATUS;
  progress: number;
  company: Company;
  request: Request;
  survey: Survey;
  approvedBy: number[];
  rejectedBy: number[];
  review: Record<string, QuestionReview>;
}

export enum SURVEY_TYPE {
  CORE = 'Core',
  USER = 'User',
  SYSTEM = 'System',
}

export enum SURVEY_STAGE {
  INITIAL_ESG_SCREENING = 'InitialEsgScreening',
  DUE_DILIGENCE = 'DueDiligence',
  CORE_KPI = 'CoreKpi',
  INDUSTRY = 'Industry',
  CHECKLIST_QUARTERLY = 'CheckListQuarterly',
  CHECKLIST_ANNUALLY = 'CheckListAnnually',
  INVESTOR_EXCLUSIONS_SCREENING = 'InvestorExclusionScreening',
}

export interface Survey {
  id: number;
  name: string;
  description: string;
  sections: SurveySection[];
  type: SURVEY_TYPE | null;
  stage: SURVEY_STAGE;
  isAssigned: boolean;
  includeEvaluation: boolean;
  evaluationRules: EvaluationRule[];
  evaluationScale?: EVALUATION_SCALE;
  created_at: string;
  isArchived: boolean;
  includeWeight?: boolean;
  includeQuestionNumbers?: boolean;
}

export interface EvaluationRule {
  name: string;
  weight: number;
  color: keyof ColorPalette;
}

export enum REVIEW_TYPE {
  APPROVE = 'Approve',
  OVERRIDE = 'Override',
  REJECT = 'Reject',
}

export type TReviewOptionConfig = {
  type: REVIEW_TYPE;
  name: string;
  color: string;
};

export interface QuestionReview {
  type: REVIEW_TYPE;
  name: string;
  reason?: string;
  overrideResponse?: null | string | QuestionOption | QuestionOption[];
  reviewedBy?: User | null;
  reviewDate?: string;
}

export interface SurveyTemplateSection {
  id: number;
  name: string;
  questions: SurveyQuestionTemplate[];
  category: SurveyTemplateCategory;
  isExpanded?: boolean;
}

export enum EVALUATION_SCALE {
  BINARY = 'Binary',
  RANGE = 'Range',
}
export interface SurveyQuestionTemplate {
  id: number;
  name: string;
  type: QUESTION_TYPE;
  options: QuestionOptionTemplate[] | null;
  tableData: QuestionTableData;
  includeInstructions: boolean;
  includeEvaluation: boolean | null;
  evaluationRules: EvaluationRule[] | null;
  evaluationScale: EVALUATION_SCALE;
  instructions: string;
}

export interface QuestionOptionTemplate {
  id: number;
  name: string;
}

export interface SurveyTemplateCategory {
  id: number;
  name: string;
}

export interface SurveyQuestion {
  sectionId: string;
  isActive?: boolean;
  followUpQuestions?: SurveyQuestion[];
  investorId?: number | null;
  fundIds?: string[] | null;
  condition?: string;
  includeConditions?: boolean;
  id: string;
  name: string;
  type: QUESTION_TYPE;
  options: QuestionOption[];
  tableData: QuestionTableData;
  includeInstructions: boolean;
  includeEvaluation: boolean | null;
  evaluationRules: EvaluationRule[] | null;
  instructions: string;
  isRequired: boolean;
}

export interface QuestionTableData {
  columns: string[];
  rows: QuestionTableRow[];
  readOnlyColumns?: string[];
}

export type KPISValueResponse = {
  value: string;
  notes?: string;
};

export type QuestionTableRow = Record<string, string | KPISValueResponse> & {
  id: string;
};

export interface QuestionOption {
  id: string;
  name: string;
  justCreated?: boolean;
  isFlagged?: boolean;
  notes?: string;
  subQuestions?: SurveyQuestion[];
  weight?: number;
}

export enum QUESTION_TYPE {
  TEXT = 'Text',
  SINGLE = 'Single',
  MULTI = 'Multi',
  TABLE = 'Table',
  YES_NO = 'YesNo',
}

export interface Sector {
  id: number;
  name: string;
  industries: Industry[];
}
export interface Region {
  id: number;
  name: string;
}

export interface Industry {
  id: number;
  name: string;
  sector: Sector;
  questions: SurveyQuestionTemplate[];
  survey?: Survey;
}

export interface CompanyRelation {
  name: string;
  logo: string;
}

export enum REPORT_STATUS {
  NOT_STARTED = 'Not Started',
  IN_PROGRESS = 'In Progress',
  IN_REVIEW = 'In Review',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  SUBMITTED = 'Submitted',
}

export enum OBJECTIVE_STATE {
  AT_RISK = 'At Risk',
  PROGRESSING = 'Progressing',
  ON_TRACK = 'On Track',
}

export interface ComponentWithChildren {
  children: ReactNode;
}

export type SelectItem = {
  id: number | string;
  value: string;
  icon?: JSX.Element;
  group?: string;
  disabled?: boolean;
};

// export interface Objective {
//   id: number;
//   name: string;
//   sector: Sector;
//   progress: number;
//   state: OBJECTIVE_STATE;
//   lastUpdated: string;
//   deadline: string;
//   owner: string;
// }
export interface KPI {
  id: number;
  name: string;
  value: number;
  trendingPercent: number;
  description?: string;
  data: ScatterChartItem[];
}

type ChartDatItemStyle = {
  color?: string;
};

export type ChartDataItem = {
  name: string;
  value: number;
  seriesValue?: (string | number)[][];
  id: string;
  groupedData?: ChartDataItem[];
  itemStyle?: ChartDatItemStyle;
  icon?: string;
  scoreValue?: number;
};

export type ChartRichStyles = Record<
  string,
  {
    height?: number;
    width?: number;
    backgroundColor?: {
      image: string;
    };
    [key: string]: any;
  }
>;

export interface PieChartData {
  data: ChartDataItem[];
  title: string;
  totalLabel?: string;
  totalValue: number;
}

export type ScatterChartItem = {
  id: number;
  name: string;
  value: number;
};

export type Response = null | string | QuestionOption | QuestionOption[] | QuestionTableData;

export type DistributionOverTime = {
  year: number;
  socialScore: number;
  governanceScore: number;
  environmentalScore: number;
  controversies: number;
  msciRatings: number;
  trucost: number;
  sustainalytics: number;
};

export type DistributionOverTimeWithAverage = DistributionOverTime & {
  esgScore: number;
  combinedEsgScore: number;
};

export enum LoadingId {
  createSurvey = 'createSurvey',
  updateSurvey = 'updateSurvey',
  createRequestGroup = 'createRequestGroup',
  fetchSurvey = 'fetchSurvey',
  updateRequestGroup = 'updateRequestGroup',
  deleteSurvey = 'deleteSurvey',
  deleteRequestGroup = 'deleteRequestGroup',
  updateCompany = 'updateCompany',
  updateReport = 'updateReport',
  completeReport = 'completeReport',
  createExclusion = 'createExclusion',
  updateExclusion = 'updateExclusion',
  updateExclusions = 'updateExclusions',
  deleteExclusion = 'deleteExclusion',
  createExclusionsDbScreenshot = 'createExclusionsDbScreenshot',
  resetExclusionsDbTablesFromScreenshot = 'resetExclusionsDbTablesFromScreenshot',
  createCompanies = 'createCompanies',
  createRequest = 'createRequest',
  deleteCompany = 'deleteCompany',
  resetCompany = 'resetCompany',
  archiveSurvey = 'archiveSurvey',
  updateAppConfig = 'updateAppConfig',
  updateCoreKpiCategory = 'updateCoreKpiCategory',
  updateCoreKpiCategories = 'updateCoreKpiCategories',
  createCoreKpiCategory = 'createCoreKpiCategory',
  deleteCoreKpiCategory = 'deleteCoreKpiCategory',
  createCoreKpi = 'createCoreKpi',
  updateCoreKpi = 'updateCoreKpi',
  updateCoreKpis = 'updateCoreKpis',
  deleteCoreKpi = 'deleteCoreKpi',
  createCoreKpiDbScreenshot = 'createCoreKpiDbScreenshot',
  resetCoreKpiDbTablesFromScreenshot = 'resetCoreKpiDbTablesFromScreenshot',
  updateChecklistCategory = 'updateChecklistCategory',
  createChecklistCategory = 'createChecklistCategory',
  deleteChecklistCategory = 'deleteChecklistCategory',
  updateChecklistCategories = 'updateChecklistCategories',
  createChecklistItem = 'createChecklistItem',
  updateChecklistItem = 'updateChecklistItem',
  updateChecklistItems = 'updateChecklistItems',
  deleteChecklistItem = 'deleteChecklistItem',
  createChecklistDbScreenshot = 'createChecklistDbScreenshot',
  resetChecklistDbTablesFromScreenshot = 'resetChecklistDbTablesFromScreenshot',
  deleteUser = 'deleteUser',
  updateUser = 'updateUser',
  createUser = 'createUser',
  createObjective = 'createObjective',
  updateObjective = 'updateObjective',
  deleteObjective = 'deleteObjective',
  createKeyTask = 'createKeyTask',
  updateKeyTask = 'updateKeyTask',
  deleteKeyTask = 'deleteKeyTask',
  createExclusionCategory = 'createExclusionCategory',
  updateExclusionCategory = 'updateExclusionCategory',
  updateExclusionCategories = 'updateExclusionCategories',
  createExclusionCondition = 'createExclusionCondition',
  updateExclusionCondition = 'updateExclusionCondition',
  updateExclusionConditions = 'updateExclusionConditions',
  createStage = 'createStage',
  updateStage = 'updateStage',
  updateStages = 'updateStages',
  deleteStage = 'deleteStage',
  createStep = 'createStep',
  updateStep = 'updateStep',
  updateSteps = 'updateSteps',
  deleteStep = 'deleteStep',
  updateWorkflow = 'updateWorkflow',
  createWorkflowsDbScreenshot = 'createWorkflowsDbScreenshot',
  resetWorkflowsDbTablesFromScreenshot = 'resetWorkflowsDbTablesFromScreenshot',
  editSubmission = 'editSubmission',
  createAggregatedReport = 'createAggregatedReport',
  updateAggregatedReport = 'updateAggregatedReport',
  deleteAggregatedReport = 'deleteAggregatedReport',
}

export interface ToastProps {
  message: string;
  action?: () => void;
  actionLabel?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

export enum ToastMessageType {
  info = 'info',
  warning = 'warning',
  error = 'error',
  success = 'success',
}

export interface ExtendedToastProps extends ToastProps {
  type: ToastMessageType;
}

export enum EXCLUSION_SEVERITY {
  LOW = 'low',
  MODERATE = 'moderate',
  HIGH = 'high',
  SIGNIFICANT = 'significant',
}

//Tree
import type { MutableRefObject } from 'react';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { ColorPalette } from './theme/structure';

export interface TreeItem {
  id: UniqueIdentifier;
  children: TreeItem[];
  collapsed?: boolean;
}

export interface SurveySection {
  id: string;
  name: string;
  questions: SurveyQuestion[];
  subSections: SurveySection[];
  depth: number;
  parentId: UniqueIdentifier | null;
  collapsed?: boolean;
  description?: string;
  includeDescription?: boolean;
}

export type TreeItems = SurveySection[];

export interface FlattenedItem extends SurveySection {
  index: number;
}

export type SensorContext = MutableRefObject<{
  items: FlattenedItem[];
  offset: number;
}>;

export enum MENU {
  MAIN = 'main',
  BACK_OFFICE = 'back-office',
}

export interface ConfigData {
  lastCompaniesSync: string;
  isCoreKpiSurveyUpToDate: boolean;
  areCheckListSurveysUpToDate: boolean;
  isExclusionSurveyUpToDate: boolean;
  isControlledWorkflowUpToDate: boolean;
  isNotControlledWorkflowUpToDate: boolean;
}

export interface AppConfig {
  data: ConfigData;
}

export enum CORE_KPI_FORMAT {
  NUMBER = 'Number',
  PERCENTAGE = 'Percentage',
  TEXT = 'Text',
}

export interface CoreKpi {
  id: number;
  name: string;
  description: string;
  metric: string;
  format: CORE_KPI_FORMAT;
  category?: CoreKpiCategory;
  created_at: string;
  order: number;
  isDeleted: boolean;
}

export interface CoreKpiCategory {
  id: number;
  name: string;
  isNew?: boolean;
  kpis: CoreKpi[];
  isDeleted: boolean;
  order: number;
}

export type KpisMatrixData = CoreKpi & { categoryName: string } & Record<string, any>;

export enum CHECKLIST_ITEM_FREQUENCY {
  ANNUALLY = SURVEY_FREQUENCY.ANNUALLY,
  QUARTERLY = SURVEY_FREQUENCY.QUARTERLY,
}

export enum QUESTION_MANDATORY_OPTIONS {
  REQUIRED = 'Required',
  NOT_REQUIRED = 'Not Required',
}

export interface ChecklistItem {
  id: number;
  name: string;
  instructions: string;
  frequency: CHECKLIST_ITEM_FREQUENCY;
  category?: ChecklistCategory;
  order: number;
}
export interface ChecklistCategory {
  id: number;
  name: string;
  items: ChecklistItem[];
  isDeleted: boolean;
  order: number;
}

export type CategoryItem = {
  id: number;
  name: string;
  kpis?: CoreKpi[];
  items?: ChecklistItem[];
  isNew?: boolean;
};

export type ChecklistDataOption = {
  name: CHECKLIST_OPTION;
  updatedPeriod: string;
};

export type ChecklistDataItem = {
  id: string;
  itemName: string;
  categoryName: string;
  checklistOption: ChecklistDataOption;
  notes?: string;
  frequency: CHECKLIST_ITEM_FREQUENCY;
};

export type ChecklistData = Record<CHECKLIST_ITEM_FREQUENCY, ChecklistDataItem[]>;

export enum CHECKLIST_OPTION {
  YES = 'Yes',
  NO = 'No',
  NA = 'N/A',
}

export enum CREATE_REQUEST_PARAMS {
  SURVEY_ID = 'surveyId',
  FREQUENCY = 'frequency',
  REQUEST_NAME = 'requestName',
  SURVEY_STAGE = 'surveyStage',
  MODE = 'mode',
  SURVEY_NAME = 'surveyName',
  COMPANY_IDS = 'companyIds',
}

export enum KEY_TASK_GOAL_TYPE {
  KPI = 'KPI',
  CHECKLIST = 'Checklist',
  OTHER = 'Other',
}

export enum KEY_TASK_CONDITION_OPERATOR {
  EQUAL = '=',
  GREATER_THAN = '>',
  LESS_THAN = '<',
}

export type KeyTask = {
  id?: number;
  goalType: KEY_TASK_GOAL_TYPE;
  objective: number;
  kpi?: CoreKpi;
  checklistItem?: ChecklistItem;
  conditionOperator: KEY_TASK_CONDITION_OPERATOR;
  conditionValue: string;
  status: REPORT_STATUS;
  progress: number;
  updated_at: string;
};

export type Objective = {
  id: number;
  keyTasks: KeyTask[];
  name: string;
  frequency: SURVEY_FREQUENCY;
  status: REPORT_STATUS;
  progress: number;
  company: number;
  updated_at: string;
};
export enum REQUEST_GROUP_TYPE {
  CORE_KPI = 'CoreKpi',
  USER = 'User',
}

export type Step = {
  step: number;
  text: string;
  isClickable: boolean;
};

export enum EXPORT_COMPANIES_PARAMS {
  COLUMNS = 'columns',
  COMPANY_IDS = 'companyIds',
  EXPORT_TYPE = 'exportType',
}

export enum EXPORT_COMPANIES_TYPE {
  companyDetails = 'companyDetails',
  companyExclusions = 'companyExclusions',
}

export type ExportCompanyColumn = {
  column: string;
  value: keyof Company;
};

export type ExportCompanyExclusionsColumn = {
  column: string;
  value: keyof CompanyExclusion;
};

export interface SelectableCompanyTableData extends Company {
  disabled?: boolean;
}

export type ExternalFunds = {
  id: string;
  name: string;
};

export interface Exclusion {
  id: number;
  created_at: string;
  updated_at: string;
  exclusion: string;
  isActive: boolean;
  externalInvestorId: string;
  externalInvestorName: string;
  externalFunds: ExternalFunds[];
  isDeleted: boolean;
  order: number;
  condition: ExclusionCondition;
}

export interface ExclusionCondition {
  id: number;
  created_at: string;
  updated_at: string;
  condition: string;
  isActive: boolean;
  isDeleted: boolean;
  order: number;
  category: ExclusionCategory;
  exclusions: Exclusion[];
}

export interface ExclusionCategory {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  isDeleted: boolean;
  conditions: ExclusionCondition[];
  order: number;
}

export interface CompanyExclusion {
  id: number;
  text: string;
  condition: string;
  category: string;
  status: EXCLUSION_STATUS;
  investor: string;
  funds: ExternalFunds[];
  company: Company;
  updated_at: string;
  created_at: string;
  investorName: string;
}
export type ExcludedInvestorFunds = {
  investorName: string;
  investorId: string;
  fundName: string;
  fundId: string;
};

export type ConditionModel = {
  conditionId: string;
  conditionName: string;
  excludedInvestorFunds: ExcludedInvestorFunds[];
};

export interface ExternallyExclusionsSavePayload {
  issuerId: string;
  issuerName: string;
  conditionModels: ConditionModel[];
}

export type AssessmentStepData = {
  surveyName: string;
  surveyId: number;
  companyId: number;
  report: Report | null;
  step: WorkflowStep;
};

type SubItems = {
  title: string;
  route: string;
  id: string;
  showForRoles?: USER_ROLE[];
  badgeNum?: number;
};

export type TMenuItem = {
  title: string;
  icon: JSX.Element;
  iconActive: JSX.Element;
  route?: string;
  id: string;
  subItems?: SubItems[];
  showForRoles?: USER_ROLE[];
  hasTopBorder?: boolean;
};

export enum STEP_TYPE {
  ASSESSMENT = 'assessment',
  REQUEST = 'request',
  COMPANY_INFO = 'companyInfo',
  SCORE = 'score',
}

export enum REQUEST_STEP {
  RECURRING_KPI_UPDATE = 'recurringKPIUpdate',
  CHECKLIST_QUARTERLY = 'checklistQuarterly',
  YEARLY_ASSESSMENT = 'yearlyAssessment',
}
export enum COMPANY_INFO_STEP {
  COMPANY_INFORMATION = 'companyInformation',
  ANALYST_RATING = 'analystRating',
  ESG_CONTACT = 'esgContact',
}

export type WorkflowStep = {
  id: string | number;
  type: STEP_TYPE;
  name: string;
  survey?: Partial<Survey>;
  requestStep?: REQUEST_STEP;
  companyInfoStep?: COMPANY_INFO_STEP;
  accessRoles?: Role[];
  isApprovalRequired?: boolean;
  approvalRoles?: Role[];
  notifyUsers?: User[];
  requiredUsersApproval?: User[];
  blockingSteps?: WorkflowStep[];
  stage?: WorkflowStage;
  order: number;
  includeBlockingSteps: boolean;
  shouldSendExcelReport: boolean;
};

export type WorkflowStage = {
  id: number;
  name: string;
  order: number;
  steps: WorkflowStep[];
  workflow: Workflow;
};

export type Workflow = {
  id: number;
  companyType: COMPANY_TYPE;
  stages: WorkflowStage[];
  config: { stages: WorkflowStage[] };
};

export type EsgContactFormPayload = {
  esgContact: string;
};

export enum USER_APPROVAL_REQUIREMENT_OPTIONS {
  REQUIRED = 'Required',
  OPTIONAL = 'Optional',
}

export type ReviewSidebarStats = {
  percentage: number;
  completed: number;
  allQuestions: number;
};

export type ReviewTabConfig = {
  tab: string;
  tabComponent: ReactNode;
};

export type AnalystRatingReview = {
  type: REVIEW_TYPE;
  name: string;
  reviewedBy?: User | null;
};

export enum GOVERNANCE_SCORE_TYPE {
  GOOD = 'Good',
  BAD = 'Bad',
}

export enum LOCAL_USER_STATE {
  NO_USER_FOUND = 'No user found',
  LOADING = 'Loading',
}
export interface SingleAssessmentQuestionRow {
  id: string;
  question: string;
  answer: Response;
  overrideAnswer?: Response;
  overrideReason?: string;
  type: QUESTION_TYPE;
  overriddenBy?: string;
  evaluationColor?: keyof ColorPalette;
  evaluationLabel?: string;
  evaluationScore?: number | null;
}

export enum AGGREGATED_REPORT_ISSUERS_FILTER {
  RISK_LEVEL = 'riskLevel',
  OPPORTUNITIES = 'opportunities',
  GOVERNANCE_SCORE_TYPE = 'governanceScoreType',
  TYPE = 'type',
  ACTIVE_POSITION = 'activePosition',
  SECTOR = 'sector',
  GEOGRAPHY = 'geography',
  COMPANIES = 'companies',
}

export type TWidgetDataType = REPORT_DATA_SOURCE | REPORT_COMPANY_DETAILS;

export type AggregatedReportWidget = {
  id: string;
  questionId: string;
  widgetType: WIDGET_TYPE;
  questionAnswer?: QuestionOption;
  widgetDataType: TWidgetDataType;
  startYear?: number;
  endYear?: number;
};

export enum CREATE_REPORT_PARAMS {
  REPORT_NAME = 'reportName',
  REPORT_DESCRIPTION = 'reportDescription',
}

export type TBreakdownReportData = {
  questionId: string;
  questionName: string;
  answer: string;
  sourceType: REPORT_DATA_SOURCE;
};

export type TAggregatedReportData = {
  companyName: string;
  companyId: number;
  logoUrl: string;
  breakdownReportData: TBreakdownReportData[];
};

export enum AGGREGATED_REPORT_FILTER {
  DATA_POINTS = 'dataPoints',
  ISSUERS = 'issuers',
  GROUPS = 'groups',
}

export type TReportSurveyData = {
  id: number;
  name: string;
  questions: SurveyQuestion[];
};

export enum ASSESSMENT_WORKSHEET_KEYS {
  QUESTION = 'question',
  ANSWER = 'answer',
  OVERRIDE = 'override',
  OVERRIDDEN_BY = 'overriddenBy',
  EVALUATION_SCORE = 'evaluationScore',
}

export enum AGGREGATED_REPORT_GROUP {
  SECTOR = 'Sector',
  GEOGRAPHY = 'Geography',
}

export type TAggregatedReportGroup = {
  id: AGGREGATED_REPORT_GROUP;
  name: AGGREGATED_REPORT_GROUP;
  order: number;
};

export type AggregatedReportBreakdownGroup = {
  groupCategory: AGGREGATED_REPORT_GROUP;
  groupName: string;
  tableData?: TAggregatedReportData[];
  subgroups?: AggregatedReportBreakdownGroup[];
  groupedCompanies?: Company[];
};

export enum WIDGET_TYPE {
  BAR_CHART = 'barChart',
  PIE_CHART = 'pieChart',
  COMPANY_BREAKDOWN = 'companyBreakdown',
}

export type PerformanceWidgetAnswer = {
  id: string;
  name: string;
  year: number;
  answer: number | string;
};

export type WidgetCompanyAnswer = {
  companyName: string;
  companyId: number;
  companyLogo: string;
  questionId: string;
  sourceType: REPORT_DATA_SOURCE;
  answer: Response | string;
  performanceAnswers?: PerformanceWidgetAnswer[];
};
export type Pagination = {
  total: number;
  page: number;
  perPage: number;
  totalPages: number;
};

export type CompaniesPagination = {
  data: Company[];
  pagination: Pagination;
};

type TSectorFilterOption = {
  id: number;
  name: string;
};

export interface CompanyFilterOptions {
  analysts: string[];
  countries: string[];
  sectors: TSectorFilterOption[];
}

export type CompanyFiltersPayload = {
  type?: string;
  stage?: string;
  sectors?: string;
  countries?: string;
  analysts?: string;
  term?: string;
};

export enum ACTIVITY_TYPE {
  COMPANY_CREATED = 'Company Created',
  COMPANY_INFO_CHANGE = 'Company Info Change',
  NOTE = 'Note',
  ANALYST_RATING_CHANGE = 'Analyst Rating Change',
  GOOD_GOVERNANCE_SCORE = 'Good Governance Score',
  EXCLUSIONS = 'Exclusions',
  ASSESSMENT_SUBMITTED = 'Assessment Submitted',
  CHECKLIST = 'Checklist',
  SURVEY_REQUEST = 'Survey Request',
  KPI_RESPONSE_SUBMITTED = 'KPI Response Submitted',
}

export enum COMPANY_INFO_SUBTYPE {
  ASSIGN_ANALYST = 'Assign Analyst',
  SECTOR_CHANGE = 'Sector Change',
  INDUSTRY_CHANGE = 'Industry Change',
  LOCATION_CHANGE = 'Location Change',
  ASSIGN_ESG_CONTACT = 'Assign ESG Contact',
  STAGE_CHANGE = 'Stage Change',
}

export enum ANALYST_RATING_SUBTYPE {
  ESG_RISK_LEVEL_CHANGE = 'ESG Risk Level Change',
  ESG_OPPORTUNITIES_CHANGE = 'ESG Opportunities Change',
}

export enum EXCLUSION_SUBTYPE {
  NEW_EXCLUSION = 'New Exclusion',
  SATISFIED_EXCLUSION = 'Satisfied Exclusion',
}

export enum CHECKLIST_SUBTYPE {
  CHECKLIST_ITEM_CREATED = 'Checklist Item Created',
  CHECKLIST_ITEM_COMPLETED = 'Checklist Item Completed',
}

export enum ASSESSMENT_SUBMITTED_SUBTYPE {
  ASSESSMENT_SUBMITTED = 'Assessment Submitted',
}

export enum GOOD_GOVERNANCE_SCORE_SUBTYPE {
  GOOD_GOVERNANCE_SCORE = 'Good Governance Score',
}

export enum NOTE_SUBTYPE {
  NOTE_CREATED = 'Note Created',
}

export enum COMPANY_CREATED_SUBTYPE {
  COMPANY_CREATED = 'Company Created',
}

export enum SURVEY_REQUEST_SUBTYPE {
  REQUEST_SENT = 'Request Sent',
  RESPONSE_SUBMITTED = 'Response Submitted',
}

export enum KPI_RESPONSE_SUBMITTED_SUBTYPE {
  KPI_RESPONSE_SUBMITTED = 'KPI Response Submitted',
}

export type ACTIVITY_SUBTYPE =
  | COMPANY_INFO_SUBTYPE
  | ANALYST_RATING_SUBTYPE
  | EXCLUSION_SUBTYPE
  | CHECKLIST_SUBTYPE
  | ASSESSMENT_SUBMITTED_SUBTYPE
  | GOOD_GOVERNANCE_SCORE_SUBTYPE
  | NOTE_SUBTYPE
  | COMPANY_CREATED_SUBTYPE
  | SURVEY_REQUEST_SUBTYPE
  | KPI_RESPONSE_SUBMITTED_SUBTYPE;

export type ActivityValue = {
  previousValue?: string;
  newValue?: string;
};

export type AssessmentValue = {
  assessmentReport?: Report;
};

export type GovernanceScoreValue = {
  governanceScore?: number;
};

export type ExclusionActivityValue = {
  exclusion?: Exclusion;
};

export type ChecklistActivityValue = {
  checklistItem?: ChecklistDataItem;
};

export type SurveyRequestActivityValue = {
  requestGroup?: RequestGroup;
};

export type ResponseValue = {
  report?: Report;
};

export type NoteActivityValue = {
  note?: string;
};

export type CompanyActivityValue = ActivityValue &
  AssessmentValue &
  GovernanceScoreValue &
  ExclusionActivityValue &
  ChecklistActivityValue &
  SurveyRequestActivityValue &
  ResponseValue &
  NoteActivityValue;

export type CompanyActivity = {
  id: number;
  type: ACTIVITY_TYPE;
  subtype?: ACTIVITY_SUBTYPE;
  company: Company;
  user?: User;
  externalUser?: ExternalUser;
  value: CompanyActivityValue;
  created_at: string;
};

export type GroupedActivity = {
  type: ACTIVITY_TYPE;
  created_at: string;
  items: CompanyActivity[];
  users?: (User | ExternalUser | undefined)[];
};

export type ActivityResult = CompanyActivity | GroupedActivity;

export type CompanyActivityPagination = {
  data: CompanyActivity[];
  pagination: Pagination;
};

export type EditSurveyArgs = {
  newName: string;
  description: string;
  includeEvaluation: boolean;
  includeWeight: boolean;
  includeQuestionNumbers: boolean;
  evaluationRules?: EvaluationRule[];
  evaluationScale?: EVALUATION_SCALE;
};

export enum REPORT_DATA_SOURCE {
  ASSESSMENT = 'assessment',
  COMPANY_DETAILS = 'companyDetails',
  EXCLUSIONS = 'exclusions',
  CHECKLIST = 'checklist',
  PERFORMANCE = 'performance',
}

export enum REPORT_COMPANY_DETAILS {
  ESG_RISK_LEVEL = 'esgRiskLevel',
  ESG_OPPORTUNITIES = 'esgOpportunitiesLevel',
  GOVERNANCE_SCORE = 'governanceScore',
}

export enum AGGREGATED_REPORT_PARAMS {
  COMPANY_DETAILS = 'companyDetails',
  ASSESSMENT_IDS = 'assessmentIds',
  COMPANY_IDS = 'companyIds',
}

export type ColumnCategory = {
  id: string | number;
  name: string;
};

export type AggregatedReportColumn = {
  id: string | number;
  name: string;
  sourceType: REPORT_DATA_SOURCE;
  format?: CORE_KPI_FORMAT;
  category?: ColumnCategory;
  assessmentQuestion?: SurveyQuestion;
};

export type AggregatedReportSource = {
  id: string | number;
  type: REPORT_DATA_SOURCE;
  name: string;
  columns: AggregatedReportColumn[];
};

export interface AggregatedReport {
  id: number;
  name: string;
  description?: string;
  sources?: AggregatedReportSource[];
  companies?: Company[];
  selectedColumns?: AggregatedReportColumn[];
  issuerFilters?: Record<AGGREGATED_REPORT_ISSUERS_FILTER, string[] | string> | null;
  created_at: string;
  createdBy?: User;
  groupedBy?: TAggregatedReportGroup[] | null;
  widgets?: AggregatedReportWidget[];
}

export type AggregatedReportAnswerOption = {
  id: string;
  name: string;
  value?: string;
};
