import { styled } from '@mui/material';
import {
  AGGREGATED_REPORTS,
  fetchAggregatedReportById,
  useAggregatedReports,
  useCreateAggregatedReport,
  useDeleteAggregatedReport,
  useUpdateAggregatedReport,
} from '../../queries/useAggregatedReports';
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useReportsColumns } from './useReportsColumns';
import { useCallback, useMemo, useState } from 'react';
import { AggregatedReport } from '../../types';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { Table } from '../../components/Table/Table';
import { AggregatedReportsFilters } from './AggregatedReportsFilters';
import { ReactComponent as DuplicateIcon } from '../../assets/icons/duplicate.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { useToastMessage } from '../../hooks/useToastMessage';
import { AggregatedReportModal } from '../../components/AggregatedReportModal';
import { ConfirmDeleteModal } from '../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { AggregatedReportsSkeletonLoader } from '../../components/SkeletonLoader/AggregatedReports.SkeletonLoader';
import { useQueryClient } from 'react-query';

const Wrapper = styled('div')``;

export const AllAggregatedReports = () => {
  const { data: aggregatedReports, isLoading } = useAggregatedReports();

  const columns = useReportsColumns();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { mutate: createAggregatedReport } = useCreateAggregatedReport();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { mutate: updateAggregatedReport } = useUpdateAggregatedReport();
  const [selectedReport, setSelectedReport] = useState<AggregatedReport | null>(null);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const { mutate: deleteAggregatedReport } = useDeleteAggregatedReport();
  const queryClient = useQueryClient();

  const { pushSuccessToast } = useToastMessage();

  const navigate = useNavigate();

  const table = useReactTable({
    data: aggregatedReports || [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (report) => String(report.id),
  });

  const onRowClick = useCallback(
    (row: Row<AggregatedReport>) => {
      const reportId = row.id;
      navigate(`/${ROUTES.SINGLE_AGGREGATED_REPORT}/${reportId}/`, {
        state: { prevRoute: location.pathname, prevRouteName: 'Reports' },
      });
    },
    [navigate]
  );

  const onCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const onConfirm = ({ name, description }: { name: string; description: string }) => {
    updateAggregatedReport(
      { id: selectedReport?.id, name, description },
      {
        onSuccess: () => {
          queryClient.refetchQueries(`${AGGREGATED_REPORTS}-${selectedReport?.id}`);
        },
      }
    );
    setIsEditModalOpen(false);
  };

  const actions = useMemo(() => {
    return [
      {
        id: 'EDIT',
        value: 'Edit',
        icon: <EditIcon />,
      },
      {
        id: 'DUPLICATE',
        value: 'Duplicate',
        icon: <DuplicateIcon />,
      },

      {
        id: 'DELETE',
        value: 'Delete',
        icon: <DeleteIcon />,
      },
    ];
  }, []);

  const duplicateReport = useCallback(
    async (selectedReportId: number) => {
      const selectedReport = await fetchAggregatedReportById(selectedReportId);

      createAggregatedReport(
        {
          name: `${selectedReport.name} copy`,
          description: selectedReport.description,
          sources: selectedReport.sources,
          selectedColumns: selectedReport.selectedColumns,
          issuerFilters: selectedReport.issuerFilters,
          createdBy: selectedReport.createdBy?.id,
          groupedBy: selectedReport.groupedBy,
          widgets: selectedReport.widgets,
          companies: selectedReport.companies?.map((company) => company.id),
        },
        {
          onSuccess: () => {
            pushSuccessToast({
              message: `Report ${selectedReport.name} was duplicated successfully`,
            });
          },
        }
      );
    },
    [createAggregatedReport, pushSuccessToast]
  );

  const handleSelectAction = useCallback(
    async (actionId: string, row: Row<AggregatedReport> | null) => {
      if (!row) return;
      switch (actionId) {
        case 'EDIT':
          setIsEditModalOpen(true);
          setSelectedReport(row.original);
          return;
        case 'DUPLICATE':
          duplicateReport(row.original.id);
          return;
        case 'DELETE':
          setIsConfirmDeleteModalOpen(true);
          setSelectedReport(row.original);
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [duplicateReport]
  );

  const handleDeleteReport = useCallback(() => {
    if (!selectedReport) return;
    setIsConfirmDeleteModalOpen(false);
    deleteAggregatedReport(selectedReport.id);
  }, [selectedReport, deleteAggregatedReport]);

  if (isLoading) return <AggregatedReportsSkeletonLoader />;

  return (
    <Wrapper>
      <AggregatedReportsFilters table={table} aggregatedReports={aggregatedReports} />
      <Table
        table={table}
        onRowClick={onRowClick}
        actions={actions}
        onActionClick={handleSelectAction}
      />
      {isEditModalOpen && (
        <AggregatedReportModal
          onClose={onCloseModal}
          onConfirm={onConfirm}
          title='Edit Report'
          confirmBtnText='Confirm'
          reportName={selectedReport?.name || ''}
          reportDescription={selectedReport?.description || ''}
        />
      )}
      <ConfirmDeleteModal
        onClose={() => setIsConfirmDeleteModalOpen(false)}
        onConfirm={handleDeleteReport}
        title={`Delete ${selectedReport?.name}?`}
        confirmLabel='Yes, Delete'
        cancelLabel='No, Cancel'
        isOpen={isConfirmDeleteModalOpen}
      />
    </Wrapper>
  );
};
