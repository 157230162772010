import { styled, useTheme } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/icons/starcier.svg';
import { Typography } from '../Typography/Typography';

const LogoWrapper = styled('div')`
  gap: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const EsgCompassLogo = () => {
  const { colors } = useTheme();
  return (
    <LogoWrapper>
      <Logo style={{ transform: 'scale(0.9)' }} />
      <Typography
        color={colors.primary[90]}
        variant='tabLabel'
        style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}
      >
        Compass
      </Typography>
    </LogoWrapper>
  );
};
