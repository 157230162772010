import { styled } from '@mui/material';
import { useParams } from 'react-router';
import { ReportDataStep } from './ReportDataStep/ReportDataStep';
import { CompaniesStep } from './CompaniesStep/CompaniesStep';

const Wrapper = styled('div')`
  padding: 24px 40px;
`;

export const StepContent = () => {
  const params = useParams();
  const activeStep = Number(params.activeStep);
  return (
    <Wrapper>
      {activeStep === 1 && <ReportDataStep />}
      {activeStep === 2 && <CompaniesStep />}
    </Wrapper>
  );
};
