import { styled } from '@mui/material';
import { Multiselect } from '../../../../../components/Multiselect/Multiselect';
import { useUsers } from '../../../../../queries/useUsers';
import { Dispatch, useMemo } from 'react';
import { UserAvatar } from '../../../../../components/UserAvatar/UserAvatar';
import {
  ACTIVITY_SUBTYPE,
  ANALYST_RATING_SUBTYPE,
  ASSESSMENT_SUBMITTED_SUBTYPE,
  CHECKLIST_SUBTYPE,
  COMPANY_CREATED_SUBTYPE,
  COMPANY_INFO_SUBTYPE,
  EXCLUSION_SUBTYPE,
  GOOD_GOVERNANCE_SCORE_SUBTYPE,
  KPI_RESPONSE_SUBMITTED_SUBTYPE,
  NOTE_SUBTYPE,
  SelectItem,
  SURVEY_REQUEST_SUBTYPE,
} from '../../../../../types';
import { SetStateAction } from 'jotai';

const Wrapper = styled('div')`
  margin-top: 16px;
`;

const FiltersWrapper = styled('div')`
  width: 100%;
  display: flex;
  gap: 8px;
  margin-top: 7px;
`;

interface Props {
  selectedUsers?: SelectItem[];
  setSelectedUsers: Dispatch<SetStateAction<SelectItem[]>>;
  selectedActivities?: SelectItem[];
  setSelectedActivities: Dispatch<SetStateAction<SelectItem[]>>;
}

const activitySubtypes: ACTIVITY_SUBTYPE[] = [
  COMPANY_INFO_SUBTYPE.ASSIGN_ANALYST,
  COMPANY_INFO_SUBTYPE.SECTOR_CHANGE,
  COMPANY_INFO_SUBTYPE.INDUSTRY_CHANGE,
  COMPANY_INFO_SUBTYPE.LOCATION_CHANGE,
  COMPANY_INFO_SUBTYPE.ASSIGN_ESG_CONTACT,
  COMPANY_INFO_SUBTYPE.STAGE_CHANGE,
  ANALYST_RATING_SUBTYPE.ESG_OPPORTUNITIES_CHANGE,
  ANALYST_RATING_SUBTYPE.ESG_RISK_LEVEL_CHANGE,
  EXCLUSION_SUBTYPE.NEW_EXCLUSION,
  EXCLUSION_SUBTYPE.SATISFIED_EXCLUSION,
  CHECKLIST_SUBTYPE.CHECKLIST_ITEM_COMPLETED,
  CHECKLIST_SUBTYPE.CHECKLIST_ITEM_CREATED,
  ASSESSMENT_SUBMITTED_SUBTYPE.ASSESSMENT_SUBMITTED,
  GOOD_GOVERNANCE_SCORE_SUBTYPE.GOOD_GOVERNANCE_SCORE,
  NOTE_SUBTYPE.NOTE_CREATED,
  COMPANY_CREATED_SUBTYPE.COMPANY_CREATED,
  SURVEY_REQUEST_SUBTYPE.REQUEST_SENT,
  SURVEY_REQUEST_SUBTYPE.RESPONSE_SUBMITTED,
  KPI_RESPONSE_SUBMITTED_SUBTYPE.KPI_RESPONSE_SUBMITTED,
];

const activitiesOptions = activitySubtypes?.map((subtype, i) => ({
  id: i,
  value: subtype,
}));

export const ActivityFilters = ({
  selectedUsers,
  setSelectedUsers,
  selectedActivities,
  setSelectedActivities,
}: Props) => {
  const { data: users } = useUsers();

  const userOptions = useMemo(() => {
    return (
      users?.map((u) => ({
        id: u?.id,
        value: u?.fullName,
        icon: <UserAvatar size='medium' name={u?.fullName} />,
      })) || []
    );
  }, [users]);

  return (
    <Wrapper>
      <FiltersWrapper>
        <Multiselect
          containerStyles={{ flex: 1 }}
          style={{ width: '100%' }}
          options={userOptions}
          value={selectedUsers || []}
          onChange={(_, val) => setSelectedUsers(val as SelectItem[])}
          disablePortal
          optionsName='Persons'
          fieldPlaceholder={'Filter by Persons'}
          selectedTextMaxWidth='9rem'
        />
        <Multiselect
          containerStyles={{ flex: 1 }}
          style={{ width: '100%' }}
          options={activitiesOptions}
          value={selectedActivities || []}
          onChange={(_, val) => setSelectedActivities(val as SelectItem[])}
          disablePortal
          optionsName='Events'
          fieldPlaceholder={'Filter by Event'}
          selectedTextMaxWidth='9rem'
        />
      </FiltersWrapper>
    </Wrapper>
  );
};
