import { styled } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router';
import { useCompanyById } from '../../queries/useCompanies';
import { useInitKpiMatrix } from './hooks/useInitKpiMatrix';
// import { useSaveExternallyExclusions } from './hooks/useSaveExternallyExclusions';
import { COMPANY_STAGE, COMPANY_TYPE } from '../../types';
import { ScreeningFlow } from '../../components/ScreeningFlow/ScreeningFlow';
import { useMemo } from 'react';
import { ControlledCompany } from './ControlledCompany';
import { NotControlledCompany } from './NotControlledCompany';
import { CompanyPageSkeletonLoader } from '../../components/SkeletonLoader/CompanyPage.SkeletonLoader';
import { SingleCompanyHeader } from '../../components/SingleCompanyHeader';
import { ROUTES } from '../../constants/routes';
import { AssessmentsTable } from './components/AssessmentsTable';
import { ResponsesTable } from './components/ResponsesTable';
import { useIsInScreeningFlow } from './hooks/useIsInScreeningFlow';
import { useCompanyReports } from './hooks/useCompanyReports';
import { useWorkflowsByCompanyType } from '../../queries/useWorkflows';

const Wrapper = styled('div')`
  overflow: hidden;
`;

export const SingleCompany = () => {
  const params = useParams();
  const { isLoading: isLoadingCompany, data: company } = useCompanyById(Number(params.id));
  const { isLoading: isLoadingKpiMatrixData } = useInitKpiMatrix(Number(params.id));
  const { isLoading: isWorkflowLoading, data: workflow } = useWorkflowsByCompanyType(company?.type);

  // useSaveExternallyExclusions();

  const isInitial = company?.stage === COMPANY_STAGE.SCREENING;

  const isInScreeningFlow = useIsInScreeningFlow();

  const { assessmentReports, nonAssessmentReports } = useCompanyReports();

  const companySections = useMemo(() => {
    if (isInScreeningFlow)
      return (
        <ScreeningFlow
          stages={workflow?.config?.stages || []}
          companyType={company?.type || COMPANY_TYPE.CONTROLLED}
        />
      );

    if (company?.type === COMPANY_TYPE.CONTROLLED) {
      return <ControlledCompany />;
    }

    return <NotControlledCompany />;
  }, [company?.type, isInScreeningFlow, workflow?.config?.stages]);

  if (!company) return null;

  if (isLoadingCompany || isLoadingKpiMatrixData || isWorkflowLoading)
    return <CompanyPageSkeletonLoader />;

  return (
    <AnimatePresence>
      <Wrapper>
        <SingleCompanyHeader company={company} />
        {companySections}
        {Boolean(
          assessmentReports?.length &&
            !isInitial &&
            params.activeTab !== ROUTES.COMPANY_PERFORMANCE &&
            params.activeTab !== ROUTES.COMPANY_CHECKLIST
        ) && <AssessmentsTable reports={assessmentReports || []} />}
        {Boolean(
          nonAssessmentReports?.length &&
            params.activeTab !== ROUTES.COMPANY_PERFORMANCE &&
            params.activeTab !== ROUTES.COMPANY_CHECKLIST
        ) && <ResponsesTable reports={nonAssessmentReports || []} />}
      </Wrapper>
    </AnimatePresence>
  );
};
