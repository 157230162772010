import { ACTIVITY_TYPE } from '../../../../../../../../../../types';

export const getActivityGroupTitle = (type: ACTIVITY_TYPE) => {
  if (type === ACTIVITY_TYPE.COMPANY_INFO_CHANGE) {
    return 'Company details changed';
  }

  if (type === ACTIVITY_TYPE.ANALYST_RATING_CHANGE) {
    return 'Score changed';
  }

  if (type === ACTIVITY_TYPE.EXCLUSIONS) {
    return 'Exclusions changed';
  }

  if (type === ACTIVITY_TYPE.ASSESSMENT_SUBMITTED) {
    return 'Assessment submitted';
  }

  if (type === ACTIVITY_TYPE.CHECKLIST) {
    return 'Checklist item changed';
  }

  if (type === ACTIVITY_TYPE.KPI_RESPONSE_SUBMITTED) {
    return 'KPI Collection response submitted ';
  }

  if (type === ACTIVITY_TYPE.SURVEY_REQUEST) {
    return 'Request changed';
  }
  if (type === ACTIVITY_TYPE.NOTE) {
    return 'Note added ';
  }

  if (type === ACTIVITY_TYPE.GOOD_GOVERNANCE_SCORE) {
    return 'Governance score published';
  }
};
