import { useCallback, useMemo } from 'react';
import { useUpdateCompany } from '../../queries/useCompanies';
import { useAtom, useAtomValue } from 'jotai';
import { ACTIVITY_TYPE, Exclusion, EXCLUSION_SUBTYPE, QuestionOption, User } from '../../types';
import { useInvalidateExclusionsByCompany } from '../../queries/useExclusionsByCompany';
import { activeReportState, reviewState, userState } from '../../state/UIState';
import { useExclusionCategories } from '../../queries/useExclusionCategories';
import { useCreateCompanyActivities } from '../../queries/useCompanyActivity';

export const useUpdateCompanyExclusions = () => {
  const updateCompany = useUpdateCompany();
  const [report] = useAtom(activeReportState);
  const [review] = useAtom(reviewState);
  const user = useAtomValue(userState) as User;
  const { data: exclusionCategories } = useExclusionCategories();
  const { mutateAsync: createCompanyActivities } = useCreateCompanyActivities(report?.company?.id);

  const allActiveExclusions = useMemo(() => {
    if (!exclusionCategories) return [];
    return (
      exclusionCategories
        .flatMap((category) => category.conditions.flatMap((condition) => condition.exclusions))
        ?.filter((exclusion) => exclusion?.isActive) || []
    );
  }, [exclusionCategories]);

  const invalidateExclusionsByCompany = useInvalidateExclusionsByCompany();

  const updateCompanyExclusions = useCallback(async () => {
    if (!report || !user) return;
    const { response, survey } = report;

    const exclusionIds: number[] = [];

    survey.sections.forEach((section) => {
      section.questions.forEach((question) => {
        const validResponse = review?.[question?.id]?.overrideResponse || response[question.id];
        if ((validResponse as QuestionOption)?.name === 'Yes') {
          exclusionIds.push(Number(question.id));
        }
      });
    });

    const exclusions = exclusionIds
      .map((exclusionId) => {
        const exclusion = allActiveExclusions?.find((exclusion) => exclusion?.id === exclusionId);
        return exclusion;
      })
      ?.filter(Boolean) as Exclusion[];

    await updateCompany.mutateAsync({
      id: report.company.id,
      exclusions: exclusionIds,
      violatedExclusionsIds: exclusionIds,
    });

    const companyActivities = exclusions.map((exclusion) => ({
      type: ACTIVITY_TYPE.EXCLUSIONS,
      subtype: EXCLUSION_SUBTYPE.NEW_EXCLUSION,
      value: {
        exclusion,
      },
      company: report.company?.id,
      user: user?.id,
    }));

    await createCompanyActivities({ data: companyActivities });

    invalidateExclusionsByCompany(report.company.id);
  }, [
    allActiveExclusions,
    createCompanyActivities,
    invalidateExclusionsByCompany,
    report,
    review,
    updateCompany,
    user,
  ]);

  return { updateCompanyExclusions };
};
