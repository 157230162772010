import { ReactComponent as HighGreen } from '../assets/icons/high-green.svg';
import { ReactComponent as LowRed } from '../assets/icons/low-red.svg';

export const getIsGoodScore = (governanceScore?: number | null) => {
  return (governanceScore || 0) < 5;
};

export const showGovernanceScore = (governanceScore?: number | null) =>
  governanceScore !== undefined && governanceScore !== null;

export const getGovernanceScoreLabel = (governanceScore?: number | null) => {
  const isGoodScore = getIsGoodScore(governanceScore);

  if (governanceScore === undefined) return null;

  const label = isGoodScore ? 'Good' : 'Bad';

  return `${label} (${governanceScore})`;
};

export const getGovernanceScoreIcon = (governanceScore?: number | null) => {
  const isGoodScore = getIsGoodScore(governanceScore);

  return isGoodScore ? HighGreen : LowRed;
};
