import { EsgOpportunitiesIcon } from '../../../../../../../../../components/EsgOpportunitiesIcon';
import { EsgRiskIcon } from '../../../../../../../../../components/EsgRiskIcon';
import { ANALYST_RATING_SUBTYPE, CompanyActivity, RISK_LEVEL } from '../../../../../../../../../types';
import { ComparedValues } from './components/ComparedValues';

interface Props {
  activity: CompanyActivity;
}

const getAnalystRatingIcon = (subType: ANALYST_RATING_SUBTYPE, value: RISK_LEVEL) => {
  if (subType === ANALYST_RATING_SUBTYPE.ESG_OPPORTUNITIES_CHANGE) {
    return <EsgOpportunitiesIcon esgOpportunitiesLevel={value} />;
  }

  if (subType === ANALYST_RATING_SUBTYPE.ESG_RISK_LEVEL_CHANGE) {
    return <EsgRiskIcon esgRiskLevel={value} />;
  }

  return null;
};

export const AnalystRatingContent = ({ activity }: Props) => {
  return (
    <ComparedValues
      comparedValuesConfig={[
        {
          value: activity.value?.previousValue,
          icon: getAnalystRatingIcon(
            activity.subtype as ANALYST_RATING_SUBTYPE,
            activity.value?.previousValue as RISK_LEVEL
          ),
        },
        {
          value: activity.value?.newValue,
          icon: getAnalystRatingIcon(
            activity.subtype as ANALYST_RATING_SUBTYPE,
            activity.value?.newValue as RISK_LEVEL
          ),
        },
      ]}
    />
  );
};
