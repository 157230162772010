import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { userState } from '../state/UIState';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { SingleAssessmentQuestionRow, User } from '../types';
import { getFileName } from '../utils/getFileName';
import { formatAssessmentWorksheet } from '../utils/formatAssessmentWorksheet';

export const useExportSingleAssessment = () => {
  const user = useAtomValue(userState) as User;

  const onExportAssessment = useCallback(
    async ({
      data,
      surveyName,
      showOverrideColumn,
      hasEvaluation,
    }: {
      data: SingleAssessmentQuestionRow[];
      surveyName: string;
      showOverrideColumn?: boolean;
      hasEvaluation?: boolean;
    }) => {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = user?.fullName || '';
      workbook.created = new Date();
      workbook.properties.date1904 = true;

      const worksheet = workbook.addWorksheet(surveyName);

      formatAssessmentWorksheet({ worksheet, showOverrideColumn, hasEvaluation, exportData: data });

      const buffer = await workbook.xlsx.writeBuffer();
      FileSaver.saveAs(new Blob([buffer]), `${getFileName(surveyName)}.xlsx`);
    },
    [user?.fullName]
  );

  return onExportAssessment;
};
