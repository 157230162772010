import { styled, useTheme } from '@mui/material';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { Typography } from '../../../components/Typography/Typography';
import { useAtom } from 'jotai';
import { selectedCompanyExclusionsState, selectedConditionState } from '../../../state/UIState';
import { useCompanyExclusions } from '../../SIngleCompany/hooks/useCompanyExclusions';
import { useMemo } from 'react';

const Container = styled('div')`
  display: flex;
  margin-top: 16px;
  align-items: center;
  gap: 8px;
  margin-left: 16px;
`;

export const CheckAll = () => {
  const { colors } = useTheme();
  const [selectedCompanyExclusions, setSelectedCompanyExclusions] = useAtom(
    selectedCompanyExclusionsState
  );
  const [selectedCondition] = useAtom(selectedConditionState);
  const { data: companyExclusions } = useCompanyExclusions();

  const selectableExclusions = useMemo(() => {
    const existingCompanyExclusionIds = companyExclusions?.map((exclusion) => exclusion.id) || [];
    return (
      selectedCondition?.exclusions?.filter(
        (exclusion) => !existingCompanyExclusionIds.includes(exclusion.id)
      ) || []
    );
  }, [companyExclusions, selectedCondition?.exclusions]);

  const companyExclusionIds = useMemo(
    () => selectedCompanyExclusions?.map((exclusion) => exclusion.id) || [],
    [selectedCompanyExclusions]
  );

  const areAllSelected = useMemo(() => {
    return Boolean(
      selectableExclusions?.filter((exclusion) => companyExclusionIds?.includes(exclusion.id))
        ?.length
    );
  }, [companyExclusionIds, selectableExclusions]);

  const onToggleSelectAll = () => {
    const selectableExclusionIds = selectableExclusions?.map((exclusion) => exclusion.id);
    if (areAllSelected) {
      setSelectedCompanyExclusions((prev) =>
        prev.filter((prevExclusion) => !selectableExclusionIds?.includes(prevExclusion.id))
      );
      return;
    }

    setSelectedCompanyExclusions((prev) => [...prev, ...selectableExclusions]);
  };

  return (
    <Container onClick={onToggleSelectAll}>
      <Checkbox
        checked={areAllSelected}
        style={{ padding: 0 }}
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 24,
          },
        }}
      />
      <Typography
        variant='overline'
        color={colors.primary[60]}
        style={{ textTransform: 'uppercase' }}
      >
        Exclusion
      </Typography>
    </Container>
  );
};
