import ContentLoader from 'react-content-loader';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')`
  display: flex;
  flex: 1;
`;

export function AggregatedReportsAssessmentStepSkeletonLoader(): ReactElement {
  return (
    <SkeletonWrapper>
      <ContentLoader height={830} width={'100%'}>
        <rect width='300' height='40' y='20' rx='4' fill='#EEEFF1' />
        <rect width='250' height='30' y='100' rx='4' fill='#EEEFF1' />
        <rect width='320' height='20' y='136' rx='4' fill='#EEEFF1' />

        <rect y='164' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='205' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='246' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='287' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='328' width='100%' height='38' rx='4' fill='#EEEFF1' />

        <rect width='250' height='30' y='396' rx='4' fill='#EEEFF1' />
        <rect width='320' height='20' y='432' rx='4' fill='#EEEFF1' />

        <rect y='460' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='501' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='542' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='583' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='624' width='100%' height='38' rx='4' fill='#EEEFF1' />
    
      </ContentLoader>
 
    </SkeletonWrapper>
  );
}
