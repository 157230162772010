import { Fade, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../components/Typography/Typography';
import { AggregatedReportWidget } from '../../../../../../types';
import { useWidgetQuestion } from '../hooks/useWidgetQuestion';
import { useCompaniesAnswers } from '../hooks/useCompaniesAnswers';
import { ActionMenu } from './ActionMenu';
import { CSSProperties, ReactNode, useCallback, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as DragIcon } from '../../../../../../assets/icons/drag.svg';
import { Tooltip } from '../../../../../../components/Tooltip/Tooltip';

const Wrapper = styled('div')<{ width?: string }>`
  padding: 17px 24px;
  background-color: #fff;
  width: ${({ width }) => width || '100%'};
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const Header = styled('div')`
  display: flex;
  gap: 8px;
`;

const TitleWrapper = styled('div')`
  display: flex;
  margin-left: 16px;
  gap: 8px;
`;

const WidgetHeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const TextWrapper = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const DragIconWrapper = styled('div')`
  position: absolute;
  top: 17px;
  left: 24px;
  width: 8px;
`;

interface Props {
  widget: AggregatedReportWidget;
  children: ReactNode;
  containerWidth?: string;
  titleContainerStyles?: CSSProperties;
}

export const WidgetCard = ({ widget, children, containerWidth, titleContainerStyles }: Props) => {
  const { colors } = useTheme();
  const widgetQuestion = useWidgetQuestion(widget);
  const { numOfCompaniesAnswers } = useCompaniesAnswers(widget);
  const [showDragIcon, setShowDragIcon] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: widget.id,
  });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const onMouseEnter = useCallback(() => {
    setShowDragIcon(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setShowDragIcon(false);
  }, []);

  return (
    <Wrapper
      width={containerWidth}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        ...style,
        opacity: isDragging ? 0.5 : 1,
        zIndex: isDragging ? 2 : 1,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <WidgetHeaderWrapper>
        <Header>
          {showDragIcon && (
            <DragIconWrapper>
              <DragIcon />
            </DragIconWrapper>
          )}
          <TitleWrapper style={titleContainerStyles}>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={widgetQuestion?.name}
            >
              <TextWrapper>
                <Typography variant='subtitle2' color={colors.primary[90]}>
                  {widgetQuestion?.name}
                </Typography>
              </TextWrapper>
            </Tooltip>
            <Typography variant='body' color={colors.primary[70]}>
              {numOfCompaniesAnswers} issuers
            </Typography>
          </TitleWrapper>
        </Header>
        <ActionMenu widget={widget} />
      </WidgetHeaderWrapper>
      {children}
    </Wrapper>
  );
};
