import { Outlet, useNavigate, useParams } from 'react-router';
import { useCallback, useMemo } from 'react';
import { ROUTES } from '../../constants/routes';
import { ControlButtons } from './components/ControlButtons';
import { useAtomValue } from 'jotai';
import { createReportCompletedProgressState } from '../../state/UIState';
import { StepperLayout } from '../../components/StepperLayout/StepperLayout';
import { useQueryParams } from '../../hooks/useQueryParams';

export const CreateAggregatedReportStepper = () => {
  const navigate = useNavigate();
  const params = useParams();

  const progress = useAtomValue(createReportCompletedProgressState);
  const activeStep = Number(params.activeStep);
  const { navigateAndPreserveParams } = useQueryParams();

  const onClick = useCallback(() => {
    navigate(`/${ROUTES.AGGREGATED_REPORTS}`);
  }, [navigate]);

  const progressBarText =
    progress < 99
      ? 'Complete all steps to create the request.'
      : 'Congrats! All steps are completed.';

  const steps = useMemo(() => {
    return [
      {
        step: 1,
        text: 'Report Content',
        isClickable: true,
      },
      {
        step: 2,
        text: 'Companies',
        isClickable: true,
      },
    ];
  }, []);

  const onStepClick = (step: number) => {
    if (step === 1) {
      navigateAndPreserveParams(`/${ROUTES.CREATE_AGGREGATED_REPORT}/${step}`);
    }
  };

  return (
    <StepperLayout
      onBackClick={onClick}
      headerTitle='New Report'
      progressBarText={progressBarText}
      progress={progress}
      steps={steps}
      activeStep={activeStep}
      onClickStepNavigate={onStepClick}
      controlButtons={<ControlButtons />}
    >
      <Outlet />
    </StepperLayout>
  );
};
