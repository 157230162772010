import { useMemo, useState } from 'react';
import { Accordion } from '../../../../../components/Accordion/Accordion';
import { AggregatedReportColumn, AggregatedReportSource } from '../../../../../types';
import { styled } from '@mui/system';
import { ColumnSelectItem } from './ColumnSelectItem';
import { Button, useTheme } from '@mui/material';
import { Typography } from '../../../../../components/Typography/Typography';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../state/UIState';

interface Props {
  source: AggregatedReportSource;
}

type DataPointCategory = {
  id: string | number;
  name: string;
  columns: AggregatedReportColumn[];
};

const Wrapper = styled('div')`
  /* padding: 20px 0; */
`;

export const DataPointsSubList = ({ source }: Props) => {
  const [expanded, setExpanded] = useState<string | null>(null);
  const { colors } = useTheme();
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const sourceCategories: DataPointCategory[] = useMemo(() => {
    return source?.columns?.reduce((acc: DataPointCategory[], column) => {
      const category = acc.find((cat) => cat.id === column.category?.id);
      if (category) {
        category.columns.push(column);
      } else {
        acc.push({
          id: column.category?.id || 0,
          name: column.category?.name || '',
          columns: [column],
        });
      }
      return acc;
    }, []);
  }, [source?.columns]);

  const onToggleExpanded = (categoryId: string) => {
    if (expanded === categoryId) {
      setExpanded(null);
      return;
    }
    setExpanded(categoryId);
  };

  const getIsAllSelected = (category: DataPointCategory) => {
    const allSelectedColumnsIds = aggregatedReport?.selectedColumns?.map((c) => c.id);
    const isAllSelected = category?.columns?.every((c) => allSelectedColumnsIds?.includes(c.id));
    return isAllSelected;
  };

  const onToggleSelectAll = (category: DataPointCategory) => {
    if (!aggregatedReport) return;
    const isAllSelected = getIsAllSelected(category);
    const columnsIds = category?.columns?.map((c) => c.id);

    if (isAllSelected) {
      const updatedSelectedQuestions = aggregatedReport?.selectedColumns?.filter(
        (col) => !columnsIds?.includes(col.id)
      );

      setAggregatedReport({ ...aggregatedReport, selectedColumns: updatedSelectedQuestions });

      return;
    }

    const updatedSelectedQuestions = aggregatedReport?.selectedColumns
      ? [...aggregatedReport.selectedColumns, ...category.columns]
      : [];

    setAggregatedReport({ ...aggregatedReport, selectedColumns: updatedSelectedQuestions });
  };

  return (
    <Wrapper>
      {sourceCategories?.map((category) => {
        const isAllSelected = getIsAllSelected(category);
        return (
          <Accordion
            key={category.id}
            title={category.name}
            isExpanded={expanded === category.id}
            onExpandToggle={() => onToggleExpanded(String(category.id))}
            suffixButton={
              <Button variant='text' onClick={() => onToggleSelectAll(category)}>
                <Typography variant='subtitle2' color={colors.accent[50]}>
                  {isAllSelected ? 'Deselect All' : 'Select All'}
                </Typography>
              </Button>
            }
          >
            {category.columns?.map((column) => (
              <ColumnSelectItem key={column.id} column={column} />
            ))}
          </Accordion>
        );
      })}
    </Wrapper>
  );
};
