import { Button, styled } from '@mui/material';
import { EditableTextFieldV2 } from '../../../../../components/EditableTextField/EditableTextFieldV2';
import { useCallback, useState } from 'react';
import { useAtomValue } from 'jotai';
import { userState } from '../../../../../state/UIState';
import { useCreateCompanyActivity } from '../../../../../queries/useCompanyActivity';
import { useParams } from 'react-router';
import { ACTIVITY_TYPE, NOTE_SUBTYPE, User } from '../../../../../types';

const Wrapper = styled('div')`
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AddNote = () => {
  const params = useParams();
  const [inputValue, setInputValue] = useState('');
  const user = useAtomValue(userState) as User;
  const { mutate: createActivity } = useCreateCompanyActivity(Number(params.id));

  const onCreateNote = useCallback(() => {
    if (!inputValue || !user) return;

    createActivity({
      type: ACTIVITY_TYPE.NOTE,
      subtype: NOTE_SUBTYPE.NOTE_CREATED,
      company: Number(params.id),
      user: user.id,
      value: {
        note: inputValue,
      },
    });
    setInputValue('');
  }, [createActivity, inputValue, params.id, user]);

  return (
    <Wrapper>
      <EditableTextFieldV2
        value={inputValue}
        onChange={(e) => {
          const val = e.target.value;

          setInputValue(val);
        }}
        placeholder={'Your note'}
        onValueChanged={onCreateNote}
        wrapperStyle={{ height: '30px', width: 'auto', flex: 1 }}
        withBorder
      />
      <Button variant='contained' onClick={() => ''} style={{ width: '90px', height: '30px' }}>
        Add Note
      </Button>
    </Wrapper>
  );
};
