import { styled, useTheme } from '@mui/material';
import { useMemo, useRef } from 'react';
import { ReactComponent as YesIcon } from '../../../assets/icons/yes-answer.svg';
import { ReactComponent as NoIcon } from '../../../assets/icons/no-answer.svg';
import { ReactComponent as BulletIcon } from '../../../assets/icons/bullet-answer.svg';
import { Typography } from '../../Typography/Typography';
import { ReactComponent as FlagIcon } from '../../../assets/icons/flag-red.svg';
import { NotesBoxPopup } from '../../NotesBoxPopup/NotesBoxPopup';

const OptionWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const OptionTextContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 2px;
`;

const OptionTextWrapper = styled('div')<{ isFlagged?: boolean }>`
  background: ${({ theme, isFlagged }) => (isFlagged ? theme.colors.error[5] : 'transparent')};
  flex: 1;
  padding: 2px 4px;
`;

const TextWrapper = styled('div')`
  position: relative;
`;

interface OptionProps {
  text: string;
  isFlagged?: boolean;
  notes?: string;
  answerId?: string;
  isOverridden?: boolean;
  isOverrideAnswer?: boolean;
  overrideReason?: string;
}

export const AnswerOption = ({
  text,
  isFlagged,
  notes,
  isOverridden,
  isOverrideAnswer,
  overrideReason,
}: OptionProps) => {
  const { colors } = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  const Icon = useMemo(() => {
    if (text?.toLowerCase() === 'yes') return YesIcon;
    if (text?.toLowerCase() === 'no') return NoIcon;
    return BulletIcon;
  }, [text]);

  const backgroundColor = useMemo(() => {
    if (notes) return colors.warning[20];
    if (isOverridden) return colors.primary[10];

    return 'transparent';
  }, [colors.primary, colors.warning, isOverridden, notes]);

  const optionWrapperBackground = useMemo(() => {
    if (notes) return colors.warning[20];
    if (isOverrideAnswer && Boolean(overrideReason)) return '#CAF6FB';
    return 'transparent';
  }, [colors.warning, isOverrideAnswer, notes, overrideReason]);

  return (
    <OptionWrapper ref={containerRef}>
      <OptionTextContainer
        style={{
          background: optionWrapperBackground,
        }}
      >
        {!isOverridden && <Icon />}
        <OptionTextWrapper isFlagged={isFlagged}>
          <TextWrapper>
            <NotesBoxPopup
              notes={notes || overrideReason || ''}
              popupPlacement='bottom-start'
              containerStyle={{ display: 'inline' }}
              popupOffset={{ mainAxis: 8 }}
              popupStrategy='fixed'
            >
              <Typography
                variant='body'
                color={colors.primary[90]}
                style={{
                  background: backgroundColor,
                  display: 'inline',
                  cursor: notes ? 'pointer' : 'auto',
                  textDecoration: isOverridden ? 'line-through' : 'none',
                }}
              >
                {text}
              </Typography>
            </NotesBoxPopup>
          </TextWrapper>
        </OptionTextWrapper>
      </OptionTextContainer>
      {isFlagged && <FlagIcon />}
    </OptionWrapper>
  );
};
