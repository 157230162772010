import { styled, useTheme } from '@mui/material';
import { EXCLUSION_STATUS } from '../types';
import { useMemo } from 'react';
import { ReactComponent as SuccessIcon } from '../assets/icons/success-green-round-small.svg';
import { ReactComponent as DangerIcon } from '../assets/icons/danger-red-round-small.svg';
import { Typography } from './Typography/Typography';

const StatusWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ExclusionStatus = ({ status }: { status: EXCLUSION_STATUS }) => {
  const { colors } = useTheme();
  const Icon = useMemo(() => {
    if (status === EXCLUSION_STATUS.VIOLATED) return <DangerIcon />;
    if (status === EXCLUSION_STATUS.SATISFIED) return <SuccessIcon />;
    return null;
  }, [status]);
  return (
    <StatusWrapper>
      {Icon}
      <Typography variant='body' color={colors.primary[90]}>
        {status}
      </Typography>
    </StatusWrapper>
  );
};
