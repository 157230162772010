import {
  GOVERNANCE_SCORE_TYPE,
  REPORT_COMPANY_DETAILS,
  RISK_LEVEL,
  TWidgetDataType,
} from '../../../../../../types';

export const getCompanyDetailsAnswerOptions = (dataType: TWidgetDataType) => {
  if (
    dataType === REPORT_COMPANY_DETAILS.ESG_OPPORTUNITIES ||
    dataType === REPORT_COMPANY_DETAILS.ESG_RISK_LEVEL
  ) {
    return [
      {
        id: RISK_LEVEL.HIGH,
        value: RISK_LEVEL.HIGH,
        name: RISK_LEVEL.HIGH,
      },
      {
        id: RISK_LEVEL.MEDIUM,
        value: RISK_LEVEL.MEDIUM,
        name: RISK_LEVEL.MEDIUM,
      },
      {
        id: RISK_LEVEL.LOW,
        value: RISK_LEVEL.LOW,
        name: RISK_LEVEL.LOW,
      },
    ];
  }

  if (dataType === REPORT_COMPANY_DETAILS.GOVERNANCE_SCORE) {
    return [
      {
        id: GOVERNANCE_SCORE_TYPE.GOOD,
        value: GOVERNANCE_SCORE_TYPE.GOOD,
        name: GOVERNANCE_SCORE_TYPE.GOOD,
      },
      {
        id: GOVERNANCE_SCORE_TYPE.BAD,
        value: GOVERNANCE_SCORE_TYPE.BAD,
        name: GOVERNANCE_SCORE_TYPE.BAD,
      },
    ];
  }

  return [];
};
