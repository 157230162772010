import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../../../../../components/Typography/Typography';
import { ReactComponent as RightArrow } from '../../../../../../../../../../assets/icons/right-arrow.svg';
import { useMemo } from 'react';

type ComparedValueItem = {
  icon?: JSX.Element | null;
  value?: string;
};

interface Props {
  comparedValuesConfig: ComparedValueItem[];
}

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Item = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const ItemWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ComparedValues = ({ comparedValuesConfig }: Props) => {
  const { colors } = useTheme();

  const validConfig = useMemo(
    () => comparedValuesConfig.filter((item) => Boolean(item.value)),
    [comparedValuesConfig]
  );

  return (
    <Wrapper>
      {validConfig?.map((item, i) => {
        return (
          <ItemWrapper key={i}>
            <Item>
              {item.icon}
              <Typography variant='body' color={colors.primary[90]}>
                {item.value}
              </Typography>
            </Item>
            {i !== validConfig.length - 1 && <RightArrow />}
          </ItemWrapper>
        );
      })}
    </Wrapper>
  );
};
