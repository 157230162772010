import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { ReactComponent as YesAnswer } from '../../../../assets/icons/checkmark.svg';
import { ReactComponent as NAIcon } from '../../../../assets/icons/not-available.svg';
import { ReactComponent as NoAnswer } from '../../../../assets/icons/no-answer.svg';
import { useMemo } from 'react';
import { CHECKLIST_OPTION } from '../../../../types';

interface Props {
  answer: string;
}

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ChecklistItemCell = ({ answer }: Props) => {
  const { colors } = useTheme();

  const icon = useMemo(() => {
    if (answer === CHECKLIST_OPTION.YES) {
      return <YesAnswer />;
    }

    if (answer === CHECKLIST_OPTION.NO) {
      return <NoAnswer />;
    }

    return <NAIcon />;
  }, [answer]);

  return (
    <Container>
      {icon}
      <Typography variant='body' color={colors.primary[90]}>
        {answer}
      </Typography>
    </Container>
  );
};
